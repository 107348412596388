/**
 * @author Brian Frichette (brian@eturi.com)
 */

import type { ReactNode } from 'react'
import { Component } from 'react'
import { sentryError } from './sentry-helpers'

type LoadErrorBoundaryState = {
	readonly hasError: boolean
}

export class LoadErrorBoundary extends Component<
	{ readonly children?: ReactNode },
	LoadErrorBoundaryState
> {
	state: LoadErrorBoundaryState = { hasError: false }

	static getDerivedStateFromError(): LoadErrorBoundaryState {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		sentryError(error, { ErrBoundaryStack: errorInfo.componentStack.trim() })
	}

	// FIXME: Add error page or handling for global errors caught here
	render() {
		return this.props.children
	}
}
