import './UserThumbnail.scss'

import type { MotivUser, TeamMember } from '@motiv-shared/server'
import { charAtIdx } from '@motiv-shared/util'
import cls from 'classnames'
import last from 'lodash/last'
import Image from 'react-bootstrap/Image'

type UserThumbnailProps = {
	readonly user: MotivUser | TeamMember
	readonly className?: string
}

const getUserInitials = (userName: string) => {
	const userNames = userName.trim().split(' ')
	const firstInitial = charAtIdx(userNames[0], 0)
	const lastInitial = userNames.length > 1 ? charAtIdx(last(userNames), 0) : ''

	return firstInitial + lastInitial
}

export const UserThumbnail = ({ className, user }: UserThumbnailProps) => {
	const imgUrl = (user as TeamMember).avatarUrl
	const userName = user.fullName || user.email

	return (
		<figure className={cls('user-thumbnail', className, !imgUrl && 'user-thumbnail--initials')}>
			{imgUrl && (
				<Image
					alt={userName}
					className="user-thumbnail"
					id="thumbnail"
					roundedCircle
					src={imgUrl}
					title={userName}
				/>
			)}

			{!imgUrl && (
				<div className="user-thumbnail__initials" title={userName}>
					{getUserInitials(user.fullName || user.email)}
				</div>
			)}
		</figure>
	)
}
