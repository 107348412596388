import { hasUserPermission } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import {
	completedIntegrations$,
	fetchIntegrationCatalog,
	fetchIntegrations,
	fetchUsers,
	hasUnauthorizedIntegrations$,
	integrations$,
	isAccountIntegrated$,
	user$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { AppDownloadCard } from './AppDownloadCard'
import { Integrations } from './Integrations'
import { TeamLead } from './TeamLead'
import { TeamMemberCard } from './TeamMemberCard'
import { TeamMembersTable } from './TeamMembersTable'
import { Teams } from './Teams'
import { doTeamsExist$ } from './teams.selectors'
import { TodoCard } from './TodoCard'

export const TeamsPage = () => {
	const dispatch = useAppDispatch()

	const completedIntegrations = useSelector(completedIntegrations$)
	const doTeamsExist = useSelector(doTeamsExist$)
	const hasUnauthorizedIntegrations = useSelector(hasUnauthorizedIntegrations$)
	const integrations = useSelector(integrations$)
	const isAccountIntegrated = useSelector(isAccountIntegrated$)
	const user = useSelector(user$)!

	useEffect(() => {
		dispatch(fetchIntegrationCatalog())
		dispatch(fetchIntegrations({ indicatorRegion: IndicatorRegions.INTEGRATIONS }))
		dispatch(fetchUsers())
	}, [])

	if (hasUserPermission(user, UserPermissions.CAN_VIEW_TEAM_LEAD_VIEW)) {
		return <TeamLead />
	}

	return (
		<Row>
			<Col lg={3}>
				<BusyIndicator region={IndicatorRegions.INTEGRATIONS}>
					{!hasUnauthorizedIntegrations && !doTeamsExist && (
						<TodoCard integrations={completedIntegrations} doTeamsExist={doTeamsExist} />
					)}

					{hasUnauthorizedIntegrations ? (
						<Integrations integrations={integrations} />
					) : (
						isAccountIntegrated && (
							<>
								<Teams />
								<Integrations integrations={completedIntegrations} />
							</>
						)
					)}
				</BusyIndicator>
			</Col>

			<Col lg={9}>
				{hasUserPermission(user, UserPermissions.CAN_VIEW_MOTIV_BANNER) && <AppDownloadCard />}

				<BusyIndicator region={IndicatorRegions.INTEGRATIONS}>
					{isAccountIntegrated && !hasUnauthorizedIntegrations ? (
						<TeamMembersTable />
					) : (
						<TeamMemberCard />
					)}
				</BusyIndicator>
			</Col>
		</Row>
	)
}
