import { useConstant, useFn } from '@motiv-shared/react'
import { MAX_TEAMS_PER_ACCOUNT } from '@motiv-shared/server'
import { Form as FormikForm, Formik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import AddIcon from '../../assets/icons/add.svg'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { useAppDispatch } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikMultiSelectProps } from '../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import { MultiSelect } from '../../widgets/SelectDropdown'
import {
	assignTeamMembers,
	fetchTeamMembers,
	fetchTeams,
	selectedTeamMemberIds$,
	selectedTeamMembersWithTeamAssignments$,
	setCreateTeamMemberIds,
	setSelectedTeamMemberId,
	setSelectedTeamMemberIds,
	setTeamsModal,
	settingsTeams$,
} from '../Teams'
import { TeamsModals } from './teamsModals.constants'

type AssignTeamsFormData = {
	readonly assignedTeams: MultiSelectOption[]
}

export const AssignTeamsToUsersModal = () => {
	const dispatch = useAppDispatch()
	const selectedTeamMemberIds = useSelector(selectedTeamMemberIds$)
	const selectedTeamMembersWithAssignments = useSelector(selectedTeamMembersWithTeamAssignments$)
	const teams = useSelector(settingsTeams$)

	const hasReachedTeamLimit = teams.length >= MAX_TEAMS_PER_ACCOUNT

	const teamOpts = useMemo(
		(): MultiSelectOption[] =>
			teams.map((t) => ({
				label: t.name,
				value: t.id,
			})),
		[teams]
	)

	const INITIAL_VALUES = useConstant(
		(): AssignTeamsFormData => {
			const teamsAssignedToAllSelectedMembers = teams.filter((t) => {
				return selectedTeamMembersWithAssignments.every((tm) =>
					tm.teamAssignments.some((team) => team.id === t.id)
				)
			})

			return {
				assignedTeams: teamsAssignedToAllSelectedMembers.map((t) => ({
					label: t.name,
					value: t.id,
				})),
			}
		}
	)

	const handleSubmit: FormikSubmit<AssignTeamsFormData> = useFn(async (values) => {
		sentryBreadcrumb('Assigning Team Members to Teams')

		try {
			const newTeamIds = values.assignedTeams.map((t) => ({ teamId: t.value }))
			const teamMembers = selectedTeamMemberIds.map((t) => ({ teamMemberId: t }))

			await dispatch(
				assignTeamMembers({
					assignedMembers: { teamMembers, teams: newTeamIds },
				})
			)

			handleClose()
		} catch (e) {
			sentryError(e, 'Error Assigning Team Members to Team')
		}
	})

	const handleClose = useFn(() => {
		dispatch(fetchTeamMembers({ force: true }))
		dispatch(fetchTeams({ force: true }))
		dispatch(setSelectedTeamMemberIds(null))
		dispatch(setSelectedTeamMemberId(null))
		resetModal()
	})

	const handleCreateTeamClick = useFn(() => {
		dispatch(setTeamsModal(TeamsModals.CREATE_TEAM))
	})

	const resetModal = useFn(() => {
		dispatch(setCreateTeamMemberIds(null))
		dispatch(setTeamsModal(null))
	})

	useEffect(() => {
		dispatch(setCreateTeamMemberIds(selectedTeamMemberIds))
	}, [selectedTeamMemberIds])

	useEffect(() => {
		dispatch(fetchTeams())

		return () => {
			dispatch(setSelectedTeamMemberIds(null))
		}
	}, [])

	return (
		<Formik<AssignTeamsFormData> initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
			{(p) => {
				const getMultiSelectProps = formikMultiSelectProps(p)

				return (
					<MotivModal onHide={resetModal} size="md" title="Assign to Team">
						<Modal.Body>
							<BusyIndicator region={IndicatorRegions.ASSIGN_TO_TEAM}>
								<Form as={FormikForm}>
									<Form.Group>
										<Form.Label>Assign the Selected User(s) to The Following Teams:</Form.Label>

										<div className="d-flex flex-column flex-md-row align-items-md-center">
											<div className="flex-fill align-items-center mb-4 mb-md-0">
												<MultiSelect
													noOptionsMessage="No Teams Have Been Created"
													options={teamOpts}
													placeholder="Type Team Name..."
													{...getMultiSelectProps('assignedTeams')}
												/>
											</div>

											<Button
												className="d-flex align-items-center text-nowrap ml-md-3 align-self-start"
												disabled={hasReachedTeamLimit}
												onClick={handleCreateTeamClick}
												size="lg"
											>
												Create New Team
												<Image
													alt="Create Team Icon"
													className="ml-auto ml-sm-3"
													src={AddIcon}
													width={25}
												/>
											</Button>
										</div>

										{hasReachedTeamLimit && (
											<Form.Text className="text-danger">
												Team limit reached. Only {MAX_TEAMS_PER_ACCOUNT} teams allowed.
											</Form.Text>
										)}
									</Form.Group>
								</Form>
							</BusyIndicator>
						</Modal.Body>

						<Modal.Footer>
							<Button size="lg" type="submit" variant="success" onClick={p.submitForm}>
								Save
							</Button>

							<Button variant="light-link" size="lg" onClick={resetModal}>
								Cancel
							</Button>
						</Modal.Footer>
					</MotivModal>
				)
			}}
		</Formik>
	)
}
