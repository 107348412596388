import { useFn, useMounted } from '@motiv-shared/react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { AuthRedirect, useAuth } from '../../Auth'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import {
	deleteUser,
	fetchUsers,
	setUserModal,
	setUserToBeDeleted,
	user$,
	userToBeDeleted$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'

export const DeleteUserModal = () => {
	const dispatch = useAppDispatch()
	const { logout } = useAuth()
	const userId = useSelector(userToBeDeleted$)
	const { id: currentUserId } = useSelector(user$)!
	const isMounted = useMounted()

	const [isDisabled, setIsDisabled] = useState(false)

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
		dispatch(setUserToBeDeleted(null))
	})

	const handleDeleteClick = useFn(async () => {
		if (!userId) return

		sentryBreadcrumb('Deleting User')

		setIsDisabled(true)

		try {
			await dispatch(deleteUser({ userId }))

			if (userId === currentUserId) return logout(AuthRedirect.SIGNUP)

			dispatch(fetchUsers({ force: true }))
		} catch (e) {
			sentryError(e, 'Error Deleting User')
		}

		isMounted() && setIsDisabled(false)
		handleClose()
	})

	return (
		<MotivModal onHide={handleClose} size="sm" title="Are You Sure You Want to Delete?">
			<BusyIndicator region={IndicatorRegions.DELETE_USER}>
				<Modal.Body>
					<span className="p-0 d-block text-dark">This action cannot be undone.</span>
				</Modal.Body>

				<Modal.Footer>
					<Button disabled={isDisabled} onClick={handleDeleteClick} size="lg" variant="danger">
						Delete
					</Button>

					<Button variant="light-link" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
