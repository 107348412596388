import { resetAction } from '@motiv-shared/reducers'
import type { Integration, IntegrationCatalogData } from '@motiv-shared/server'
import { sanitizeIntegration } from '@motiv-shared/server'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { fetchIntegrationCatalog, fetchIntegrations } from './integrations.asyncActions'

export type IntegrationsState = {
	readonly integrationCatalog: IntegrationCatalogData[]
	readonly integrations: Integration[]
	readonly integrationToBeDeleted: Maybe<string>
}

export type WithIntegrationsState = {
	readonly integrations: IntegrationsState
}

const initialState: IntegrationsState = {
	integrationCatalog: [],
	integrations: [],
	integrationToBeDeleted: null,
}

export const integrationsSlice = createSlice({
	name: 'integrations',
	initialState,
	reducers: {
		setIntegrationToBeDeleted: (s, a: PayloadAction<Maybe<string>>) => {
			s.integrationToBeDeleted = a.payload
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)

			.addCase(fetchIntegrations.fulfilled, (s, a) => {
				s.integrations = a.payload || []
			})

			.addCase(fetchIntegrationCatalog.fulfilled, (s, a) => {
				s.integrationCatalog = a.payload || []
			})
	},
})

export const { setIntegrationToBeDeleted } = integrationsSlice.actions

export const sanitizeIntegrationsState = (s: IntegrationsState) => ({
	integrations: s.integrations.map(sanitizeIntegration),
	integrationToBeDeleted: s.integrationToBeDeleted,
})

export const typeOnlyIntegrationActions = new Set([setIntegrationToBeDeleted.type])
