/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import { sanitizeSubscriptionState, subscriptionSlice } from '@motiv-shared/reducers'
import type { Action } from 'redux'
import { accountSlice, sanitizeAccountState } from '../../features/Account'
import { sanitizeSettingsState, teamsSlice, typeOnlySettingsActions } from '../../features/Teams'
import { authSlice, sanitizeAuthState, typeOnlyAuthActions } from '../../reducers/auth'
import {
	integrationsSlice,
	sanitizeIntegrationsState,
	typeOnlyIntegrationActions,
} from '../../reducers/integrations'
import { sanitizeUsersState, typeOnlyUsersActions, usersSlice } from '../../reducers/users'

import type { RootState } from '../../rootReducer'
import type { SentryActionTransformer } from './sentry-helpers'

export const sanitizeState = (s: RootState) => {
	if (!s) return s

	return {
		...s,
		[accountSlice.name]: sanitizeAccountState(s[accountSlice.name]),
		[authSlice.name]: sanitizeAuthState(s[authSlice.name]),
		[integrationsSlice.name]: sanitizeIntegrationsState(s[integrationsSlice.name]),
		[teamsSlice.name]: sanitizeSettingsState(s[teamsSlice.name]),
		[subscriptionSlice.name]: sanitizeSubscriptionState(s[subscriptionSlice.name]),
		[usersSlice.name]: sanitizeUsersState(s[usersSlice.name]),
	}
}

const THUNK_ACTION_REGEXP = /\/(fulfilled|pending|rejected)$/

export const stripPayload = (action: Action) => ({ type: action.type })

const TYPE_ONLY_ACTIONS = new Set([
	...typeOnlyAuthActions,
	...typeOnlyIntegrationActions,
	...typeOnlySettingsActions,
	...typeOnlyUsersActions,
])

export const sanitizeAction: SentryActionTransformer = (action) => {
	// Filter out thunk actions since they contain raw data and potentially
	// confidential info.
	if (THUNK_ACTION_REGEXP.test(action.type)) return null

	// For actions that have payload that contain sensitive info, we just keep
	// the type
	if (TYPE_ONLY_ACTIONS.has(action.type)) return stripPayload(action)

	return action
}
