import { pick } from '@motiv-shared/util'
import type { UserPermissionData } from './UserPermissionData'
import type { UserRole } from './UserRole'

export type MotivUser = {
	readonly accountId: string
	readonly auth0Id: string
	readonly createdAt: string
	readonly email: string
	readonly fullName: string
	readonly id: string // uuid
	readonly isInviteAccepted: boolean
	readonly isSetupInstructionsEmailSent: boolean
	readonly permissions: UserPermissionData[]
	readonly roleId: UserRole
	readonly setupInstructionEmailSentAt?: string
	readonly settings: SettingsData
}

export type SettingsData = {
	marketingOptIn?: boolean
	webClientUsed?: boolean
}

export const sanitizeMotivUser = (u: Maybe<MotivUser>) =>
	u
		? {
				...pick(u, [
					'accountId',
					'id',
					'isInviteAccepted',
					'isSetupInstructionsEmailSent',
					'permissions',
					'roleId',
					'setupInstructionEmailSentAt',
					'settings',
				]),
		  }
		: null

export type MotivUserSeed = {
	email: string
	fullName: string
	roleId: UserRole
	setAssignedTeams?: string[]
}

export type PasswordPatch = {
	currentPassword: string
	newPassword: string
}

export type MotivUserPatch = Partial<Pick<MotivUser, 'email' | 'fullName' | 'roleId'>> & {
	setAssignedTeams?: string[]
	addSettings?: Partial<SettingsData>
}

export type MotivUserAndPasswordPatch = MotivUserPatch & Partial<PasswordPatch>

export type UserPatchQueryArgs = {
	resendInvite?: boolean
	shouldSendSetupInstructions?: boolean
	shouldTransferAccount?: boolean
}

export type PasswordChangeResponse = {
	isCurrentPasswordInvalid?: boolean
	isCurrentUserPasswordUpdated?: boolean
}

export type MotivUserPatchResponse = MotivUser | PasswordChangeResponse

export const isMotivUser = (response: any): response is MotivUser =>
	(response as MotivUser).id !== undefined

export const isPasswordChangeResponse = (response: any): response is PasswordChangeResponse => {
	const pwChange = response as PasswordChangeResponse

	return (
		pwChange.isCurrentPasswordInvalid !== undefined ||
		pwChange.isCurrentUserPasswordUpdated !== undefined
	)
}
