/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import type { HttpExtra } from '@motiv-shared/reducers'
import { fetchPaymentSources, fetchPurchaseInfo, fetchSKUDefs } from '@motiv-shared/reducers'
import { createAsyncThunk } from '../../createAsyncThunk'
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
	IndicatorRegions,
} from '../../widgets/BusyIndicator'

// NOTE: this async action is here and not shared because we want
//  to modify busy indicators before and after the call since
//  indicator state is not in shared we have to have this action here
export const fetchSubscriptions = createAsyncThunk(
	'subscriptions/init',
	async (extra: HttpExtra = {}, { dispatch }) => {
		dispatch(incrementBusyIndicator(IndicatorRegions.FETCH_SUBS))

		try {
			await Promise.all([
				dispatch(fetchSKUDefs(extra)),
				dispatch(fetchPurchaseInfo(extra)),
				dispatch(fetchPaymentSources(extra)),
			])
		} finally {
			dispatch(decrementBusyIndicator(IndicatorRegions.FETCH_SUBS))
		}
	}
)
