import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import type { Team, UserRole } from '@motiv-shared/server'
import { UserRoles } from '@motiv-shared/server'
import type { AsyncThunkAction } from '@reduxjs/toolkit'
import { Form as FormikForm, Formik } from 'formik'
import { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import {
	accountOwnerRole$,
	adminRole$,
	createUser,
	setUserModal,
	setUserToBeEdited,
	updateUser,
	userModal$,
	userRoleData$,
	userWithAssignmentsToBeEdited$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { formikCtrlProps } from '../../util'
import { formikMultiSelectProps } from '../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import { MultiSelect } from '../../widgets/SelectDropdown'
import { fetchTeams, settingsTeams$ } from '../Teams'
import { UserModals } from './userModals.constants'

const SELECT_ROLE = 'Select Role'

type ManageUserFormValues = {
	readonly assignedTeams: MultiSelectOption[]
	readonly email: string
	readonly fullName: string
	readonly roleId?: UserRole
}

export const ManageUserModal = () => {
	const dispatch = useAppDispatch()
	const accountOwnerRole = useSelector(accountOwnerRole$)
	const adminRole = useSelector(adminRole$)
	const roleData = useSelector(userRoleData$)
	const teams = useSelector(settingsTeams$)
	const userModal = useSelector(userModal$)
	const userToBeEdited = useSelector(userWithAssignmentsToBeEdited$)
	const isMounted = useMounted()

	const [isDisabled, setIsDisabled] = useState(false)

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			fullName: Yup.string()
				.trim()
				.required('Full Name is required.')
				.max(69, 'Character Limit Reached'),
			email: Yup.string().required('Email is required.').email('Invalid email'),
			roleId: Yup.string().required('Role is required.'),
		})
	)

	const mapAssignedTeamsToOpts = (teams: Team[]) =>
		teams.map((t) => ({
			label: t.name,
			value: t.id,
		}))

	const assignedTeamsOpts = useMemo(() => mapAssignedTeamsToOpts(teams), [teams])

	const INITIAL_VALUES: ManageUserFormValues = useConstant(() => ({
		assignedTeams: mapAssignedTeamsToOpts(userToBeEdited?.assignedTeams || []),
		email: userToBeEdited?.email || '',
		fullName: userToBeEdited?.fullName || '',
		roleId: userToBeEdited?.roleId,
	}))

	const isAddUserModalOpen = userModal === UserModals.ADD_USER
	const isEditUserModalOpen = userModal === UserModals.EDIT_USER
	const isUserToEditAccountOwner =
		isEditUserModalOpen &&
		userToBeEdited &&
		accountOwnerRole &&
		userToBeEdited.roleId === accountOwnerRole.id

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
		dispatch(setUserToBeEdited(null))
	})

	const handleSave = useFn(async (v: ManageUserFormValues) => {
		if (v.roleId == null) return

		// Don't save if nothing changed
		if (isEqual(v, INITIAL_VALUES)) return handleClose()

		setIsDisabled(true)

		const actionStr = `${isAddUserModalOpen ? 'Creating' : 'Updating'} User`

		sentryBreadcrumb(actionStr)

		const updatedUser = {
			email: v.email,
			fullName: v.fullName,
			roleId: v.roleId,
			setAssignedTeams: v.roleId === adminRole?.id ? [] : v.assignedTeams.map((t) => t.value),
		}

		const action: AsyncThunkAction<any, any, any> = isAddUserModalOpen
			? createUser({ user: updatedUser })
			: updateUser({
					userId: userToBeEdited!.id,
					patch: updatedUser,
			  })

		try {
			await dispatch(action)
		} catch (e) {
			sentryError(e, `Error ${actionStr}`)
		}

		isMounted() && setIsDisabled(false)
		dispatch(fetchTeams({ force: true }))
		handleClose()
	})

	return (
		<Formik<ManageUserFormValues>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSave}
			validationSchema={validationSchema}
		>
			{(p) => {
				const { values } = p
				const getCtrlProps = formikCtrlProps(p)
				const getAssignedTeamsProps = formikMultiSelectProps(p)

				return (
					<MotivModal onHide={handleClose} size="lg" title="Manage User">
						<BusyIndicator
							region={
								isAddUserModalOpen ? IndicatorRegions.ADD_NEW_USER : IndicatorRegions.UPDATE_USER
							}
						>
							<Modal.Body>
								<p>Add or edit user details in the fields below.</p>

								<Form as={FormikForm}>
									<Row>
										<Form.Group as={Col} md={6}>
											<Form.Label>Name</Form.Label>

											<Form.Control
												maxLength={70}
												placeholder="Full Name"
												{...getCtrlProps('fullName')}
											/>

											<FormValidationText field="fullName" formikProps={p} />
										</Form.Group>

										<Form.Group as={Col} md={6}>
											<Form.Label>Email</Form.Label>

											<Form.Control
												disabled={!isAddUserModalOpen}
												inputMode="email"
												placeholder="Email Address"
												readOnly={!isAddUserModalOpen}
												type="email"
												{...getCtrlProps('email')}
											/>

											<FormValidationText field="email" formikProps={p} />
										</Form.Group>
									</Row>

									<Row>
										{values.roleId !== accountOwnerRole?.id && (
											<Form.Group as={Col} md={6}>
												<Form.Label>Role</Form.Label>

												<Form.Control placeholder="Role" as="select" {...getCtrlProps('roleId')}>
													<option
														key={0}
														value={isUserToEditAccountOwner ? userToBeEdited!.roleId : ''}
													>
														{isUserToEditAccountOwner ? UserRoles.ACCOUNT_OWNER : SELECT_ROLE}
													</option>

													{roleData.map(
														({ id, label }) =>
															id !== UserRoles.ACCOUNT_OWNER && (
																<option key={id} value={id}>
																	{label}
																</option>
															)
													)}
												</Form.Control>

												<FormValidationText field="roleId" formikProps={p} />
											</Form.Group>
										)}

										{(INITIAL_VALUES.roleId && values.roleId) !== adminRole?.id && (
											<Form.Group as={Col} md={6}>
												<Form.Label>Assign Team(s) to User</Form.Label>

												<MultiSelect
													placeholder="Type Team Name..."
													options={assignedTeamsOpts}
													{...getAssignedTeamsProps('assignedTeams')}
												/>

												<FormValidationText field="assignedTeams" formikProps={p} />
											</Form.Group>
										)}
									</Row>
								</Form>
							</Modal.Body>

							<Modal.Footer>
								<Button disabled={isDisabled} onClick={p.submitForm} size="lg" variant="success">
									Save
								</Button>

								<Button onClick={handleClose} size="lg" variant="light-link">
									Cancel
								</Button>
							</Modal.Footer>
						</BusyIndicator>
					</MotivModal>
				)
			}}
		</Formik>
	)
}
