import { useFn } from '@motiv-shared/react'
import type { MotivUser, Team, UserRole, UserRoleData } from '@motiv-shared/server'
import { UserRoles } from '@motiv-shared/server'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'
import type { ColumnFormatter } from 'react-bootstrap-table-next'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import EditIcon from '../../assets/icons/edit.svg'
import TrashIcon from '../../assets/icons/trash.svg'
import {
	allUsersWithTeamAssignments$,
	setUserModal,
	setUserToBeDeleted,
	setUserToBeEdited,
	userRoleData$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import type { MotivUserWithTeamAssignments } from '../../types'
import { BadgesWithTooltips } from '../../widgets/BadgeWithTooltip'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import type { SafeColumnDescription } from '../../widgets/Table'
import { Table } from '../../widgets/Table'
import { UserThumbnail } from '../../widgets/UserThumbnail'
import { UserModals } from '../UserModal'

export const AccountUsersTable = () => {
	const dispatch = useAppDispatch()
	const allUsers = useSelector(allUsersWithTeamAssignments$)
	const userRoleData = useSelector(userRoleData$)

	const [usersData, setUsersData] = useState<MotivUserWithTeamAssignments[]>([])

	const handleAddUserClick = useFn(() => {
		dispatch(setUserModal(UserModals.ADD_USER))
	})

	const handleTableChange = useFn((type, { sortField, sortOrder }) => {
		if (type === 'sort' && allUsers) {
			setUsersData(orderBy(allUsers, sortField, [sortOrder]))
		}
	})

	const AssignedTeamsFormatter = (teams: Team[], user: MotivUser) => {
		if (user.roleId === UserRoles.ADMIN) return 'Admin cannot be assigned Teams.'

		if (isEmpty(teams)) return 'No Teams Have Been Assigned.'

		return (
			<div className="justify-content-space-between d-flex pointer flex-column flex-md-row">
				<BadgesWithTooltips maxLength={12} maxVisible={2} labels={map(teams, 'name')} />
			</div>
		)
	}

	const EditTrashFormatter = (id: Maybe<string>, user: MotivUser) => (
		<div className="d-flex justify-content-end">
			<Button
				variant="link"
				className="p-0 mr-4"
				onClick={() => {
					dispatch(setUserToBeEdited(user.id))
					dispatch(setUserModal(UserModals.EDIT_USER))
				}}
			>
				<Image src={EditIcon} alt="Edit Icon" width={25} />
			</Button>

			{user.roleId !== UserRoles.ACCOUNT_OWNER && (
				<Button
					variant="link"
					className="p-0"
					onClick={() => {
						dispatch(setUserToBeDeleted(user.id))
						dispatch(setUserModal(UserModals.DELETE_USER))
					}}
				>
					<Image src={TrashIcon} alt="Trash Icon" width={25} />
				</Button>
			)}
		</div>
	)

	const NameFormatter = (name: string, user: MotivUser) => (
		<div className="account__name-field">
			<UserThumbnail user={user} />
			<span className="text-truncate">{name || user.email}</span>
		</div>
	)

	const RoleFormatter: ColumnFormatter<MotivUserWithTeamAssignments, UserRoleData[], UserRole> = (
		roleId,
		row,
		index,
		roles
	) => roles.find((role) => role.id === roleId)?.label || false

	const columns: SafeColumnDescription<MotivUserWithTeamAssignments>[] = [
		{
			dataField: 'fullName',
			formatter: NameFormatter,
			sort: true,
			text: 'Name',
		},
		{
			dataField: 'roleId',
			formatter: RoleFormatter,
			formatExtraData: userRoleData,
			sort: true,
			text: 'Role',
		},
		{
			dataField: 'assignedTeams',
			formatter: AssignedTeamsFormatter,
			text: 'Teams Assigned',
		},
		{
			dataField: 'id',
			formatter: EditTrashFormatter,
			text: '',
		},
	]

	useEffect(() => {
		allUsers && setUsersData(allUsers)
	}, [allUsers])

	return (
		<>
			<Row className="mb-5 mb-md-3">
				<Col md={6}>
					<h3 className="mb-md-0 py-3">Users</h3>
				</Col>

				<Col md={4} className="ml-md-auto d-flex align-items-center">
					<Button variant="primary" className="btn-block" onClick={handleAddUserClick} size="sm">
						Add User
					</Button>
				</Col>
			</Row>

			<Card>
				<BusyIndicator region={IndicatorRegions.USERS}>
					<Table<MotivUserWithTeamAssignments>
						columns={columns}
						data={usersData}
						keyField="id"
						onTableChange={handleTableChange}
					/>
				</BusyIndicator>
			</Card>
		</>
	)
}
