import type { FieldInputProps, FormikProps } from 'formik'

export type FormikChkProps<T, K extends keyof T> = SafeOmit<
	FieldInputProps<T>,
	'name' | 'value'
> & {
	readonly name: K
	readonly type: 'checkbox' | 'radio'
	readonly value: NonNullable<T[K] extends any[] ? T[K][number] : T[K]>
	readonly isInvalid?: boolean
	readonly isValid?: boolean
}

export const formikChkProps = <T, K extends keyof T>(
	p: FormikProps<T>,
	name: K,
	type: 'checkbox' | 'radio' = 'checkbox',
	validate = true
) => (value: T[K] extends any[] ? T[K][number] : T[K]): FormikChkProps<T, K> => {
	const chkProps = p.getFieldProps({ name, type, value })

	if (!validate || p.submitCount < 1) return { ...chkProps, name, type }

	const isInvalid = Boolean(p.errors[name])

	return {
		...chkProps,
		name,
		type,
		isInvalid,
		isValid: !isInvalid,
	}
}
