import { useFn, useMounted } from '@motiv-shared/react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { fetchUsers } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import {
	deleteTeam,
	fetchTeamMembers,
	fetchTeams,
	setTeamsModal,
	setTeamToBeDeleted,
	teamToBeDeleted$,
} from '../Teams'

export const DeleteTeamModal = () => {
	const dispatch = useAppDispatch()
	const teamToBeDeleted = useSelector(teamToBeDeleted$)
	const isMounted = useMounted()

	const [isDisabled, setIsDisabled] = useState(false)

	const handleClose = useFn(() => {
		dispatch(setTeamsModal(null))
		dispatch(setTeamToBeDeleted(null))
	})

	const handleDelete = useFn(async () => {
		if (!teamToBeDeleted) return

		sentryBreadcrumb('Deleting Team')

		setIsDisabled(true)

		try {
			await dispatch(deleteTeam({ teamId: teamToBeDeleted }))

			Promise.all([
				dispatch(fetchTeamMembers({ force: true })),
				dispatch(fetchTeams({ force: true })),
				dispatch(fetchUsers({ force: true })),
			])
		} catch (e) {
			sentryError(e, 'Error Deleting Team')
		}

		isMounted() && setIsDisabled(false)
		handleClose()
	})

	return (
		<MotivModal onHide={handleClose} size="sm" title="Are You Sure You Want to Delete?">
			<BusyIndicator region={IndicatorRegions.DELETE_TEAM}>
				<Modal.Body>
					<p>This action cannot be undone.</p>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="danger" size="lg" disabled={isDisabled} onClick={handleDelete}>
						Delete
					</Button>

					<Button variant="light-link" size="lg" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
