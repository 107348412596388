import { useFn } from '@motiv-shared/react'
import { hasUserPermission, isDowngradedSub$, nextSeatLimit$ } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$, setActivePurchaseModal, user$ } from '../../reducers'
import { willHaveMaxSeats$ } from '../../state-helpers'
import { useAppDispatch } from '../../store'
import { PurchaseModalType } from '../../types'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'
import { assignedSeats$ } from '../Teams'

export const DowngradeBanner = () => {
	const dispatch = useAppDispatch()
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const assignedSeats = useSelector(assignedSeats$)
	const currentUser = useSelector(user$)!
	const willHaveMaxSeats = useSelector(willHaveMaxSeats$)
	const isDowngradedSub = useSelector(isDowngradedSub$)

	const canAccessBilling =
		currentUser && hasUserPermission(currentUser, UserPermissions.CAN_VIEW_BILLING_TAB)

	const showBanner = isDowngradedSub && willHaveMaxSeats

	const MaxSeatMsg = useMemo(() => {
		const extraSeats = assignedSeats - nextSeatLimit

		if (canAccessBilling) {
			return `Your subscription has downgraded and only allows for ${nextSeatLimit} team members. 
			Please remove ${extraSeats} team member${extraSeats > 1 ? 's' : ''}. If no action is taken, 
			we will remove the most recently added team member on ${activeSubExpiryStr}.`
		}

		return `Your subscription has downgraded and only allows for ${nextSeatLimit} team members. 
		Admin or Account Owner action required.`
	}, [willHaveMaxSeats, canAccessBilling, assignedSeats, activeSubExpiryStr, nextSeatLimit])

	const handleManageDowngrade = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.DOWNGRADE))
	})

	if (!showBanner) return null

	return <BannerWithBtn btnMsg="Manage Team" msg={MaxSeatMsg} onClick={handleManageDowngrade} />
}
