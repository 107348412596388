import { assertUnreachable, pick } from '@motiv-shared/util'
import type { IntegrationProvider } from './IntegrationProvider'
import { IntegrationProviders } from './IntegrationProvider'
import type { IntegrationScope } from './IntegrationScope'
import type { IntegrationStatus } from './IntegrationStatus'

export type BaseIntegration = {
	readonly integrationProvider: IntegrationProvider
	readonly scope: IntegrationScope
	readonly status: IntegrationStatus
}

export type Integration = BaseIntegration & {
	readonly id: string
}

export type CreateIntegrationInfo = BaseIntegration & {
	readonly authUrl: string
}

export const sanitizeIntegration = (i: Maybe<Integration>) =>
	i ? pick(i, ['integrationProvider', 'scope', 'status']) : null

export const integrationName = (provider: IntegrationProvider) => {
	switch (provider) {
		case IntegrationProviders.GOOGLE:
			return 'Google Workspace'

		case IntegrationProviders.MICROSOFT:
			return 'Microsoft 365'

		case IntegrationProviders.SLACK:
			return 'Slack'

		case IntegrationProviders.ZOOM:
			return 'Zoom'
	}

	assertUnreachable(provider, `Unknown provider ${provider}`)
}

export const integrationShortName = (provider: IntegrationProvider) =>
	integrationName(provider).split(/\s+/)[0]
