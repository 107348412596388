import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { LoadingFigure } from '../BusyIndicator'
import { CardCvc, CardExpiry, CardNumber, useStripeForm } from './StripeFormProvider'

type StripeCardFormProps = {
	readonly className?: string
}

export const StripeCardForm = ({ className }: StripeCardFormProps) => {
	const stripeForm = useStripeForm()

	if (!stripeForm.isReady) return <LoadingFigure />

	return (
		<Row className={className}>
			<CardNumber as={Col} xs={12} md={6} />
			<CardExpiry as={Col} xs={12} sm={6} md={3} />
			<CardCvc as={Col} xs={12} sm={6} md={3} />
		</Row>
	)
}
