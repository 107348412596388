import './DisableOverlay.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type DisableOverlayProps = {
	readonly children: ReactNode
	readonly className?: string
	readonly isDisabled: boolean
}

export const DisableOverlay = ({ children, className, isDisabled }: DisableOverlayProps) => (
	<div className={cls('disable-overlay', className)}>
		{isDisabled && <div className="disable-overlay__overlay" />}

		{children}
	</div>
)
