import React from 'react'
import { BillingBanners } from './BillingBanners'
import { FreeTrialExpiringBanner } from './FreeTrialExpiringBanner'

export const Banners = () => {
	return (
		<>
			<BillingBanners />
			<FreeTrialExpiringBanner />
		</>
	)
}
