/**
 * @author Brian Frichette (brian@eturi.com)
 */

import type { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'
import createAuth0Client from '@auth0/auth0-spa-js'
import { useConstant, useFn } from '@motiv-shared/react'
import {
	IdentifyAction,
	isIdentifyActionRequiredRes,
	isIdentifyInviteRejectedRes,
} from '@motiv-shared/server'
import { unwrapResult } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from './infrastructure'
import { setAuthUser, setJwtToken, shouldLogout$ } from './reducers/auth'
import { postAuthIdentify } from './reducers/auth/auth.asyncActions'
import { useAppDispatch } from './store'
import type { Auth0User } from './types'

export enum AuthRedirect {
	INVITE = 'inviteSignUp',
	LOGIN = 'webappLogin',
	SIGNUP = 'webappSignup',
}

const AUTH_REDIRECT_KEY = '__MOTIV_AUTH_REDIRECT__'
const INVITE_ACTIONS_KEY = '__MOTIV_INVITE_ACTIONS__'

const getAuthRedirect = (): AuthRedirect =>
	(sessionStorage.getItem(AUTH_REDIRECT_KEY) || AuthRedirect.LOGIN) as AuthRedirect

const getInviteActions = (): string => sessionStorage.getItem(INVITE_ACTIONS_KEY) || ''

export const setInviteActions = (actions: Maybe<Record<string, string>>) => {
	if (!actions) return sessionStorage.removeItem(INVITE_ACTIONS_KEY)

	sessionStorage.setItem(INVITE_ACTIONS_KEY, JSON.stringify(actions))
}

export const setAuthRedirect = (redirect: Maybe<AuthRedirect>) => {
	if (!redirect) return sessionStorage.removeItem(AUTH_REDIRECT_KEY)

	sessionStorage.setItem(AUTH_REDIRECT_KEY, redirect)
}

type AuthCtx = {
	readonly isReady: boolean
	readonly login: () => Promise<void>
	readonly logout: (redirectTo?: AuthRedirect) => void
}

const AuthContext = createContext<AuthCtx>(null as any)
export const useAuth = () => useContext(AuthContext)

export const Auth = ({ children }: { readonly children?: ReactNode }) => {
	const d = useAppDispatch()
	const shouldLogout = useSelector(shouldLogout$)

	const [auth0Client, setAuth0Client] = useState<Auth0Client>()
	const [isLoading, setLoading] = useState(true)
	const [isReady, setReady] = useState(false)

	const initOptions = useConstant(
		(): Auth0ClientOptions => ({
			audience: process.env.MOTIV_AUTH0_AUDIENCE,
			cacheLocation: 'localstorage',
			client_id: process.env.MOTIV_AUTH0_CLIENT_ID,
			domain: process.env.MOTIV_AUTH0_DOMAIN,
			redirect_uri: `${location.origin}/login`,
		})
	)

	const createAuth0 = useFn(async () => {
		sentryBreadcrumb('Creating Auth 0 Client')

		try {
			const auth0Client = await createAuth0Client(initOptions)
			setAuth0Client(auth0Client)
		} catch (e) {
			sentryError(e, 'Failed To Create Auth0 Client')
		}
	})

	const handleAuth0Client = useFn(async () => {
		if (!auth0Client) return

		try {
			const url = new URL(location.href)

			if (url.searchParams.has('code') && url.searchParams.has('state')) {
				sentryBreadcrumb('Handling Auth 0 Redirect')

				await auth0Client.handleRedirectCallback()
				// Need to clear the query params or there we'll try to parse them again on refresh.
				// Also, the uri is dirty w/ all the params.
				history.replaceState({}, document.title, url.pathname)
			}
		} catch (e) {
			sentryError(e, 'Failed to parse location or handle auth redirect')
		}

		try {
			// We check for isAuthenticated because getUser and
			// getTokenSilently take a long time when not logged in.
			const isAuthenticated = await auth0Client.isAuthenticated()

			if (!isAuthenticated) return setLoading(false)

			const [user, token] = await Promise.all([
				auth0Client.getUser<Auth0User>(),
				auth0Client.getTokenSilently(),
			])

			d(setAuthUser(user))
			d(setJwtToken(token))

			const res = await d(postAuthIdentify()).then(unwrapResult)

			if (isIdentifyActionRequiredRes(res)) {
				// NOTE: We currently accept invitation if there is one, by default.
				//  Future UI may allow users to make this choice. If there is no
				//  invitation, try to create an account.
				const action = res.clientActions.includes(IdentifyAction.ACCEPT_INVITATION)
					? IdentifyAction.ACCEPT_INVITATION
					: res.clientActions.includes(IdentifyAction.CREATE_ACCOUNT)
					? IdentifyAction.CREATE_ACCOUNT
					: null

				if (!action) {
					throw new Error('Post auth identify is missing a valid action.')
				}

				await d(postAuthIdentify({ action }))
			} else if (isIdentifyInviteRejectedRes(res)) {
				// NOTE: This should never happen until we allow invite rejection
				//  in the UI flow. So we throw an error for now.
				throw new Error(`Invitation rejected, but this shouldn't occur.`)
			}
		} catch (e) {
			if (e?.error !== 'login_required') {
				sentryError(e, 'Failed to check authentication state')
			}
		}

		setLoading(false)
	})

	const login = useFn(async () => {
		await auth0Client?.loginWithRedirect({
			action: getAuthRedirect(),
			actionForInvitedUser: getInviteActions(),
		})

		setAuthRedirect(null)
		setInviteActions(null)
	})

	const logout = useFn((redirectTo?: AuthRedirect) => {
		redirectTo && setAuthRedirect(redirectTo)
		auth0Client?.logout({ returnTo: `${location.origin}/logout` })
	})

	useEffect(() => {
		createAuth0()
	}, [])

	useEffect(() => {
		handleAuth0Client()
	}, [auth0Client])

	useEffect(() => {
		setReady(Boolean(auth0Client && !isLoading))
	}, [auth0Client, isLoading])

	// This is set when there's a 401
	useEffect(() => {
		if (shouldLogout) logout()
	}, [shouldLogout])

	return (
		<AuthContext.Provider
			value={{
				isReady,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
