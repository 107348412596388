/**
 * API server used success HTTP status codes
 */
export const ApiSuccessStatusCodes = {
	OK: 200,
	Created: 201,
	NoContent: 204,
	NotModified: 304,
} as const

/**
 * Enum of high level client types
 */
export enum ClientType {
	WEB = 'web',
	MOBILE = 'mobile',
	OTHER = 'other',
}
