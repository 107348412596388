/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import { activeSeatLimit$, nextSeatLimit$ } from '@motiv-shared/reducers'
import { createSelector } from '@reduxjs/toolkit'
import { assignedSeats$ } from '../features/Teams'

export const hasMaxSeats$ = /* @__PURE__ */ createSelector(
	activeSeatLimit$,
	assignedSeats$,
	(limit, assignedSeats) => assignedSeats > limit
)

export const willHaveMaxSeats$ = /* @__PURE__ */ createSelector(
	nextSeatLimit$,
	assignedSeats$,
	(limit, assignedSeats) => assignedSeats > limit
)
