/**
 * @author Brian Frichette (brian@eturi.com)
 */

import throttle from 'lodash/throttle'
import { useEffect, useRef } from 'react'
import { useConstant } from './useConstant'

/**
 * Immutable wrapped `throttle` hook
 * @see throttle
 */
export const useThrottle: typeof throttle = (fn, wait, opts = { trailing: false }) => {
	const fnRef = useRef(fn)
	fnRef.current = fn

	const throttled = useConstant(() =>
		throttle((...args: any[]) => fnRef.current(...args), wait, opts)
	)

	useEffect(
		() => () => {
			// Don't fire throttled function when unmounted
			throttled.cancel()
		},
		[]
	)

	// NOTE: Casting as `any` b/c I don't want to deal w/ implementing the entire throttle
	//  signature just to tell TypeScript that what we're returning is the same.
	return throttled as any
}
