import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import { unwrapResult } from '@reduxjs/toolkit'
import { Form as FormikForm, Formik } from 'formik'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import {
	addErrorToast,
	addSuccessToast,
	fetchUsers,
	updateUser,
	updateUserPassword,
	user$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { setSelectedAccountModal } from '../Account'
import { AccountModals } from '../AccountModal'

const { UPDATE_USER, UPDATE_PASSWORD } = IndicatorRegions
const PW_REGEXP = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/

export const ProfilePage = () => {
	const dispatch = useAppDispatch()

	const handleEmailSubscriptionClick = useFn(() => {
		dispatch(setSelectedAccountModal(AccountModals.EMAIL_SUBSCRIPTION))
	})

	return (
		<Row>
			<Col md={6}>
				<UpdateInfoCard />
				<Button
					className="btn-block"
					onClick={handleEmailSubscriptionClick}
					size="lg"
					variant="light"
				>
					Email Subscription
				</Button>
			</Col>

			<Col md={6}>
				<UpdatePasswordCard />
			</Col>
		</Row>
	)
}

type UserInfoData = {
	readonly email: string
	readonly fullName: string
}

const UpdateInfoCard = () => {
	const dispatch = useAppDispatch()
	const { email, fullName, id } = useSelector(user$)!
	const isMounted = useMounted()

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().trim().required('Email is required.').email('Invalid email').default(''),

			fullName: Yup.string()
				.trim()
				.required('Full Name is required.')
				.max(70, 'Character Limit Reached.')
				.default(''),
		})
	)

	const [initialValues, setInitialValues] = useState<UserInfoData>(() =>
		VALIDATION_SCHEMA.cast({ email, fullName })
	)

	const handSubmit: FormikSubmit<UserInfoData> = useFn(async (v, helpers) => {
		// Normalize / trim
		const patch = VALIDATION_SCHEMA.cast(v)

		if (isEqual(patch, initialValues)) {
			return helpers.resetForm()
		}

		sentryBreadcrumb('Updating User Profile')

		try {
			await dispatch(updateUser({ userId: id, patch, region: UPDATE_USER }))

			// We make this call to update the cached data for fetchAllUsers
			await dispatch(fetchUsers({ force: true }))

			isMounted() && setInitialValues(patch)

			dispatch(addSuccessToast('Information updated successfully'))
		} catch (e) {
			dispatch(addErrorToast('Something went wrong saving your info'))
			sentryError(e, 'Failed to update profile')
		}
	})

	return (
		<Formik<UserInfoData>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handSubmit}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(p) => {
				const getCtrlProps = formikCtrlProps(p)

				return (
					<Card className="mb-5">
						<Card.Header as="h4">Update Information</Card.Header>

						<Card.Body>
							<Form as={FormikForm}>
								<BusyIndicator region={UPDATE_USER}>
									<Form.Group controlId="fullNameField">
										<Form.Label>Name</Form.Label>

										<Form.Control
											maxLength={70}
											placeholder="Enter your name"
											{...getCtrlProps('fullName')}
										/>

										<FormValidationText field="fullName" formikProps={p} />
									</Form.Group>

									<Form.Group>
										<Form.Label>Email Address</Form.Label>

										<Form.Control
											inputMode="email"
											placeholder="Enter your email"
											type="email"
											{...getCtrlProps('email')}
										/>

										<FormValidationText field="email" formikProps={p} />
									</Form.Group>
								</BusyIndicator>

								<Button
									className="btn-block"
									disabled={p.isSubmitting}
									size="lg"
									type="submit"
									variant="success"
								>
									Save
								</Button>
							</Form>
						</Card.Body>
					</Card>
				)
			}}
		</Formik>
	)
}

type UserPwData = {
	readonly confirmPassword: string
	readonly currentPassword: string
	readonly newPassword: string
}

const UpdatePasswordCard = () => {
	const dispatch = useAppDispatch()
	const { id } = useSelector(user$)!

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			confirmPassword: Yup.string()
				.required('This field cannot be empty.')
				.oneOf([Yup.ref('newPassword')], 'Passwords must match.')
				.default(''),

			currentPassword: Yup.string().required('This field cannot be empty.').default(''),

			newPassword: Yup.string()
				.required('This field cannot be empty.')
				.matches(
					PW_REGEXP,
					'8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)'
				)
				.default(''),
		})
	)

	const INITIAL_VALUES = useConstant((): UserPwData => VALIDATION_SCHEMA.cast({}))

	const handleSubmit: FormikSubmit<UserPwData> = useFn(async (values, { resetForm }) => {
		sentryBreadcrumb('Updating Password')

		const passwordPatch = {
			newPassword: values.newPassword,
			currentPassword: values.currentPassword,
		}

		try {
			const res = await dispatch(
				updateUserPassword({ userId: id, passwordPatch, region: UPDATE_PASSWORD })
			).then(unwrapResult)

			if (res.isCurrentPasswordInvalid) {
				dispatch(addErrorToast('Current password is invalid!'))
			} else if (res.isCurrentUserPasswordUpdated) {
				dispatch(
					addSuccessToast({
						msg: 'Your password has been changed successfully.',
						title: 'Password Updated',
					})
				)
			}

			resetForm({})
		} catch (e) {
			sentryError(e, 'Failed to update password')
		}
	})

	return (
		<Formik<UserPwData>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSubmit}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(p) => {
				const getCtrlProps = formikCtrlProps(p)

				return (
					<Card className="mb-5">
						<Card.Header as="h4">Update Password</Card.Header>

						<Card.Body>
							<Form as={FormikForm}>
								<BusyIndicator region={UPDATE_PASSWORD}>
									<Form.Group controlId="currentPwField">
										<Form.Label>Current Password</Form.Label>

										<Form.Control
											placeholder="Current Password"
											type="password"
											{...getCtrlProps('currentPassword')}
										/>

										<FormValidationText field="currentPassword" formikProps={p} />
									</Form.Group>

									<Form.Group controlId="newPwField">
										<Form.Label>New Password</Form.Label>

										<Form.Control
											placeholder="New Password"
											type="password"
											{...getCtrlProps('newPassword')}
										/>

										<FormValidationText field="newPassword" formikProps={p} />
									</Form.Group>

									<Form.Group controlId="confirmPwField">
										<Form.Label>Confirm New Password</Form.Label>

										<Form.Control
											placeholder="Confirm Password"
											type="password"
											{...getCtrlProps('confirmPassword')}
										/>

										<FormValidationText field="confirmPassword" formikProps={p} />
									</Form.Group>
								</BusyIndicator>

								<Button
									className="btn-block"
									disabled={p.isSubmitting}
									size="lg"
									type="submit"
									variant="success"
								>
									Save
								</Button>
							</Form>
						</Card.Body>
					</Card>
				)
			}}
		</Formik>
	)
}
