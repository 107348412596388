import type {
	MotivApiErrorData,
	Team,
	TeamMember,
	TeamPatch,
	TeamsCreateData,
} from '@motiv-shared/server'
import { ApiErrors } from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { HttpExtra } from '../../http'
import type { SelectedTeam, SelectedTeamMember } from '../../types'
import { IndicatorRegions } from '../../widgets/BusyIndicator'

export const fetchTeams = createAsyncThunk(
	'teams/getAll',
	({ ...extra }: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Team[]>(`teams`, {
				errorMessage: 'Unable to load teams data. Please try again later.',
				indicatorRegion: IndicatorRegions.FETCH_TEAMS,
				...extra,
			})
		)
)

type FetchTeamArgs = HttpExtra & {
	readonly teamId: string
}

export const fetchTeam = createAsyncThunk(
	'teams/teamId',
	({ teamId, ...extra }: FetchTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Team>(`teams/${teamId}`, {
				errorMessage: 'Unable to load team. Please try again later.',
				...extra,
			})
		)
)

type CreateTeamArgs = HttpExtra & {
	readonly team: TeamsCreateData
}

// FIXME: Return type. This should return the team or teams, so we shouldn't
//  have to immediately fetch teams afterward.
export const createTeam = createAsyncThunk(
	'teams/createTeam',
	({ team, ...extra }: CreateTeamArgs, { dispatch, extra: { http } }) => {
		return dispatch(
			http.post(`teams`, {
				data: team,
				indicatorRegion: IndicatorRegions.CREATE_TEAM,
				errorMessage: (extra, body) => () => {
					const data = body?.data

					if (data?.errorCode === ApiErrors.EXCEEDED_LIMIT_TEAMS[0] && data?.detailMsg) {
						return { msg: data.detailMsg, title: 'Limit Reached' }
					}

					return 'Unable to add team. Please try again later.'
				},
				...extra,
			})
		)
	}
)

type DeleteTeamArgs = HttpExtra & {
	readonly teamId: string
}

export const deleteTeam = createAsyncThunk(
	'teams/Id',
	({ teamId, ...extra }: DeleteTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`teams/${teamId}`, {
				errorMessage: 'Unable to delete team. Please try again later.',
				indicatorRegion: IndicatorRegions.DELETE_TEAM,
				...extra,
			})
		)
)

export type UpdateTeamArgs = HttpExtra & {
	readonly teamId: string
	readonly patch: TeamPatch
}

export const updateTeam = createAsyncThunk(
	'teams/updateTeam',
	({ teamId, patch, ...extra }: UpdateTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.patch(`teams/${teamId}`, {
				data: patch,
				errorMessage: 'Unable to update team. Please try again later.',
				indicatorRegion: IndicatorRegions.UPDATE_TEAM,
				...extra,
			})
		)
)

export const fetchTeamMembers = createAsyncThunk(
	'team-members',
	({ ...extra }: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<TeamMember[]>(`team-members`, {
				errorMessage: 'Unable to load team members. Please try again later.',
				indicatorRegion: IndicatorRegions.TEAM_MEMBERS,
				...extra,
			})
		)
)

type AssignTeamMemberObj = {
	readonly teamMembers: SelectedTeamMember[] // Team member ids
	readonly teams: SelectedTeam[]
}

type AssignTeamMembersArgs = HttpExtra & {
	readonly assignedMembers: AssignTeamMemberObj
}

export const assignTeamMembers = createAsyncThunk(
	'team-assignments/assignMembers',
	({ assignedMembers, ...extra }: AssignTeamMembersArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.post('team-assignments', {
				data: assignedMembers,
				errorMessage: 'Unable to assign team members. Please try again later.',
				indicatorRegion: IndicatorRegions.ASSIGN_TO_TEAM,
				...extra,
			})
		)
)

type UpdateAssignedTeamMemberObj = {
	readonly teams: SelectedTeam[]
}

type UpdateAssignedTeamMembersArgs = HttpExtra & {
	readonly assignedMembers: UpdateAssignedTeamMemberObj
	readonly teamMemberId: string
}

export const updateAssignedTeamMembers = /* @__PURE__ */ createAsyncThunk(
	'team-members/updateMembers',
	(
		{ assignedMembers, teamMemberId, ...extra }: UpdateAssignedTeamMembersArgs,
		{ dispatch, extra: { http } }
	) =>
		dispatch(
			http.patch(`team-members/${teamMemberId}`, {
				data: assignedMembers,
				errorMessage: 'Unable to assign team members. Please try again later.',
				indicatorRegion: IndicatorRegions.ASSIGN_TO_TEAM,
				...extra,
			})
		)
)
