import type { WithSubscriptionState } from '@motiv-shared/reducers'
import { subscriptionSlice } from '@motiv-shared/reducers'
import { combineReducers } from 'redux'
import type { WithAccountState } from './features/Account'
import { accountSlice } from './features/Account'
import type { WithTeamsState } from './features/Teams'
import { teamsSlice } from './features/Teams'
import type {
	WithAuthState,
	WithIntegrationsState,
	WithSubscriptionUIState,
	WithToastState,
	WithUsersState,
} from './reducers'
import {
	authSlice,
	integrationsSlice,
	subscriptionUISlice,
	toastSlice,
	usersSlice,
} from './reducers'

import type { WithBusyIndicatorState } from './widgets/BusyIndicator'
import { busyIndicatorSlice } from './widgets/BusyIndicator'

type FlatIntersection<T> = {
	readonly [P in keyof T]: T[P]
}

export type RootState = FlatIntersection<
	WithAccountState &
		WithAuthState &
		WithBusyIndicatorState &
		WithIntegrationsState &
		WithTeamsState &
		WithSubscriptionState &
		WithSubscriptionUIState &
		WithToastState &
		WithUsersState
>

export const rootReducer = combineReducers({
	[accountSlice.name]: accountSlice.reducer,
	[authSlice.name]: authSlice.reducer,
	[busyIndicatorSlice.name]: busyIndicatorSlice.reducer,
	[integrationsSlice.name]: integrationsSlice.reducer,
	[teamsSlice.name]: teamsSlice.reducer,
	[subscriptionSlice.name]: subscriptionSlice.reducer,
	[subscriptionUISlice.name]: subscriptionUISlice.reducer,
	[toastSlice.name]: toastSlice.reducer,
	[usersSlice.name]: usersSlice.reducer,
})
