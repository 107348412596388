/**
 * @author Brian Frichette (brian@eturi.com)
 */

export const pick = <T, K extends keyof T>(obj: T, keys: readonly K[]): MPick<T, K> => {
	if (!obj) return {} as any

	const newObj: any = {}

	for (const k of keys) {
		const v = obj[k]

		// Don't pick undefined
		if (v === undefined) continue

		newObj[k] = v
	}

	return newObj
}
