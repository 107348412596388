import { IntegrationStatuses } from '@motiv-shared/server'
import { notEmpty } from '@motiv-shared/util'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import find from 'lodash/find'
import type { IntegrationDecorated } from '../../types'
import { AvailableIntegrations } from '../../types'
import { getIntegrationDisplayData } from './integration.util'
import type { WithIntegrationsState } from './integrations.slice'

const state$ = <T extends WithIntegrationsState>(s: T) => s.integrations

export const integrationToBeDeleted$ = createSelector(state$, (s) => s.integrationToBeDeleted)
export const integrations$ = createSelector(state$, (s) => s.integrations)
export const integrationsCatalog$ = createSelector(state$, (s) => s.integrationCatalog)

export const integrationsDecorated$ = createSelector(
	integrations$,
	integrationsCatalog$,
	(integrations, catalog) =>
		catalog.map(
			({ integrationProvider }): IntegrationDecorated => {
				const displayData = getIntegrationDisplayData(integrationProvider)
				const integration = find(integrations, { integrationProvider })
				const status = integration?.status

				return {
					...displayData,
					...integration,
					integrationProvider,
					isAvailable: AvailableIntegrations.has(integrationProvider),
					isIntegrated: status === IntegrationStatuses.COMPLETE,
					isUnauthorized: status === IntegrationStatuses.UNAUTHORIZED,
				}
			}
		)
)

export const completedIntegrations$ = createSelector(integrations$, (igs) =>
	filter(igs, { status: IntegrationStatuses.COMPLETE })
)

export const isAccountIntegrated$ = createSelector(completedIntegrations$, notEmpty)

export const unauthorizedIntegrations$ = createSelector(integrations$, (igs) =>
	filter(igs, { status: IntegrationStatuses.UNAUTHORIZED })
)

export const hasUnauthorizedIntegrations$ = createSelector(unauthorizedIntegrations$, notEmpty)
