/**
 * @author Brian Frichette (brian@eturi.com)
 */

import isObject from 'lodash/isObject'

export const getMotivHost = (): string => {
	let MOTIV_API_HOST = process.env.MOTIV_API_HOST

	if (process.env.APP_ENV !== 'dev') return MOTIV_API_HOST

	try {
		const host = sessionStorage.getItem('MOTIV_API_HOST')
		if (host) MOTIV_API_HOST = host
	} catch (e) {
		console.warn('Could not parse sessionStorage MOTIV_API_HOST', e)
	}

	return MOTIV_API_HOST
}

export const getRequiresAuth = (url: string) => url.startsWith(`https://${getMotivHost()}`)

export const normalizeHttpData = (data?: any): string | undefined =>
	data == null ? undefined : isObject(data) ? JSON.stringify(data) : data

export const normalizeUrl = (url: string): string => {
	if (!url.startsWith('http')) {
		const host = getMotivHost()
		const urlObj = new URL(`https://${host}/${url}`)

		return urlObj.toString().replace(/\/$/, '')
	}

	return url
}

export const normalizeHeaders = (headers?: any) => ({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	...headers,
})
