import './ManageTeamModal.scss'

import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import { activeSeatLimit$, maxTeamSeatLimit$ } from '@motiv-shared/reducers'
import type { MotivUser, TeamMember } from '@motiv-shared/server'
import { notEmpty } from '@motiv-shared/util'
import { Formik } from 'formik'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reject from 'lodash/reject'
import type { ChangeEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import type { TableChangeHandler } from 'react-bootstrap-table-next'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { canBeTeamLeadMembers$, fetchUsers } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { filterAvailableTeamMembers } from '../../util/filterAvailableTeamMembers'
import { formikMultiSelectProps } from '../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import { MultiSelect } from '../../widgets/SelectDropdown'
import type { SafeColumnDescription } from '../../widgets/Table'
import { Table } from '../../widgets/Table'
import {
	assignedTeamMemberIds$,
	createTeam,
	createTeamMemberIds$,
	fetchTeamMembers,
	fetchTeams,
	hasSeatLimit$,
	setCreateTeamMemberIds,
	setSelectedTeamId,
	setTeamsModal,
	setTeamToBeDeleted,
	settingsModal$,
	settingsTeam$,
	settingsTeamMembers$,
	settingsTeams$,
	uniqueTeamMembersOfSelectedTeam$,
	updateTeam,
} from '../Teams'
import { TeamsModals } from './teamsModals.constants'

const { CREATE_TEAM, UPDATE_TEAM } = IndicatorRegions

type ManageTeamFormData = {
	readonly teamName: string
	readonly teamLeads: MultiSelectOption[]
}

export const ManageTeamModal = () => {
	const dispatch = useAppDispatch()
	const activeSeatLimit = useSelector(activeSeatLimit$)
	const assignedTeamMemberIds = useSelector(assignedTeamMemberIds$)
	const canBeTeamLeadMembers = useSelector(canBeTeamLeadMembers$)
	const createTeamMemberIds = useSelector(createTeamMemberIds$)
	const hasSeatLimit = useSelector(hasSeatLimit$)
	const maxTeamSeatLimit = useSelector(maxTeamSeatLimit$)
	const settingsModal = useSelector(settingsModal$)
	const settingsTeam = useSelector(settingsTeam$)
	const teamMembers = useSelector(settingsTeamMembers$)
	const teams = useSelector(settingsTeams$)
	const uniqueTeamMembersOfSelectedTeam = useSelector(uniqueTeamMembersOfSelectedTeam$)

	const isCreateTeamModal = settingsModal === TeamsModals.CREATE_TEAM
	const isUpdateTeamModal = settingsModal === TeamsModals.UPDATE_TEAM

	const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>(() => {
		const ids = isCreateTeamModal ? createTeamMemberIds : settingsTeam!.assignedTeamMembers

		return teamMembers.filter((t) => ids.includes(t.id))
	})

	const selectedTeamMemberIds = useMemo(() => map(selectedTeamMembers, 'id'), [selectedTeamMembers])

	// Number of team members unique to currently selected team that
	// have been deselected and can be replaced
	const deselectedAssignedMemberIds = uniqueTeamMembersOfSelectedTeam.filter(
		(u) => !selectedTeamMemberIds.includes(u)
	)
	const deselectedAssignedMemberCount = deselectedAssignedMemberIds.length

	// NOTE: This is mirrored from state for search filtering
	const [teamMembersData, setTeamMembersData] = useState<TeamMember[]>(teamMembers)
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
	const isMounted = useMounted()

	const nonAssignedSelectedTeamMemberIds = selectedTeamMemberIds.filter(
		(id) => !assignedTeamMemberIds.includes(id)
	)

	const normalizeTeamName = (teamName: string) => teamName.toLowerCase().split(/\s+/).join(' ')

	const startingTeamName = normalizeTeamName(settingsTeam?.name || '')

	// We have reached our limit if we have the limit of assigned team members
	// and none have been deselected or if the number of selected team members
	// is equal to or greater than the number of assigned team members minus
	// the deselected
	const hasReachedLimit =
		selectedTeamMemberIds.length > 0 &&
		((hasSeatLimit && deselectedAssignedMemberCount === 0) ||
			selectedTeamMemberIds.length >= maxTeamSeatLimit ||
			assignedTeamMemberIds.length -
				deselectedAssignedMemberCount +
				nonAssignedSelectedTeamMemberIds.length >=
				activeSeatLimit)

	const teamNames = useMemo(() => new Set(teams.map((t) => normalizeTeamName(t.name))), [teams])

	const validateTeamName = useFn((v: Maybe<string>) => {
		if (!v) return false

		const teamName = normalizeTeamName(v)

		return startingTeamName === teamName || !teamNames.has(teamName)
	})

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			teamName: Yup.string()
				.required('Team Name cannot be empty.')
				.max(70, 'Character Limit Reached')
				.test('teamName', 'Team Name should be unique.', validateTeamName),
		})
	)

	const mapTeamLeadToOpts = (u: MotivUser): MultiSelectOption => ({
		label: u.fullName || u.email,
		value: u.id,
	})

	const assignTeamLeadOpts = useMemo(
		(): MultiSelectOption[] => canBeTeamLeadMembers.map(mapTeamLeadToOpts),
		[canBeTeamLeadMembers]
	)

	const INITIAL_VALUES = useConstant(
		(): ManageTeamFormData => {
			const teamLeads = canBeTeamLeadMembers
				.filter((u) => settingsTeam?.assignedTeamLeads.includes(u.id))
				.map(mapTeamLeadToOpts)

			return {
				teamName: settingsTeam?.name || '',
				teamLeads,
			}
		}
	)

	const handleClose = useFn(() => dispatch(setTeamsModal(null)))

	const handleClearAllUsers = useFn(() => {
		setSelectedTeamMembers([])
	})

	const handleTeamMemberSelected = useFn((teamMember, isSelected: boolean) => {
		// Only select a team member if the limit hasn't been reached, but take into
		// account if some team members (unique to this team) have been deselected
		if (
			isSelected &&
			((hasSeatLimit && !deselectedAssignedMemberCount) ||
				selectedTeamMemberIds.length - deselectedAssignedMemberCount >= activeSeatLimit) &&
			!assignedTeamMemberIds.includes(teamMember.id)
		)
			return

		const newSelectedTeamMembers = isSelected
			? [...selectedTeamMembers, teamMember]
			: reject(selectedTeamMembers, { id: teamMember.id })

		setSelectedTeamMembers(newSelectedTeamMembers)
	})

	const handleOnSelectAll = useFn((isSelected: boolean, rows: TeamMember[]) => {
		const allowedRows =
			!hasSeatLimit && rows.length <= activeSeatLimit
				? rows
				: filterAvailableTeamMembers(rows, assignedTeamMemberIds, maxTeamSeatLimit)

		setSelectedTeamMembers(isSelected ? allowedRows : [])
	})

	const handleSubmit: FormikSubmit<ManageTeamFormData> = useFn(async (v) => {
		// TODO: Submit conditionally only if form data changes
		setIsSaveButtonDisabled(true)

		const actionStr = `${isCreateTeamModal ? 'Creating' : 'Updating'} Team`

		sentryBreadcrumb(actionStr)

		const assignedTeamLeads = map(v.teamLeads, 'value')
		const assignedTeamMembers = selectedTeamMemberIds
		const name = v.teamName

		try {
			if (isCreateTeamModal) {
				await dispatch(createTeam({ team: { name, assignedTeamMembers, assignedTeamLeads } }))
			} else {
				await dispatch(
					updateTeam({
						teamId: settingsTeam!.id,
						patch: {
							name,
							setTeamLeads: assignedTeamLeads,
							setAssignedTeamMembers: assignedTeamMembers,
						},
					})
				)
			}

			Promise.all([
				dispatch(fetchTeams({ force: true })),
				dispatch(fetchTeamMembers({ force: true })),
				dispatch(fetchUsers({ force: true })),
			])

			handleClose()
		} catch (e) {
			sentryError(e, `Error ${actionStr}`)
			isMounted() && setIsSaveButtonDisabled(false)
		}
	})

	const handleDeleteTeamsClick = useFn(() => {
		dispatch(setTeamToBeDeleted(settingsTeam?.id))
		dispatch(setTeamsModal(TeamsModals.DELETE_TEAM))
	})

	const handleSearchChange = useFn((e: ChangeEvent<HTMLInputElement>) => {
		const normalizedSearchData = normalizeTeamName(e.target.value)

		const filteredTeamMembers = teamMembers.filter(
			(each) =>
				normalizedSearchData === '' ||
				normalizeTeamName(each.fullName).includes(normalizedSearchData)
		)

		setTeamMembersData(filteredTeamMembers)
	})

	const handleTableChange: TableChangeHandler<any> = useFn((type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			return setTeamMembersData(orderBy(teamMembersData, sortField, [sortOrder]))
		}
	})

	const handleSelectedTableChange: TableChangeHandler<any> = useFn(
		(type, { sortField, sortOrder }) => {
			if (type === 'sort' && selectedTeamMembers) {
				return setSelectedTeamMembers(orderBy(selectedTeamMembers, sortField, [sortOrder]))
			}
		}
	)

	const CheckboxRenderer = ({ checked }) => (
		<div className="custom-checkbox dark">
			<Form.Check type="checkbox" label="" checked={checked} readOnly={true} />
		</div>
	)

	const CustomCheckbox = (name: string, user: TeamMember) => (
		<div className="d-flex align-items-center">
			<Image
				alt="Profile avatar image"
				className="rounded-circle mr-3"
				src={user.avatarUrl}
				width={40}
			/>

			<div className="text-truncate" style={{ maxWidth: '180px' }}>
				{name}
			</div>
		</div>
	)

	const selectRow = {
		clickToSelect: true,
		mode: 'checkbox' as const,
		onSelect: handleTeamMemberSelected,
		onSelectAll: handleOnSelectAll,
		selected: selectedTeamMemberIds,
		selectionHeaderRenderer: CheckboxRenderer,
		selectionRenderer: CheckboxRenderer,
	}

	const selectedTeamMembersSelectRow = {
		clickToSelect: true,
		hideSelectAll: true,
		mode: 'checkbox' as const,
		onSelect: handleTeamMemberSelected,
		selected: selectedTeamMemberIds,
		selectionHeaderRenderer: CheckboxRenderer,
		selectionRenderer: CheckboxRenderer,
	}

	const columns: SafeColumnDescription<TeamMember>[] = [
		{
			classes: 'w-100',
			dataField: 'fullName',
			formatter: CustomCheckbox,
			sort: true,
			text: 'Name',
		},
	]

	const selectedTeamMembersColumns: SafeColumnDescription<TeamMember>[] = [
		{
			classes: 'w-100',
			dataField: 'fullName',
			formatter: CustomCheckbox,
			sort: true,
			text: 'Name',
		},
	]

	const rowStyles = useFn((teamMember: TeamMember) =>
		hasReachedLimit &&
		(!assignedTeamMemberIds.includes(teamMember.id) ||
			deselectedAssignedMemberIds.includes(teamMember.id)) &&
		!selectedTeamMemberIds.includes(teamMember.id)
			? { opacity: 0.5, pointerEvents: 'none' as const }
			: {}
	)

	const limitMessage = useMemo(() => {
		return selectedTeamMemberIds.length >= maxTeamSeatLimit
			? `Limit Reached. Only ${maxTeamSeatLimit} team members allowed.`
			: `You have reached the maximum of ${activeSeatLimit} manged team members.`
	}, [hasReachedLimit, selectedTeamMemberIds])

	useEffect(
		() => () => {
			dispatch(setCreateTeamMemberIds(null))
			dispatch(setSelectedTeamId(null))
		},
		[]
	)

	return (
		<MotivModal onHide={handleClose} title={isCreateTeamModal ? 'Create New Team' : 'Manage Team'}>
			<Formik<ManageTeamFormData>
				initialValues={INITIAL_VALUES}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{(p) => {
					const getCtrlProps = formikCtrlProps(p)
					const getMultiSelectProps = formikMultiSelectProps(p)

					return (
						<BusyIndicator region={isCreateTeamModal ? CREATE_TEAM : UPDATE_TEAM}>
							<Modal.Body>
								<p className="mb-5">
									{isCreateTeamModal
										? 'Create your team and assign users to it'
										: 'Edit team details or add/remove team members'}
								</p>

								<Row>
									<Form.Group className="mb-5" as={Col} lg={6} controlId="teamNameField">
										<Form.Label>Team Name</Form.Label>

										<Form.Control
											maxLength={71}
											placeholder="Enter team name"
											{...getCtrlProps('teamName')}
										/>

										<FormValidationText field="teamName" formikProps={p} />
									</Form.Group>

									<Form.Group className="mb-5" as={Col} lg={6}>
										<Form.Label>Assign to Team Lead(s)</Form.Label>

										<MultiSelect
											noOptionsMessage="No Team Leads Available"
											options={assignTeamLeadOpts}
											placeholder="Type User Name..."
											{...getMultiSelectProps('teamLeads')}
										/>
									</Form.Group>
								</Row>

								<div className="border-top border-bottom border-light">
									<Row>
										<Col className="manage-team-modal__select" md={7}>
											<div className="py-5">
												<Form.Group className="mb-0">
													<Form.Control
														className="search-control"
														onChange={handleSearchChange}
														placeholder="Search Users"
													/>
												</Form.Group>
											</div>

											<BusyIndicator region={IndicatorRegions.TEAM_MEMBERS}>
												<Table<TeamMember>
													columns={columns}
													data={teamMembersData}
													isFixedHeader
													keyField="id"
													onTableChange={handleTableChange}
													rowStyle={rowStyles}
													selectRow={selectRow}
												/>
											</BusyIndicator>
										</Col>

										<Col className="manage-team-modal__selected border-light" md={5}>
											<div className="d-flex align-items-center py-5">
												<span className="font-weight-bold opacity-50">
													{selectedTeamMemberIds.length} Users Selected
												</span>

												<Button
													className="ml-auto"
													onClick={handleClearAllUsers}
													size="lg"
													variant="light-link"
												>
													Clear All
												</Button>
											</div>

											{notEmpty(selectedTeamMemberIds) && (
												<Table<TeamMember>
													columns={selectedTeamMembersColumns}
													data={selectedTeamMembers}
													isFixedHeader
													keyField="id"
													onTableChange={handleSelectedTableChange}
													selectRow={selectedTeamMembersSelectRow}
												/>
											)}
										</Col>
									</Row>
								</div>

								{hasReachedLimit && <p className="manage-team-modal__limit">{limitMessage}</p>}
							</Modal.Body>

							<Modal.Footer>
								{isUpdateTeamModal && (
									<Button
										className="mr-auto"
										onClick={handleDeleteTeamsClick}
										size="lg"
										variant="danger"
									>
										Delete Team
									</Button>
								)}

								<Button
									variant="success"
									size="lg"
									onClick={p.submitForm}
									disabled={isSaveButtonDisabled}
								>
									Save
								</Button>

								<Button size="lg" onClick={handleClose} variant="light-link">
									Cancel
								</Button>
							</Modal.Footer>
						</BusyIndicator>
					)
				}}
			</Formik>
		</MotivModal>
	)
}
