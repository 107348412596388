import cls from 'classnames'
import type { ListGroupItemProps } from 'react-bootstrap/ListGroupItem'
import type { LinkProps } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

type ListGroupLinkProps = Pick<ListGroupItemProps, 'active' | 'disabled' | 'variant'> & LinkProps

export const ListGroupLink = ({
	active,
	children,
	className,
	disabled,
	variant,
	...p
}: ListGroupLinkProps) => (
	<NavLink
		activeClassName="active"
		className={cls(
			'list-group-item list-group-item-action',
			variant && `list-group-item-${variant}`,
			disabled && 'disabled',
			className
		)}
		tabIndex={disabled ? -1 : undefined}
		{...p}
	>
		{children}
	</NavLink>
)
