/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useChgState } from './useChgState'
import { useFn } from './useFn'

/**
 * Similar to `useToggleState`, but without the toggle fn since it isn't used
 * particularly frequently.
 * @see useToggleState
 */
export const useBoolState = (
	initValue: boolean
): [state: boolean, setTrue: () => void, setFalse: () => void] => {
	const [state, setState] = useChgState(initValue)
	const toggleTrue = useFn(() => setState(true))
	const toggleFalse = useFn(() => setState(false))

	return [state, toggleTrue, toggleFalse]
}
