import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import { Form as FormikForm, Formik } from 'formik'
import React, { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { accountOwnerRole$, allUsers$, fetchUsers, updateUser } from '../../reducers'
import { useAppDispatch } from '../../store'
import { formikCtrlProps } from '../../util'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'
import { setSelectedAccountModal } from '../Account'

type TransferAccountFormData = {
	readonly selectedUser: string
}

export const TransferAccountModal = () => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const accountOwnerRole = useSelector(accountOwnerRole$)
	const allUsers = useSelector(allUsers$)
	const [isTransferButtonDisabled, setIsTransferButtonDisabled] = useState(false)
	const isMounted = useMounted()

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			selectedUser: Yup.string().required('No User Selected').default(''),
		})
	)

	const INITIAL_VALUES = useConstant((): TransferAccountFormData => VALIDATION_SCHEMA.cast({}))

	const handleReset = useFn(() => {
		dispatch(setSelectedAccountModal(null))
	})

	const handleTransferSubmit = useFn(async (values) => {
		sentryBreadcrumb('Transferring Account')

		try {
			setIsTransferButtonDisabled(true)

			await dispatch(
				updateUser({
					userId: values.selectedUser,
					patch: { roleId: accountOwnerRole?.id },
					query: { shouldTransferAccount: true },
				})
			)

			handleReset()
			dispatch(fetchUsers({ force: true }))
			history.push('/')
		} catch (e) {
			isMounted() && setIsTransferButtonDisabled(false)
			sentryError(e, 'Failed to Transfer Account')
		}
	})

	const UserOptions = useMemo(
		() =>
			allUsers?.map(
				(user) =>
					user.roleId !== accountOwnerRole?.id && (
						<option value={user.id} key={user.id}>
							{user.fullName}
						</option>
					)
			),
		[accountOwnerRole, allUsers]
	)

	return (
		<Formik<TransferAccountFormData>
			initialValues={INITIAL_VALUES}
			validationSchema={VALIDATION_SCHEMA}
			onSubmit={handleTransferSubmit}
		>
			{(p) => (
				<MotivModal
					onHide={handleReset}
					size="sm"
					title="Transfer Account Ownership to Another User"
				>
					<BusyIndicator region={IndicatorRegions.UPDATE_USER}>
						<Modal.Body>
							<Form as={FormikForm}>
								<Form.Group controlId="transferUserSelect">
									<Form.Label>
										Transfer your account to another user by selecting the use from the dropdown
										below.
									</Form.Label>

									<Form.Control
										as="select"
										placeholder="Full Name"
										{...formikCtrlProps(p)('selectedUser')}
									>
										<option value={''}>Select User</option>
										{UserOptions}
									</Form.Control>

									<FormValidationText field="selectedUser" formikProps={p} />
								</Form.Group>
							</Form>
						</Modal.Body>

						<Modal.Footer>
							<Button
								disabled={isTransferButtonDisabled}
								size="lg"
								type="submit"
								variant="danger"
								onClick={p.submitForm}
							>
								Transfer Ownership
							</Button>

							<Button variant="light-link" size="lg" onClick={handleReset}>
								Cancel
							</Button>
						</Modal.Footer>
					</BusyIndicator>
				</MotivModal>
			)}
		</Formik>
	)
}
