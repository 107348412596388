import { useFn, useInterval, useMounted } from '@motiv-shared/react'
import type { Integration, IntegrationProvider } from '@motiv-shared/server'
import { IntegrationStatuses } from '@motiv-shared/server'
import { unwrapResult } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchTeamMembers, fetchTeams, setTeamsModal } from './features/Teams'
import { TeamsModals } from './features/TeamsModal'
import {
	addErrorToast,
	createIntegration,
	fetchIntegrations,
	integrations$,
	setIntegrationToBeDeleted,
} from './reducers'
import { useAppDispatch } from './store'

type OAuthCtx = {
	connect(provider: IntegrationProvider): void
	disconnect(integrationId: string): void
}

const OAuthContext = createContext<OAuthCtx>(null as any)
export const useIntegrationFlow = () => useContext(OAuthContext)

export const IntegrationFlow = ({ children }: { readonly children?: ReactNode }) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const integrations = useSelector(integrations$)

	const [pendingIntegration, setPendingIntegration] = useState<Maybe<Integration>>(null)
	const [pendingProvider, setPendingProvider] = useState<Maybe<IntegrationProvider>>(null)
	const [win, setWin] = useState<Window | null>(null)

	const connect = useFn(async (provider: IntegrationProvider) => {
		if (pendingProvider) return

		setPendingProvider(provider)

		const newIntegration = await dispatch(createIntegration({ provider }))
			.then(unwrapResult)
			.catch((e) => {
				setPendingProvider(null)
				throw e
			})

		if (isMounted()) {
			setWin(window.open(newIntegration.authUrl, 'popup', buildPopupFeatures()))
		} else {
			setPendingProvider(null)
		}
	})

	const disconnect = useFn((integrationId: string) => {
		dispatch(setIntegrationToBeDeleted(integrationId))
		dispatch(setTeamsModal(TeamsModals.DELETE_INTEGRATION))
	})

	const onFinished = useFn(
		(openWin: Window | null, pendingIntegration: Maybe<Integration> = null) => {
			openWin?.close()
			setWin(null)

			if (pendingProvider == null) return

			setPendingProvider(null)

			if (pendingIntegration?.status === IntegrationStatuses.COMPLETE) {
				dispatch(fetchTeams({ force: true })) // TODO: Remove?
				dispatch(fetchTeamMembers({ force: true }))
			} else {
				dispatch(addErrorToast('There was a problem integrating your account. Please try again.'))
			}
		}
	)

	useInterval(
		async () => {
			if (win?.closed) {
				setWin(null)
				onFinished(null, pendingIntegration)
				return
			}

			dispatch(fetchIntegrations({ force: true }))
		},
		win ? 1000 : null
	)

	useEffect(() => {
		setPendingIntegration(
			pendingProvider &&
				integrations.find((integration) => integration.integrationProvider === pendingProvider)
		)
	}, [integrations, pendingProvider])

	useEffect(() => {
		// Monitors for the following cases
		// - User has completed oauth flow (window may still be open)
		if (pendingIntegration?.status === IntegrationStatuses.COMPLETE) {
			onFinished(win, pendingIntegration)
		}
	}, [onFinished, pendingIntegration, win])

	return <OAuthContext.Provider value={{ connect, disconnect }}>{children}</OAuthContext.Provider>
}

const buildPopupFeatures = (): string => {
	const height = 500
	const width = 500
	const left = screen.width / 2 - width / 2
	const top = screen.height / 2 - height / 2

	return [
		'copyhistory=no',
		'directories=no',
		'location=no',
		'menubar=no,',
		'resizable=no',
		'scrollbars=no',
		'status=no',
		'toolbar=no',
		`height=${height}`,
		`left=${left}`,
		`top=${top}`,
		`width=${width}`,
	].join(', ')
}
