import { resetAction } from '@motiv-shared/reducers'
import type { IntegrationIdentityId, Team, TeamMember } from '@motiv-shared/server'
import { sanitizeTeam } from '@motiv-shared/server'
import { arrayAddOrUpdate, sanitizeSize } from '@motiv-shared/util'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { deleteIntegration } from '../../reducers'
import type { TeamsModalType } from '../TeamsModal'
import { fetchTeam, fetchTeamMembers, fetchTeams } from './teams.asyncActions'

export type TeamsState = {
	readonly createTeamMemberIds: IntegrationIdentityId[]
	readonly selectedTeamId: Maybe<string>
	readonly selectedTeamMemberId: Maybe<string>
	readonly selectedTeamMemberIds: IntegrationIdentityId[]
	readonly teamsModal: Maybe<TeamsModalType>
	readonly teamMembers: TeamMember[]
	readonly teams: Team[]
	readonly teamToBeDeleted: Maybe<string>
}

export type WithTeamsState = {
	readonly teams: TeamsState
}

const initialState: TeamsState = {
	createTeamMemberIds: [],
	selectedTeamId: null,
	selectedTeamMemberId: null,
	selectedTeamMemberIds: [],
	teamsModal: null,
	teamMembers: [],
	teams: [],
	teamToBeDeleted: null,
}

export const teamsSlice = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		setCreateTeamMemberIds: (s, a: PayloadAction<Maybe<IntegrationIdentityId[]>>) => {
			s.createTeamMemberIds = a.payload || []
		},

		setTeamsModal: (s, a: PayloadAction<Maybe<TeamsModalType>>) => {
			s.teamsModal = a.payload
		},

		setSelectedTeamId: (s, a: PayloadAction<Maybe<string>>) => {
			s.selectedTeamId = a.payload
		},

		setSelectedTeamMemberIds: (s, a: PayloadAction<Maybe<IntegrationIdentityId[]>>) => {
			s.selectedTeamMemberIds = a.payload || []
		},

		setSelectedTeamMemberId: (s, a: PayloadAction<Maybe<string>>) => {
			s.selectedTeamMemberId = a.payload
		},

		setTeamToBeDeleted: (s, a: PayloadAction<Maybe<string>>) => {
			s.teamToBeDeleted = a.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)

			.addCase(deleteIntegration.fulfilled, (s) => {
				s.teams = s.teamMembers = []
			})

			.addCase(fetchTeamMembers.fulfilled, (s, a) => {
				s.teamMembers = a.payload || []
			})

			.addCase(fetchTeam.fulfilled, (s, a) => {
				storeTeam(s, a.payload)
			})

			.addCase(fetchTeams.fulfilled, (s, a) => {
				s.teams = a.payload || []
			})
	},
})

const storeTeam = (s: Draft<TeamsState>, team: Team) => {
	s.teams = arrayAddOrUpdate(s.teams, team, 'id')
}

export const {
	setCreateTeamMemberIds,
	setSelectedTeamId,
	setSelectedTeamMemberId,
	setSelectedTeamMemberIds,
	setTeamsModal,
	setTeamToBeDeleted,
} = teamsSlice.actions

export const sanitizeSettingsState = (s: TeamsState) => ({
	...s,
	teamMembers: sanitizeSize(s.teamMembers.length),
	teamMembersToCreateTeam: sanitizeSize(s.createTeamMemberIds),
	teams: s.teams.map(sanitizeTeam),
})

export const typeOnlySettingsActions = new Set([
	setCreateTeamMemberIds.type,
	setSelectedTeamMemberId.type,
	setSelectedTeamMemberIds.type,
	setTeamToBeDeleted.type,
])
