/**
 * @author Freddy Solis (freddy@eturi.com)
 */
import { useSelector } from 'react-redux'
import { activePurchaseModal$ } from '../../reducers'
import { PurchaseModalType } from '../../types'
import { CancelSubModal } from './CancelSubModal'
import { DowngradeModal } from './DowngradeModal'
import { SubscribeModal } from './SubscribeModal'
import { UpdatePaymentModal } from './UpdatePaymentModal'

export const PurchaseModals = () => {
	const activePurchaseModal = useSelector(activePurchaseModal$)

	switch (activePurchaseModal) {
		case PurchaseModalType.CANCEL:
			return <CancelSubModal />

		case PurchaseModalType.DOWNGRADE:
			return <DowngradeModal />

		case PurchaseModalType.PURCHASE:
			return <SubscribeModal />

		case PurchaseModalType.UPDATE_PAYMENT:
			return <UpdatePaymentModal />

		default:
			return null
	}
}
