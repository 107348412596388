import './TeamMembersTable.scss'

import { useFn, usePrevious } from '@motiv-shared/react'
import { activeSeatLimit$, activeSub$, maxTeamSeatLimit$ } from '@motiv-shared/reducers'
import type { IntegrationProvider, Team, TeamMember } from '@motiv-shared/server'
import { integrationName } from '@motiv-shared/server'
import { notEmpty } from '@motiv-shared/util'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import without from 'lodash/without'
import type { ChangeEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import ShareIcon from '../../assets/icons/share.svg'
import { addErrorToast, isAccountIntegrated$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { TeamMemberWithTeamAssignments } from '../../types'
import { filterAvailableTeamMembers } from '../../util/filterAvailableTeamMembers'
import { BadgesWithTooltips } from '../../widgets/BadgeWithTooltip'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { DisableOverlay } from '../../widgets/DisableOverlay/DisableOverlay'
import type { SafeColumnDescription } from '../../widgets/Table'
import { UserThumbnail } from '../../widgets/UserThumbnail'
import { TeamsModals } from '../TeamsModal'
import { PaginationTable } from './PaginationTable'
import { fetchTeamMembers } from './teams.asyncActions'
import {
	assignedTeamMemberIds$,
	hasSeatLimit$,
	selectedTeamMemberIds$,
	teamMembersWithTeamAssignments$,
} from './teams.selectors'
import { setSelectedTeamMemberIds, setTeamsModal } from './teams.slice'

const RECORDS_PER_PAGE = 15

export const TeamMembersTable = () => {
	const dispatch = useAppDispatch()
	const activeSeatLimit = useSelector(activeSeatLimit$)
	const activeSub = useSelector(activeSub$)
	const assignedTeamMemberIds = useSelector(assignedTeamMemberIds$)
	const hasSeatLimit = useSelector(hasSeatLimit$)
	const isAccountIntegrated = useSelector(isAccountIntegrated$)
	const maxTeamSeatLimit = useSelector(maxTeamSeatLimit$)
	const selectedTeamMemberIds = useSelector(selectedTeamMemberIds$)
	const teamMembersWithTeamAssignments = useSelector(teamMembersWithTeamAssignments$)

	const wasAccountIntegrated = usePrevious(isAccountIntegrated)

	const [searchData, setSearchData] = useState('')

	const normalizeMemberName = (name: string) => name.trim().toLowerCase()

	const filteredTeamMembers = useMemo(() => {
		const sortedTeamMembers = orderBy(
			teamMembersWithTeamAssignments,
			(tm) => tm.teamAssignments.length,
			['desc']
		)

		if (!searchData) return sortedTeamMembers

		return sortedTeamMembers.filter((tm) => normalizeMemberName(tm.fullName).includes(searchData))
	}, [searchData, teamMembersWithTeamAssignments])

	const nonAssignedSelectedUsers = useMemo(
		() => selectedTeamMemberIds.filter((id) => !assignedTeamMemberIds.includes(id)),
		[assignedTeamMemberIds, selectedTeamMemberIds]
	)

	const hasReachedLimit =
		hasSeatLimit ||
		selectedTeamMemberIds.length >= maxTeamSeatLimit ||
		assignedTeamMemberIds.length + nonAssignedSelectedUsers.length >= activeSeatLimit

	const handleAssignSelectedClick = useFn(() => {
		dispatch(setTeamsModal(TeamsModals.ASSIGN_TEAM_MEMBERS_TO_TEAMS))
	})

	const handleAssignTeamMemberClick = useFn((teamMember: TeamMember) => {
		if (
			hasReachedLimit &&
			!assignedTeamMemberIds.includes(teamMember.id) &&
			!selectedTeamMemberIds.includes(teamMember.id)
		)
			return

		dispatch(setSelectedTeamMemberIds([teamMember.id]))
		dispatch(setTeamsModal(TeamsModals.ASSIGN_TEAM_MEMBER_TO_TEAMS))
	})

	const handleSelectRow = useFn((row: TeamMember, isSelected: boolean) => {
		if (isSelected && hasReachedLimit && !assignedTeamMemberIds.includes(row.id)) return

		const newSelectedTeamMemberIds = isSelected
			? [...selectedTeamMemberIds, row.id]
			: without(selectedTeamMemberIds, row.id)

		dispatch(setSelectedTeamMemberIds(newSelectedTeamMemberIds))
	})

	const handleSelectAllRows = useFn((isSelected: boolean, rows: TeamMember[]) => {
		const newSelectedTeamMembers = isSelected
			? rows.length >= activeSeatLimit
				? filterAvailableTeamMembers(rows, assignedTeamMemberIds, maxTeamSeatLimit)
				: rows
			: []

		dispatch(setSelectedTeamMemberIds(map(newSelectedTeamMembers, 'id')))
	})

	const handleSearchTeamMembers = useFn((e: ChangeEvent<HTMLInputElement>) => {
		setSearchData(normalizeMemberName(e.target.value || ''))
	})

	const ViewTeamNameFormatter = (teams: Team[] = []) => (
		<div className="justify-content-space-between d-flex flex-column flex-md-row">
			<BadgesWithTooltips maxLength={12} maxVisible={2} labels={map(teams, 'name')} />
			{isEmpty(teams) && <span>No teams assigned</span>}
		</div>
	)

	const ViewNameFormatter = (fullName: string, teamMember: TeamMember) => (
		<div className="d-flex align-items-center max-col-width">
			<UserThumbnail user={teamMember} className="team-member-table-img" />
			<span className="text-truncate">{teamMember.fullName}</span>
		</div>
	)

	const ViewSourceFormatter = (source: IntegrationProvider, teamMember: TeamMember) => (
		<div className="d-flex align-items-center max-col-width">
			<span className="text-truncate">{integrationName(teamMember.source)}</span>
		</div>
	)

	const ViewIconFormatter = (id: string, teamMember: TeamMember) => (
		<Button className="p-0" onClick={() => handleAssignTeamMemberClick(teamMember)} variant="link">
			<Image src={ShareIcon} alt="Assign Team Members" width={25} />
		</Button>
	)

	const CheckboxRenderer = ({ checked }) => (
		<Form.Check type="checkbox" checked={checked} readOnly={true} />
	)

	const rowStyles = useFn((teamMember) =>
		hasReachedLimit &&
		!teamMember.teamAssignments.length &&
		!selectedTeamMemberIds.includes(teamMember.id)
			? { opacity: 0.5, pointerEvents: 'none' as const }
			: {}
	)

	const selectRow = {
		clickToSelect: true,
		mode: 'checkbox' as const,
		onSelect: handleSelectRow,
		onSelectAll: handleSelectAllRows,
		selected: selectedTeamMemberIds,
		selectionHeaderRenderer: CheckboxRenderer,
		selectionRenderer: CheckboxRenderer,
	}

	const columns: SafeColumnDescription<TeamMemberWithTeamAssignments>[] = [
		{
			dataField: 'id',
			hidden: true,
			text: '',
		},
		{
			dataField: 'fullName',
			formatter: ViewNameFormatter,
			sort: true,
			text: 'Name',
		},
		{
			dataField: 'teamAssignments',
			formatter: ViewTeamNameFormatter,
			text: 'Assigned To Team(s)',
		},
		{
			dataField: 'source',
			formatter: ViewSourceFormatter,
			sort: true,
			text: 'Source',
		},
		{
			dataField: 'id',
			formatter: ViewIconFormatter,
			text: '',
			events: { onClick: (e) => e.stopPropagation() },
		},
	]

	useEffect(() => {
		if (wasAccountIntegrated === false && isAccountIntegrated) {
			dispatch(fetchTeamMembers({ force: true }))
		}
	}, [isAccountIntegrated])

	useEffect(
		() => () => {
			dispatch(setSelectedTeamMemberIds(null))
		},
		[]
	)

	useEffect(() => {
		if (hasReachedLimit) {
			const msg =
				selectedTeamMemberIds.length >= maxTeamSeatLimit
					? `Limit Reached. Only ${maxTeamSeatLimit} allowed.`
					: `You have reached the maximum of ${activeSeatLimit} total managed team members.`
			dispatch(
				addErrorToast({
					title: 'Team member limit reached',
					msg,
				})
			)
		}
	}, [hasReachedLimit, nonAssignedSelectedUsers])

	return (
		<DisableOverlay isDisabled={!activeSub}>
			<BusyIndicator
				region={[
					IndicatorRegions.TEAM_MEMBERS,
					IndicatorRegions.FETCH_TEAMS,
					IndicatorRegions.FETCH_SUBS,
				]}
			>
				<h3>Team Members</h3>

				<Row>
					<Form.Group as={Col} md={5} xl={4}>
						<Form.Control
							className="search-control"
							onChange={handleSearchTeamMembers}
							placeholder="Search Users"
						/>
					</Form.Group>

					{notEmpty(selectedTeamMemberIds) && (
						<Form.Group as={Col} className="d-flex align-items-center ml-auto" md={5} xl={4}>
							<Button
								className="d-inline-flex align-items-center text-white mr-n3 ml-md-auto"
								onClick={handleAssignSelectedClick}
								variant="link"
							>
								<span>Assign Selected Users to Team </span>
								<Image className="ml-4" src={ShareIcon} alt="share-icon" width={25} />
							</Button>
						</Form.Group>
					)}
				</Row>

				<Card>
					<BusyIndicator region={IndicatorRegions.TEAM_MEMBERS}>
						<PaginationTable<TeamMemberWithTeamAssignments>
							columns={columns}
							data={filteredTeamMembers}
							keyField="id"
							hover={true}
							rowStyle={rowStyles}
							selectRow={selectRow}
							sizePerPage={RECORDS_PER_PAGE}
							totalSize={
								filteredTeamMembers.length > RECORDS_PER_PAGE ? filteredTeamMembers.length : 0
							}
						/>
					</BusyIndicator>
				</Card>
			</BusyIndicator>
		</DisableOverlay>
	)
}
