/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useEffect, useRef } from 'react'

/**
 * Keeps a ref of whether the component is mounted
 */
export const useMounted = (): (() => boolean) => {
	const mountedRef = useRef(true)

	useEffect(() => {
		mountedRef.current = true

		return () => {
			mountedRef.current = false
		}
	}, [])

	return () => mountedRef.current
}
