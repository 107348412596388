import { Banner } from '../widgets/Banners/Banner'
import { BannerWithBtn } from '../widgets/Banners/BannerWithBtn'

export const DemoMiscPage = () => {
	const onBtnClick = () => {
		alert('pushed button')
	}

	const shortMessage = 'This is a short test message for the banner component.'
	const longMessage =
		'This is a long test message for the banner component that should span multiple lines and give a good sense of how a long message will look in a banner.  More text here to get a few more characters.'

	return (
		<>
			<Banner msg={shortMessage} />
			<Banner msg={longMessage} />
			<BannerWithBtn msg={shortMessage} btnMsg={'Submit'} onClick={onBtnClick} />
			<BannerWithBtn msg={longMessage} btnMsg={'Submit'} onClick={onBtnClick} />
		</>
	)
}
