import type { MotivSubscription, SKUDef, PurchaseInfo, InvoiceData } from '@motiv-shared/server'

import { createSlice } from '@reduxjs/toolkit'
import { resetAction } from '../actions/resetAction'
import {
	fetchSKUDefs,
	fetchSubscriptionInfo,
	fetchPaymentSources,
	fetchInvoices,
	fetchPurchaseInfo,
} from './subscription.asyncActions'

export type SubscriptionState = {
	readonly defs: SKUDef[]
	readonly invoices: InvoiceData[]
	readonly purchaseInfo: Maybe<PurchaseInfo>
	readonly subs: MotivSubscription[]
}

export type WithSubscriptionState = {
	readonly subscription: SubscriptionState
}

const initialSubscriptionState: SubscriptionState = {
	defs: [],
	invoices: [],
	purchaseInfo: null,
	subs: [],
}

export const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState: initialSubscriptionState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction.type, () => initialSubscriptionState)
			.addCase(fetchInvoices.fulfilled, (s, { payload }) => {
				if (payload) s.invoices = payload
			})
			.addCase(fetchPurchaseInfo.fulfilled, (s, a) => {
				s.subs = a.payload
			})
			.addCase(fetchPaymentSources.fulfilled, (s, { payload }) => {
				// TODO: How do we identify the valid payment source, if we get many,
				//  which one do we present? In OP we had a status property that let
				//  us know it was active.
				const purchaseInfo = payload[0]

				if (purchaseInfo) s.purchaseInfo = purchaseInfo
			})
			.addCase(fetchSubscriptionInfo.fulfilled, (s, { payload }) => {
				s.subs = payload
			})
			.addCase(fetchSKUDefs.fulfilled, (s, { payload }) => {
				return { ...s, defs: payload.defs }
			}),
})

export const sanitizeSubscriptionState = (s: SubscriptionState) => ({
	...s,
	purchaseInfo: null,
	invoices: [],
})
