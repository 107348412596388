/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useRef } from 'react'

/**
 * Creates an immutable function reference. Used to limit re-renders and to
 * create a closure for local variables.
 */
export const useFn = <T extends (...args: any[]) => void>(fn: T): T => {
	const fnRef = useRef<T>(fn)

	fnRef.current = fn

	return useRef((...args: Parameters<T>) => fnRef.current(...args)).current as T
}
