import { useFn } from '@motiv-shared/react'
import { notEmpty } from '@motiv-shared/util'
import cls from 'classnames'
import type { MouseEvent } from 'react'
import { useMemo } from 'react'
import type { PageButtonRendererOptions, PaginationOptions } from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import Image from 'react-bootstrap/Image'
import DoubleArrow from '../../assets/icons/double-right-arrow.svg'
import type { TableProps } from '../../widgets/Table'
import { Table } from '../../widgets/Table'

type PaginationProps<T extends object = any, K = number> = TableProps<T, K> &
	Pick<PaginationOptions, 'sizePerPage' | 'totalSize'>

export const PaginationTable = <T extends object = any, K = number>({
	columns,
	data,
	keyField,
	onTableChange,
	rowStyle,
	selectRow,
	sizePerPage,
	totalSize,
	...tableProps
}: PaginationProps<T, K>) => (
	<PaginationProvider
		pagination={paginationFactory({
			custom: true,
			pageButtonRenderer,
			sizePerPage,
			totalSize,
		})}
	>
		{({ paginationProps, paginationTableProps }: any) => {
			return (
				<div className="table-with-pagination">
					<Table<T>
						columns={columns}
						data={data}
						keyField={keyField}
						onTableChange={onTableChange}
						rowStyle={rowStyle}
						selectRow={selectRow}
						{...tableProps}
						{...paginationTableProps}
					/>

					{(totalSize == null || totalSize > 0) && notEmpty(data) && (
						<PaginationListStandalone {...paginationProps} bootstrap4 alwaysShowAllBtns />
					)}
				</div>
			)
		}}
	</PaginationProvider>
)

// NOTE: This garbage is how you create custom buttons in the paginator
//  the docs say you can pass `JSX.Element` to `firstPageText`, etc.,
//  but if you do, you'll end up w/ a duplicate key error.
const pageButtonRenderer = ({
	active,
	disabled,
	onPageChange,
	page,
	title,
}: PageButtonRendererOptions) => {
	const handleClick = useFn((e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		// NOTE: This doesn't even take two arguments. It's typed wrong.
		;(onPageChange as any)(page)
	})

	const Page = useMemo(() => {
		switch (page) {
			case '<<':
			case '>>': {
				return (
					<Image
						className={`${page === '<<' ? 'first' : 'last'}-icon`}
						src={DoubleArrow}
						width={20}
					/>
				)
			}

			case '<':
				return 'Back'

			case '>':
				return 'Next'
		}

		return page
	}, [page])

	return (
		<li
			className={cls('page-item', active && 'active', disabled && 'disabled')}
			key={page}
			title={title}
		>
			<a className="page-link" href="#" onClick={handleClick}>
				{Page}
			</a>
		</li>
	)
}
