/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import { fetchPaymentSources, fetchPurchaseInfo, fetchSKUDefs } from '@motiv-shared/reducers'
import React, { useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { ListGroupLink } from '../../widgets/ListGroupLink'
import { BillingInvoices } from './BillingInvoices'
import { BillingOverview } from './BillingOverview'
import { BillingPlan } from './BillingPlan'

export const BillingPage = () => {
	const dispatch = useAppDispatch()

	const { pathname } = useLocation()
	const isPlanDetailsPage = pathname.includes('/plan')

	useEffect(() => {
		dispatch(fetchSKUDefs({ indicatorRegion: IndicatorRegions.BILLING }))
		dispatch(fetchPurchaseInfo({ indicatorRegion: IndicatorRegions.BILLING }))
		dispatch(fetchPaymentSources({ indicatorRegion: IndicatorRegions.BILLING }))
		// FIXME: Refactor regions. Below gets called by BillingBanners
		// dispatch(fetchTeams({ indicatorRegion: IndicatorRegions.BILLING }))
		// dispatch(fetchTeamMembers({ indicatorRegion: IndicatorRegions.BILLING }))
	}, [])

	return (
		<BusyIndicator region={[IndicatorRegions.BILLING, IndicatorRegions.FETCH_TEAMS]}>
			<Row>
				<Col className="mb-4 mb-lg-0" lg={3}>
					<Card className="mb-4">
						<Card.Header as="h3">Billing</Card.Header>

						<ListGroup variant="flush">
							<ListGroupLink to="/billing/plan">Current Plan</ListGroupLink>
							<ListGroupLink to="/billing/invoices">Invoices</ListGroupLink>
						</ListGroup>
					</Card>

					{isPlanDetailsPage && <BillingOverview />}
				</Col>

				<Col lg={9}>
					<Switch>
						<Route path="/billing/plan" component={BillingPlan} />
						<Route path="/billing/invoices" component={BillingInvoices} />
						<Redirect to="/billing/plan" />
					</Switch>
				</Col>
			</Row>
		</BusyIndicator>
	)
}
