/**
 * @fileOverview From Dan Abramov
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @author Brian Frichette (brian@eturi.com)
 */

import { useEffect } from 'react'
import { useFn } from './useFn'

export const useInterval = (callback: () => void, delay?: number | null) => {
	const callbackField = useFn(callback)

	// Set up the interval.
	useEffect(() => {
		if (delay != null) {
			const id = window.setInterval(callbackField, delay)
			return () => window.clearInterval(id)
		}
	}, [delay])
}
