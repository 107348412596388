import { useFn, useThrottle } from '@motiv-shared/react'
import { activeSubExpiry$, hasUserPermission, isTrialSub$ } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { user$ } from '../../reducers'
import { Banner } from '../../widgets/Banners/Banner'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const FreeTrialExpiringBanner = () => {
	const history = useHistory()
	const activeSubExpiry = useSelector(activeSubExpiry$)
	const isTrialSub = useSelector(isTrialSub$)
	const user = useSelector(user$)

	const getToday = useThrottle(() => moment().startOf('day'), 10_000)
	const today = getToday()!

	const canModifyBilling = useMemo(() => {
		return user && hasUserPermission(user, UserPermissions.CAN_VIEW_BILLING_TAB)
	}, [user])

	const handleBannerBtnClick = useFn(() => {
		history.push('/billing')
	})

	const daysLeft = useMemo(() => {
		if (!activeSubExpiry) return null

		const expiryDurationMs = moment(activeSubExpiry).startOf('day').diff(today)

		return moment.duration(expiryDurationMs).asDays()
	}, [activeSubExpiry, today])

	const endDate = useMemo(() => {
		if (daysLeft == null) return ''

		if (daysLeft === 1) {
			return 'tomorrow'
		} else if (daysLeft === 0) {
			return 'today'
		} else {
			return `in ${daysLeft} days`
		}
	}, [daysLeft])

	const visible = isTrialSub && daysLeft != null && daysLeft < 4 && daysLeft > -1

	if (!visible) return null

	return canModifyBilling ? (
		<BannerWithBtn
			msg={`Your free trial is ending ${endDate}. Upgrade now to avoid interruption.`}
			btnMsg={'Upgrade Now'}
			onClick={handleBannerBtnClick}
		/>
	) : (
		<Banner msg={`Your free trial is ending ${endDate}. Account Owner or Admin action required.`} />
	)
}
