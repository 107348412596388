import has from 'lodash/has'
import type { Account } from './Account'
import type { ClientType } from './API'
import type { MotivUser } from './MotivUser'

/**
 * Enum of actions a client can request in response to an identify request.
 */
export enum IdentifyAction {
	IDENTIFY = 'identify',
	CREATE_ACCOUNT = 'create_account',
	ACCEPT_INVITATION = 'accept_invitation',
	REJECT_INVITATION = 'reject_invitation',
	// Possible: Merge identities? Change identity? Join account?
}

/**
 * Enum of non-error results of an identify request.
 */
export enum IdentifyStatus {
	IDENTIFIED = 'identified',
	ACTION_REQUIRED = 'action_required',
	ACCOUNT_CREATED = 'account_created',
	INVITATION_ACCEPTED = 'invitation_accepted',
	INVITATION_REJECTED = 'invitation_rejected',
}

export type IdentifyCreateData = {
	readonly accountName?: string
	readonly action?: IdentifyAction
	readonly clientType?: ClientType
	readonly userFullName?: string
	readonly userMarketingOptIn?: boolean
}

export type IdentifyRes =
	| IdentifyResSuccess
	| IdentifyResActionRequired
	| IdentifyResInvitationRejected

export type IdentifyStatusSuccess =
	| IdentifyStatus.IDENTIFIED
	| IdentifyStatus.ACCOUNT_CREATED
	| IdentifyStatus.INVITATION_ACCEPTED

export type IdentifyResBase = {
	clientActions: IdentifyAction[]
	status: IdentifyStatus // Non-error result status
	systemInfo?: { [key: string]: any } // Important system attributes
}

export type IdentifyResSuccess = IdentifyResBase & {
	account: Account // The account associated with the token's user
	status: IdentifyStatusSuccess
	user: MotivUser // The user associated with the token
}

export type IdentifyResActionRequired = IdentifyResBase & {
	identityInfo: { email: string; accountId?: string }
	status: IdentifyStatus.ACTION_REQUIRED
}

export type IdentifyResInvitationRejected = IdentifyResBase & {
	identityInfo: { email: string; accountId: string }
	status: IdentifyStatus.INVITATION_REJECTED
}

export type IdentifyRemoveQueryArgs = {
	readonly accountId?: string
	readonly action?: IdentifyAction
}

export const isIdentifySuccessRes = <T extends IdentifyRes>(
	res: T
): res is T & IdentifyResSuccess => has(res, 'account')

export const isIdentifyActionRequiredRes = <T extends IdentifyRes>(
	res: T
): res is T & IdentifyResActionRequired => res.status === IdentifyStatus.ACTION_REQUIRED

export const isIdentifyInviteRejectedRes = <T extends IdentifyRes>(
	res: T
): res is T & IdentifyResInvitationRejected => res.status === IdentifyStatus.INVITATION_REJECTED

export const isIdentifyCreateAccount = <T extends IdentifyRes>(
	res: T
): res is T & IdentifyResActionRequired =>
	res.status === IdentifyStatus.ACTION_REQUIRED &&
	res.clientActions.includes(IdentifyAction.CREATE_ACCOUNT)
