import { pick } from '@motiv-shared/util'

export type Account = {
	readonly createdAt: string
	readonly id: string
	readonly name: string
	readonly updatedAt: string
}

export const sanitizeAccount = (a: Maybe<Account>) =>
	a ? pick(a, ['createdAt', 'id', 'updatedAt']) : null
