/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useSelector } from 'react-redux'
import { userModal$ } from '../../reducers'
import { DeleteUserModal } from './DeleteUserModal'
import { ManageUserModal } from './ManageUserModal'
import { UserModals } from './userModals.constants'

export const UserModal = () => {
	const userModal = useSelector(userModal$)

	switch (userModal) {
		case UserModals.DELETE_USER:
			return <DeleteUserModal />

		case UserModals.ADD_USER:
		case UserModals.EDIT_USER:
			return <ManageUserModal />
	}

	return null
}
