import { useSelector } from 'react-redux'
import { settingsModal$ } from '../Teams'
import { AssignTeamsToUsersModal } from './AssignTeamsToUsersModal'
import { DeleteIntegrationModal } from './DeleteIntegrationModal'
import { DeleteTeamModal } from './DeleteTeamModal'
import { IntegrationModal } from './IntegrationModal'
import { ManageTeamModal } from './ManageTeamModal'
import { TeamsModals } from './teamsModals.constants'

export const TeamsModal = () => {
	const settingsModal = useSelector(settingsModal$)

	switch (settingsModal) {
		case TeamsModals.ASSIGN_TEAM_MEMBER_TO_TEAMS:
		case TeamsModals.ASSIGN_TEAM_MEMBERS_TO_TEAMS:
			return <AssignTeamsToUsersModal />

		case TeamsModals.DELETE_INTEGRATION:
			return <DeleteIntegrationModal />

		case TeamsModals.DELETE_TEAM:
			return <DeleteTeamModal />

		case TeamsModals.INTEGRATION:
			return <IntegrationModal />

		case TeamsModals.CREATE_TEAM:
		case TeamsModals.UPDATE_TEAM:
			return <ManageTeamModal />
	}

	return null
}
