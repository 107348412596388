/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useFn } from '@motiv-shared/react'
import type { KeyboardEvent, MouseEvent } from 'react'
import { useMemo } from 'react'
import { isEnter, isKeyboardEvent, isSpace } from '../types'

export type UseKeyboardClickHandler = <T = Element>(ev: KeyboardEvent<T> | MouseEvent<T>) => void

export const useKeyboardClick = (handler: UseKeyboardClickHandler, disabled: boolean = false) => {
	// Make sure that the space key doesn't scroll the page
	const handleSpace = useFn((ev: KeyboardEvent) => {
		if (isSpace(ev)) ev.preventDefault()
	})

	const immutableHandler = useFn((ev: KeyboardEvent | MouseEvent) => {
		if (isKeyboardEvent(ev) && !(isEnter(ev) || isSpace(ev))) return
		handler(ev)
	})

	return useMemo(() => {
		if (disabled) return {}

		return {
			onClick: immutableHandler,
			onKeyUp: immutableHandler,
			onKeyDown: handleSpace,
			role: 'button',
			tabIndex: 0,
		}
	}, [disabled])
}
