import { toQueryStr } from '@motiv-shared/react'
import type { HttpExtra, WithSubscriptionState } from '@motiv-shared/reducers'
import type {
	MotivSubscription,
	PurchaseInfo,
	SKUsGetResultDefs,
	InvoiceData,
	SKUId,
	SubscriptionPeriod,
} from '@motiv-shared/server'
import { PurchaseVendor } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithSubscriptionState>()

export const fetchSKUDefs = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchSKUDefs',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<SKUsGetResultDefs>('purchases/skus/defs', {
				errorMessage: 'Unable to get SKU information.',
				...extra,
			})
		)
)

export const fetchPurchaseInfo = createAsyncThunk(
	'subscriptions/fetchPurchaseInfo',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivSubscription[]>('purchases/subscriptions', {
				errorMessage: 'Unable to get purchase info.',
				...extra,
			})
		)
)

export const fetchSubscriptionInfo = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchSubInfo',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivSubscription[]>('purchases/subscriptions', {
				errorMessage: 'Unable to get subscription information.',
				...extra,
			})
		)
)

export const fetchPaymentSources = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchPaymentSources',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<PurchaseInfo[]>('purchases/payment-sources', {
				errorMessage: 'Unable to get payment sources.',
				...extra,
			})
		)
)

export type FetchInvoicesArgs = HttpExtra & {
	readonly vendor?: PurchaseVendor
}

export const fetchInvoices = /* @__PURE__ */ createAsyncThunk(
	'purchases/invoices',
	(
		{ vendor = PurchaseVendor.STRIPE, ...extra }: FetchInvoicesArgs,
		{ dispatch, extra: { http } }
	) => {
		const query = toQueryStr({ vendor })

		return dispatch(
			http.get<InvoiceData[]>(`purchases/invoices${query}`, {
				errorMessage: 'Unable to get invoices.',
				...extra,
			})
		)
	}
)

// token will be undefined when we want to use existing payment to buy new sku
export type PurchaseSKUArgs = HttpExtra & {
	readonly token: string | undefined
	readonly sku: SKUId
	readonly period: SubscriptionPeriod
	readonly quantity: number
}

export const purchaseSku = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/purchase',
	async (
		{ period, quantity, sku, token, ...extra }: PurchaseSKUArgs,
		{ dispatch, extra: { http } }
	) => {
		// We do not retry on this call because stripe tokens are valid for one
		// attempt, thus retries will fail regardless.
		// FIXME: We could probably retry this depending on the status code
		await dispatch(
			http.post<MotivSubscription | {}>('purchases/subscriptions', {
				data: { period, quantity, sku, token, vendor: PurchaseVendor.STRIPE },
				errorMessage: 'Unable to complete purchase.',
				retry: { shouldRetry: false },
				...extra,
			})
		)

		await dispatch(syncSubscriptionInfo(true))
	}
)

export type UpdatePaymentArgs = HttpExtra & {
	readonly token: string
}

export const updatePayment = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/update-payment',
	async ({ token, ...extra }: UpdatePaymentArgs, { dispatch, extra: { http } }) => {
		// We do not retry on this call because stripe tokens are valid for one attempt,
		// thus retries will fail regardless
		await dispatch(
			http.post('purchases/subscriptions', {
				data: { token, vendor: PurchaseVendor.STRIPE },
				errorMessage: 'Unable to complete purchase.',
				retry: { shouldRetry: false },
				...extra,
			})
		)

		await dispatch(syncSubscriptionInfo(true))
	}
)

type CancelSubArgs = HttpExtra & {
	readonly sku: SKUId
	readonly vendor?: PurchaseVendor
}

export const cancelSub = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/cancel',
	async (
		{ sku, vendor = PurchaseVendor.STRIPE, ...extras }: CancelSubArgs,
		{ dispatch, extra: { http } }
	) => {
		const query = toQueryStr({ sku })

		await dispatch(
			http.delete(`purchases/subscriptions/${query}`, {
				...extras,
			})
		)

		await dispatch(syncSubscriptionInfo(true))
	}
)

export const syncSubscriptionInfo = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/sync',
	async (force: boolean, { dispatch }) => {
		await Promise.all([
			dispatch(fetchPurchaseInfo({ force })),
			dispatch(fetchPaymentSources({ force })),
		])
	}
)
