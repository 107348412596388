import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { GooglePlayBtn, IOSAppBtn } from '../../widgets/AppStoreBtns'

export const AppDownloadCard = () => (
	<Card className="mb-5 app-download-container">
		<Card.Body as={Row}>
			<Col md={6}>
				<h3 className="font-weight-bold mb-3 mb-md-0 py-3 pl-2">
					Motiv is Productivity Reporting from Anywhere
				</h3>
			</Col>

			<Col
				md={6}
				className="d-flex flex-column flex-sm-row align-items-center justify-content-lg-center"
			>
				<IOSAppBtn className="mb-3 mb-sm-0 ml-lg-auto p-0" />
				<GooglePlayBtn className="ml-sm-4 p-0" />
			</Col>
		</Card.Body>
	</Card>
)
