import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import { Form as FormikForm, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AuthRedirect, useAuth } from '../../Auth'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { useAppDispatch } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'
import { account$, deleteAccount, isAccountDeleted$, setSelectedAccountModal } from '../Account'

type DeleteAccountData = {
	readonly deleteVerify: string
}

export const DeleteAccountModal = () => {
	const { logout } = useAuth()
	const dispatch = useAppDispatch()
	const account = useSelector(account$)!
	const isAccountDeleted = useSelector(isAccountDeleted$)
	const [isSaving, setSaving] = useState(false)
	const isMounted = useMounted()

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			deleteVerify: Yup.string()
				.test({ test: (v) => v === 'DELETE', message: `Verification doesn't match.` })
				.default(''),
		})
	)
	const INITIAL_VALUES = useConstant((): DeleteAccountData => VALIDATION_SCHEMA.cast({}))

	const handleClose = useFn(() => {
		dispatch(setSelectedAccountModal(null))
	})

	const handleDeleteSubmit: FormikSubmit<DeleteAccountData> = useFn(async () => {
		if (isSaving) return

		setSaving(true)

		sentryBreadcrumb('Deleting Account')

		try {
			await dispatch(deleteAccount({ accountId: account.id }))

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to delete account')
		}

		isMounted() && setSaving(false)
	})

	useEffect(() => {
		isAccountDeleted && logout(AuthRedirect.SIGNUP)
	}, [isAccountDeleted])

	return (
		<Formik<DeleteAccountData>
			initialValues={INITIAL_VALUES}
			onSubmit={handleDeleteSubmit}
			validationSchema={VALIDATION_SCHEMA}
			validateOnMount
		>
			{(p) => {
				const getCtrlProps = formikCtrlProps(p)

				return (
					<MotivModal
						onHide={handleClose}
						size="sm"
						title="Are you sure you want to delete your account?"
					>
						<Modal.Body>
							<BusyIndicator region={IndicatorRegions.DELETE_ACCOUNT}>
								<p className="mb-5">
									Your account will be permanently deleted and cannot be recovered. Please type
									DELETE to proceed.
								</p>

								<Form as={FormikForm}>
									<Form.Group>
										<Form.Control placeholder="Type DELETE" {...getCtrlProps('deleteVerify')} />
										<FormValidationText field="deleteVerify" formikProps={p} />
									</Form.Group>
								</Form>
							</BusyIndicator>
						</Modal.Body>

						<Modal.Footer>
							<Button
								disabled={isSaving || !p.isValid}
								onClick={p.submitForm}
								size="lg"
								variant="danger"
							>
								Delete Account
							</Button>

							<Button variant="light-link" size="lg" onClick={handleClose}>
								Cancel
							</Button>
						</Modal.Footer>
					</MotivModal>
				)
			}}
		</Formik>
	)
}
