export type UserPermission = ValueUnion<typeof UserPermissions>
export const UserPermissions = {
	CAN_ACCESS_MOBILE_APP: 'canAccessMobileApp',
	CAN_TRANSFER_OR_DELETE_ACCOUNT: 'canTransferOrDeleteAccount',
	CAN_VIEW_ACCOUNT_TAB: 'canViewAccountTab',
	CAN_VIEW_BILLING_TAB: 'canViewBillingTab',
	CAN_VIEW_MOTIV_BANNER: 'canViewMotivBanner',
	CAN_VIEW_TEAM_LEAD_VIEW: 'canViewTeamLeadView',
	CAN_ASSIGN_AS_TEAM_LEAD: 'canAssignAsTeamLead',
} as const
