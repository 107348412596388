import { useChgState, useFn } from '@motiv-shared/react'
import { IntegrationProviders, integrationShortName } from '@motiv-shared/server'
import cls from 'classnames'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import type { SortOrder } from 'react-bootstrap-table-next'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useIntegrationFlow } from '../../IntegrationFlow'
import { integrationsDecorated$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { bup } from '../../util/batchUpdates'
import { MotivModal } from '../../widgets/Modal'
import type { SafeColumnDescription } from '../../widgets/Table'
import { Table } from '../../widgets/Table'
import { setTeamsModal } from '../Teams'

const { GOOGLE, MICROSOFT } = IntegrationProviders

export const IntegrationModal = () => {
	const dispatch = useAppDispatch()
	const { connect, disconnect } = useIntegrationFlow()
	const integrationsDecorated = useSelector(integrationsDecorated$)
	// NOTE: This is 'Maybe' in case we want to allow the third click to revert to
	//  the default order
	const [sortOrder, setSortOrder] = useChgState<Maybe<SortOrder>>(null)
	const [sortField, setSortField] = useChgState<Maybe<string>>(null)

	const sortedIntegrationsData = useMemo(
		() =>
			sortField && sortOrder
				? orderBy(integrationsDecorated, sortField, [sortOrder])
				: integrationsDecorated,
		[integrationsDecorated, sortField, sortOrder]
	)

	const resetModal = useFn(() => {
		dispatch(setTeamsModal(null))
	})

	const NameFormatter = (id: string, row: IntegrationDecorated) => (
		<span className="d-flex align-items-center">
			<Image
				alt={`${row.shortName} Icon`}
				className="d-none d-sm-block"
				style={{ width: '136px' }}
				src={row.isAvailable ? row.logo : row.logoBw}
			/>

			<Image
				alt={`${row.shortName} Icon`}
				className="d-block d-sm-none"
				src={row.isAvailable ? row.iconLogoSm : row.iconLogoBwSm}
			/>

			<span className="mx-4 mx-sm-5 font-weight-bold">{row.displayName}</span>

			{row.isUnauthorized && (
				<Button
					className={cls('ml-auto btn-light')}
					style={{ minWidth: '200px' }}
					onClick={() => disconnect(row.id!)}
				>
					Disconnect
				</Button>
			)}
		</span>
	)

	const ConnectButtonFormatter = (id: string, row: IntegrationDecorated) => {
		const provider = row.integrationProvider
		const BtnStyle = { minWidth: '180px' }

		if (row.isUnauthorized)
			// CASE 1: Unauthorized integration
			return (
				<span className="align-middle">
					<Button onClick={() => connect(row.integrationProvider)} style={BtnStyle} variant="light">
						Restore
					</Button>
				</span>
			)

		if (!row.isAvailable) {
			// CASE 2: Unavailable integration (not supported yet)
			return (
				<span>
					<p className="mb-0 font-weight-bolder text-muted">Coming Soon</p>
				</span>
			)
		}

		if (row.isIntegrated) {
			// CASE 3: Already connected integration
			return (
				<span>
					<Button onClick={() => disconnect(row.id!)} style={BtnStyle} variant="light">
						Disconnect
					</Button>
				</span>
			)
		}

		const google = integrationShortName(GOOGLE)
		const microsoft = integrationShortName(MICROSOFT)
		const [disconnectName, connectName] =
			provider === GOOGLE ? [microsoft, google] : [google, microsoft]

		const disconnectTxt = `Disconnect ${disconnectName} to integrate ${connectName}`

		return (
			// CASE 4: Ready to connect integration
			<span>
				<p className="mb-0 text-danger font-weight-bolder" style={{ whiteSpace: 'normal' }}>
					{disconnectTxt}
				</p>
			</span>
		)
	}

	const columns: SafeColumnDescription<IntegrationDecorated>[] = [
		{
			classes: 'align-middle',
			dataField: 'displayName',
			formatter: NameFormatter,
			sort: true,
			text: 'Name',
		},
		{
			align: 'center',
			classes: 'align-middle',
			dataField: 'id',
			formatter: ConnectButtonFormatter,
			headerClasses: 'w-237',
			text: '',
		},
	]

	const handleTableChange = useFn((type, { sortField, sortOrder }) => {
		if (type !== 'sort') return

		bup(() => {
			setSortOrder(sortOrder)
			setSortField(sortField)
		})
	})

	return (
		<MotivModal onHide={resetModal} size="lg" title="Integrations">
			<Modal.Body>
				<p>Multiple integration support coming soon.</p>

				<Table<IntegrationDecorated>
					columns={columns}
					data={sortedIntegrationsData}
					keyField="displayName"
					onTableChange={handleTableChange}
				/>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="light-link" size="lg" onClick={resetModal}>
					Close
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
