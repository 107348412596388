import { useConstant, useFn, useMounted } from '@motiv-shared/react'
import { Form as FormikForm, Formik } from 'formik'
import { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { addErrorToast, addSuccessToast, user$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { BusyIndicator, IndicatorRegions, LoadingFigure } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { fetchAccount, updateAccount } from './account.asyncActions'
import { account$ } from './account.selectors'

type CompanyFormData = {
	readonly companyName: string
}

export const CompanyNameForm = () => {
	const dispatch = useAppDispatch()
	const account = useSelector(account$)
	const user = useSelector(user$)
	const isMounted = useMounted()

	const [isSaving, setSaving] = useState(false)

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			companyName: Yup.string()
				.trim()
				.required('Please enter the company name.')
				.max(70, 'Character limit reached.')
				.default(''),
		})
	)

	const initialValues = useMemo(
		(): CompanyFormData =>
			validationSchema.cast({
				companyName: account?.name || '',
			}),
		[account]
	)

	const handleSubmit: FormikSubmit<CompanyFormData> = useFn(async ({ companyName }) => {
		if (isSaving || !(account && user) || companyName === account.name) return

		setSaving(true)

		sentryBreadcrumb('Updating Account Name')

		try {
			await dispatch(
				updateAccount({
					accountId: account.id,
					updatedAccount: { name: companyName },
				})
			)

			await dispatch(fetchAccount({ force: true }))

			dispatch(addSuccessToast(`Company name changed successfully to ${companyName}.`))
		} catch (e) {
			sentryError(e, 'Failed to update Account Name')
			dispatch(addErrorToast('Something went wrong changing account name.'))
		}

		isMounted() && setSaving(false)
	})

	if (!account) return <LoadingFigure />

	return (
		<Formik<CompanyFormData>
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{(p) => {
				const getCtrlProps = formikCtrlProps(p)

				return (
					<BusyIndicator region={IndicatorRegions.ACCOUNT}>
						<Form as={FormikForm}>
							<Form.Group>
								<Form.Label>Company Name</Form.Label>

								<Form.Control
									placeholder="Company Name"
									maxLength={70}
									{...getCtrlProps('companyName')}
								/>

								<FormValidationText field="companyName" formikProps={p} />
							</Form.Group>

							<Button
								className="btn-block"
								disabled={isSaving || p.values.companyName === account.name}
								type="submit"
								size="lg"
								variant="success"
							>
								Save
							</Button>
						</Form>
					</BusyIndicator>
				)
			}}
		</Formik>
	)
}
