import { hasUserPermission } from '@motiv-shared/reducers'
import type { MotivUser, Team, UserPermission, UserRole, UserRoleData } from '@motiv-shared/server'
import { UserPermissions, UserRoles } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { settingsTeams$ } from '../../features/Teams'
import type { MotivUserWithTeamAssignments } from '../../types'
import type { WithUsersState } from './users.slice'

const state$ = <T extends WithUsersState>(s: T) => s.users

const SSO_LOGIN_OPTS = ['google', 'windowslive']

export const allUsers$ = createSelector(state$, (s) => s.allUsers)
export const loggedInUserId$ = createSelector(state$, (s) => s.loggedInUserId)
export const userModal$ = createSelector(state$, (s) => s.userModal)
export const userRoleData$ = createSelector(state$, (s) => s.roles)
export const userToBeDeleted$ = createSelector(state$, (s) => s.userToBeDeleted)
export const userToBeEdited$ = createSelector(state$, (s) => s.userToBeEdited)
export const user$ = createSelector(loggedInUserId$, allUsers$, (loggedInUserId, allUsers) => {
	return allUsers.find((user) => user.id === loggedInUserId)
})
export const auth0Id$ = createSelector(user$, (u) => u?.auth0Id)
export const isLoggedInViaSSO$ = createSelector(auth0Id$, (auth0Id) =>
	SSO_LOGIN_OPTS.some((opt) => auth0Id?.includes(opt))
)
export const accountId$ = createSelector(user$, (u) => u?.accountId)

const isRole = (role: UserRole) => (roles: UserRoleData[]): Maybe<UserRoleData> =>
	roles.find((roleData) => roleData.id === role)

export const accountOwnerRole$ = createSelector(userRoleData$, isRole(UserRoles.ACCOUNT_OWNER))
export const adminTeamLeaderRole$ = /* @__PURE__ */ createSelector(
	userRoleData$,
	isRole(UserRoles.ADMIN_TEAM_LEAD)
)
export const adminRole$ = createSelector(userRoleData$, isRole(UserRoles.ADMIN))
export const teamLeaderRole$ = createSelector(userRoleData$, isRole(UserRoles.TEAM_LEAD))

const addAssignedTeams = (user: MotivUser, teams: Team[]): MotivUserWithTeamAssignments => {
	return {
		...user,
		assignedTeams: teams.filter((team) =>
			team.assignedTeamLeads.some((leadId) => leadId === user?.id)
		),
	}
}

export const userWithAssignmentsToBeEdited$ = createSelector(
	userToBeEdited$,
	settingsTeams$,
	allUsers$,
	(userId, teams, allUsers) => {
		if (userId == null) {
			return null
		} else {
			const user = allUsers?.find((user) => user.id === userId)
			return user ? addAssignedTeams(user, teams) : undefined
		}
	}
)

export const allUsersWithTeamAssignments$ = createSelector(
	allUsers$,
	settingsTeams$,
	(users, teams) => users.map((user) => addAssignedTeams(user, teams))
)

export const createHasPermissionsSelector = () =>
	createSelector(
		user$,
		(_, requiredPermissions: UserPermission[]) => requiredPermissions,
		(user, requiredPermissions) => {
			if (!requiredPermissions?.length) return true

			// User permissions should have all required permissions (no difference). This is type safe
			// so, e.g. `difference(['view', 'delete'], null) === ['view', 'delete']`. This function
			// works in one direction (ordered params). So `difference(['view'], ['view', 'delete'])`
			// will be `[]`, whereas `difference(['view', 'delete'], ['view'])` will be `['delete']`. So
			// it's fine if the user has more permissions than required.
			const userPermissions = user?.permissions?.map((permission) => permission.id)

			return isEmpty(difference(requiredPermissions, userPermissions || []))
		}
	)

export const canBeTeamLeadMembers$ = createSelector(allUsers$, (users) =>
	users.filter((u) => hasUserPermission(u, UserPermissions.CAN_ASSIGN_AS_TEAM_LEAD))
)
