/**
 * Allows for compilation type check of exhaustive switch statements.
 *
 * For example,
 *
 function getColorName(c: Color): string {
    switch(c) {
        case Color.Red:
            return 'red';
        case Color.Green:
            return 'green';
    }
    return assertUnreachable(c);
}
 *
 * This code will throw a compilation error if the enum Color has more entries other than Red and Green.
 *
 * @param x
 * @param message
 */
export const assertUnreachable = (x: never, message: string): never => {
	throw new Error(message)
}
