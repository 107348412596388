/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useChgState } from './useChgState'
import { useFn } from './useFn'

export const useToggleState = (
	initValue: boolean
): [state: boolean, toggleState: () => void, toggleTrue: () => void, toggleFalse: () => void] => {
	const [state, setState] = useChgState(initValue)
	const toggleState = useFn(() => setState(!state))
	const toggleOn = useFn(() => setState(true))
	const toggleOff = useFn(() => setState(false))

	return [state, toggleState, toggleOn, toggleOff]
}
