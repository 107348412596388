import type { MotivApiErrorBody } from '@motiv-shared/server'
import { pick, timeoutPromise } from '@motiv-shared/util'
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import isObject from 'lodash/isObject'
import isEqual from 'react-fast-compare'
import { v4 } from 'uuid'
import { httpUnauthorizedAction } from './actions'

export type HttpReqMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

// Retry
export type RetryOpts = {
	readonly max?: number
	readonly shouldRetry?: boolean | ((status: number) => boolean)
}

type RequiredRetryOpts = Required<RetryOpts>

type NormalizedRetry = {
	readonly max: number
	readonly shouldRetry: (status: number) => boolean
}

const DEFAULT_RETRY_OPTS: RequiredRetryOpts = {
	max: 10,
	shouldRetry: (status) =>
		status === 0 /* Network failure */ ||
		status === 408 /* Client request timeout */ ||
		status === 429 /* Too many requests */ ||
		status >= 502 /* Server-related errors */,
}

const normalizeRetry = (retry: RetryOpts = {}, defaults: Required<RetryOpts>): NormalizedRetry => {
	const shouldRetry = retry.shouldRetry || defaults.shouldRetry

	return {
		max: retry.max ?? defaults.max,
		shouldRetry: typeof shouldRetry == 'function' ? shouldRetry : () => shouldRetry,
	}
}

// Auth
type HttpAuth = {
	readonly header?: Maybe<string>
	readonly isAuthorized: boolean
	readonly requiresAuth: boolean
}

type CreateAuthThunk<State> = (
	url: string
) => ThunkAction<HttpAuth | Promise<HttpAuth>, State, any, AnyAction>

const normalizeHeaders = (headers?: any) => ({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	...headers,
})

const normalizeHttpData = (data?: any): string | undefined =>
	data == null ? undefined : isObject(data) ? JSON.stringify(data) : data

type NormalizeUrl = (pathOrUrl: string) => string | Promise<string>

export type HttpLifecycleHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>
) => ThunkAction<any, State, any, AnyAction>

export type HttpErrorHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	body?: Maybe<MotivApiErrorBody>
) => ThunkAction<any, State, any, AnyAction>

type HttpOpts = {
	readonly normalizeUrl?: NormalizeUrl
	readonly retry?: RetryOpts
	readonly useCache?: boolean
}

type RequiredHttpOpts = Required<MOmit<HttpOpts, 'retry'>> & {
	readonly retry: RequiredRetryOpts
}

type CreateHttpOpts<State, Extra = {}> = { [P in Lowercase<HttpReqMethod>]?: HttpOpts } &
	HttpOpts & {
		readonly auth: CreateAuthThunk<State>
		readonly onError: HttpErrorHandler<State, Extra>
		readonly onAfterFetch?: HttpLifecycleHandler<State, Extra>
		readonly onBeforeFetch?: HttpLifecycleHandler<State, Extra>
	}

// NOTE: This was a 3 second throttle timeout.For now we are going to set this
//  at 15 minutes, which was the same as the cache expiry on previous
//  `doAsync`. We will revisit to individually handle call caching after this
//  is merged.
const CACHE_TIMEOUT = 15 * 60 * 1000

const DEFAULT_HTTP_OPTS: RequiredHttpOpts = {
	// Identity
	normalizeUrl: (url: string) => url,
	retry: DEFAULT_RETRY_OPTS,
	useCache: false,
}

const normalizeOpts = (opts: HttpOpts): RequiredHttpOpts =>
	pick(
		{
			...DEFAULT_HTTP_OPTS,
			...opts,
			retry: normalizeRetry(opts.retry, DEFAULT_HTTP_OPTS.retry),
		},
		['normalizeUrl', 'retry', 'useCache']
	)

// The time limit for an HTTP request to resolve before it's considered lost.
const REQ_TIMEOUT = 45000

// Allow imperfectly typed HttpExtra to accept arbitrary object, but retain
// types on what we've defined (data, force, etc).
type AnyObject = {
	readonly [k: string]: any
}

export type HttpExtra<State = AnyObject, Extra = AnyObject> = {
	readonly data?: any
	readonly force?: boolean
	readonly onError?: CreateHttpOpts<State, Extra>['onError']
	readonly retry?: HttpOpts['retry']
} & Extra

type HttpMethod<State, Extra> = <R>(
	pathOrUrl: string,
	extra?: HttpExtra<State, Extra>
) => ThunkAction<Promise<R>, State, any, AnyAction>

export type MotivHttp<State, Extra> = {
	[P in Lowercase<HttpReqMethod>]: HttpMethod<State, Extra>
}

/**
 * This is meant to be created and passed as the `extraArgument` to the
 * 'redux-thunk`, when creating a store. This makes it available in async
 * thunks via `createAsyncThunk`, and allows us to define async thunks in
 * shared code that still works in environments w/ different instances of
 * this http object.
 *
 * This creates an `http` object w/ all the methods on it that is bound to
 * state and extra that can be passed. It was created to allow us to share the
 * fundamental logic between modules that have differences in the way they
 * handle things like authentication, error handling, normalizing urls, etc.
 *
 * The options require passing a way of getting an `HttpAuth` state as a thunk
 * dispatch, as well as a default error handler. Additional default options can
 * be passed for lifecycle thunks (`onBeforeFetch`, `onAfterFetch`), as well
 * as url normalization, whether to use cache by default, and default retry
 * options.
 * @see CreateHttpOpts
 * @see HttpOpts
 *
 * Additionally the default "extra" options need to be passed. These are the
 * set of options that can be passed as a second argument to any http call.
 * These include whether to `force` a call (ignore caching), and `data` to
 * be included in a POST body, etc.
 * @see HttpExtra
 *
 * The primary purpose of encoding State and Extra is to allow types to flow
 * through any of the thunks that can be called. E.g. `onError`,
 * `onAfterFetch`, etc.
 */
export const createHttp = <State, Extra>(
	opts: CreateHttpOpts<State, Extra>,
	defaultExtra: HttpExtra<State, Extra>
): MotivHttp<State, Extra> => {
	const { auth, onAfterFetch, onBeforeFetch } = opts

	const activeArgsCache = new Map()
	const retryState = new Map<string, number>()

	const httpFactory = (method: HttpReqMethod) => {
		const optsForMethod: HttpOpts | undefined = opts[method.toLowerCase()]
		const defaults = normalizeOpts({ ...opts, ...optsForMethod })

		const cacheForArgs = (url: string, headers: any, data: any) => {
			let args = [method, url, headers, data]

			// Normalize args. Since these are a list, they will be different each
			// time. So find the args that are equal to these (if any). This ensures
			// all methods use the same args, as long as they are equal, across
			// different requests. Specifically, this means that if something calls
			// http with `force: true`, it can replace existing data.
			// It might seem odd to do it this way, but it's fine for now. It's a
			// strategy to build a cache key, of which there are many. We can address
			// a more efficient method if desired in the future.
			args = [...activeArgsCache.keys()].find((k) => isEqual(k, args)) || args

			return {
				get: <R>(): Promise<R> | null => {
					const now = Date.now()

					// Prune expired cache
					activeArgsCache.forEach((v, k, c) => {
						if (v.ts < now - CACHE_TIMEOUT) c.delete(k)
					})

					return activeArgsCache.get(args)?.p || null
				},

				set: <R>(p: Promise<R>) => {
					activeArgsCache.set(args, { p, ts: Date.now() })
				},

				delete: () => activeArgsCache.delete(args),
			}
		}

		return <R>(
			pathOrUrl: string,
			extra: HttpExtra<State, Extra> = defaultExtra
		): ThunkAction<Promise<R>, State, any, AnyAction> => async (dispatch) => {
			const { data, force = false, onError = opts.onError } = extra
			const retry = normalizeRetry(extra.retry, defaults.retry)

			const url = await defaults.normalizeUrl(pathOrUrl)
			const authState = await dispatch(auth(url))

			const rejectAuth = () => {
				dispatch(httpUnauthorizedAction)
				dispatch(onError(extra))
				// NOTE: We could add specific error codes later
				return new Error('Not authenticated. Could not make an authenticated call.')
			}

			if (authState.requiresAuth && !authState.isAuthorized) {
				return Promise.reject(rejectAuth())
			}

			const body = normalizeHttpData(data)
			const headers = normalizeHeaders(
				authState.requiresAuth ? { Authorization: authState.header } : undefined
			)

			const cache = cacheForArgs(url, headers, body)
			const activePromiseForArgs = cache.get<R>()

			if (!force && activePromiseForArgs) return activePromiseForArgs

			const handleError = (e: any) => {
				if (isApiError(e)) {
					dispatch(onError(extra, e.body))

					if (e.status === 401) return Promise.reject(rejectAuth())
				} else {
					dispatch(onError(extra))
				}

				return Promise.reject(e)
			}

			const reqId = v4()
			let retryInterval = 1000

			const createReqPromise = async (): Promise<R> => {
				// We make sure the request doesn't time out if the retry interval gets
				// too long. This means the timeout is always at least 1 sec after retry
				// interval.
				const reqTimeoutDuration = Math.max(REQ_TIMEOUT, retryInterval + 1000)

				// Set the request timeout.
				// FIXME: Use globalThis
				const timeoutId = window.setTimeout(cache.delete, reqTimeoutDuration)

				// Define closures for error object, response, and status. Default status
				// is for cases where request fails completely, such as no network.
				let err
				let res: Maybe<Response>
				let status = 0

				// Fetch can fail if there's no network.
				try {
					res = await fetch(url, { body, headers, method })
					status = res.status
				} catch (e) {
					console.error('Fetch error', e)
					err = e
				}

				// Clear the request timeout immediately after we get a response. This
				// doesn't change the response for anything listening to the promise,
				// but does allow new requests of the same type to come in.
				window.clearTimeout(timeoutId)

				const retries = retryState.get(reqId) || 0
				const hasMaxRetries = retries >= retry.max
				const shouldRetry = !res?.ok && !hasMaxRetries && retry.shouldRetry(status)

				if (!res || shouldRetry) {
					if (!shouldRetry) {
						cache.delete()

						throw err
					}

					// Set the retry state
					retryState.set(reqId, retries)

					// Wait for the current retry interval before trying again
					await timeoutPromise(retryInterval)

					// Increment the retries
					retryState.set(reqId, retries + 1)

					// Increment the retry interval each time by 125%.
					retryInterval = Math.floor(retryInterval * 1.25)

					// Finally, retry the request.
					return createReqPromise()
				}

				// Clear the retry state if we have one.
				retryState.delete(reqId)

				const parsedRes = await parseResponse(res)

				// Handle request error
				if (!res.ok) {
					throw new ApiError(
						'Request failed',
						status,
						isMotivApiErrorBody(parsedRes) ? parsedRes : null
					)
				}

				return parsedRes
			}

			onBeforeFetch && dispatch(onBeforeFetch(extra))

			const newActivePromiseForArgs = createReqPromise()
				.catch(handleError)
				.finally(() => {
					onAfterFetch && dispatch(onAfterFetch(extra))
				})

			cache.set(newActivePromiseForArgs)

			return newActivePromiseForArgs
		}
	}

	return {
		delete: httpFactory('DELETE'),
		get: httpFactory('GET'),
		patch: httpFactory('PATCH'),
		post: httpFactory('POST'),
		put: httpFactory('PUT'),
	}
}

const parseResponse = async (res: Response): Promise<any> => {
	// We attempt to parse response but some responses are empty so we catch
	// parse errors and simply return nothing.
	try {
		// NOTE: We parse text instead of res.json, b/c the JSON method will
		//  throw synchronously causing the debugger to pause as an unhandled
		//  promise rejection (even though it's handled). We can remove this
		//  and go back to using res.json() when we have full native async
		//  / await support. res.text() always returns a string and we can
		//  catch any JSON parse errors ourselves.
		const text = await res.text()

		if (!text) return undefined as any

		return JSON.parse(text)
	} catch {
		/**/
	}

	return undefined as any
}

export const isMotivApiErrorBody = (error: any): error is MotivApiErrorBody =>
	error != null &&
	typeof error == 'object' &&
	error.className != null &&
	error.code != null &&
	error.message != null &&
	error.name != null

export const isApiError = (error: any): error is ApiError => error instanceof ApiError

export class ApiError extends Error {
	constructor(msg: string, readonly status: number, readonly body: Maybe<MotivApiErrorBody>) {
		super(msg)

		if (Error.captureStackTrace) Error.captureStackTrace(this, ApiError)

		this.body = body
		this.name = 'ApiError'
		this.status = status
	}
}
