import isEqual from 'react-fast-compare'

export const arrayAddOrUpdate = <T, Arr extends T[] | readonly T[]>(
	arr: Arr,
	item: T,
	key: keyof T
): Arr => {
	const itemKey = item[key]
	const itemIdx = arr.findIndex((i) => i[key] === itemKey)

	if (itemIdx === -1) return [...arr, item] as Arr

	const prevItem = arr[itemIdx]

	if (isEqual(prevItem, item)) return arr

	return [...arr.slice(0, itemIdx), item, ...arr.slice(itemIdx + 1)] as Arr
}
