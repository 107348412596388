/**
 * @author Brian Frichette (brian@eturi.com)
 */

import identity from 'lodash/identity'

/** Makes sure an array contains only truthy values */
export const unaryEvery = (arr: any[]) => arr.every(identity)

/** Make sure arguments (as in selectors) are all boolean values  */
export const selectUnaryEvery = (...args: any[]) => unaryEvery(args)
