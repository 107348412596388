import { httpUnauthorizedAction, resetAction } from '@motiv-shared/reducers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Auth0User } from '../../types'

export type AuthState = {
	readonly jwtToken: Maybe<string>
	readonly shouldLogout: boolean
	readonly user: Maybe<Auth0User>
}

export type WithAuthState = {
	readonly auth: AuthState
}

const initialState: AuthState = {
	jwtToken: null,
	shouldLogout: false,
	user: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthUser(s, a: PayloadAction<Maybe<Auth0User>>) {
			s.user = a.payload
		},

		setJwtToken(s, a: PayloadAction<Maybe<string>>) {
			s.jwtToken = a.payload
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)
			.addCase(httpUnauthorizedAction.type, (s) => {
				// NOTE: Logging out returns initialState, so this clears `shouldLogout`
				s.shouldLogout = true
			})
	},
})

export const { setJwtToken, setAuthUser } = authSlice.actions

export const sanitizeAuthState = (_: AuthState) => null

export const typeOnlyAuthActions = new Set([setJwtToken.type, setAuthUser.type])
