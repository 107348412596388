/**
 * @fileOverview Sentry helpers
 * @author Brian Frichette (brian@eturi.com)
 */

import * as Sentry from '@sentry/react'
import type { Action, AnyAction, PreloadedState, Reducer, StoreEnhancerStoreCreator } from 'redux'

type BreadcrumbWithMsg = Sentry.Breadcrumb & { message: string }

export const sentryBreadcrumb = (message: string | BreadcrumbWithMsg) => {
	const breadcrumb: Sentry.Breadcrumb = { level: Sentry.Severity.Info }

	if (typeof message === 'string') {
		breadcrumb.message = message
	} else {
		Object.assign(breadcrumb, message)
	}

	Sentry.addBreadcrumb(breadcrumb)
}

/**
 * Capture a sentry exception. If a context is passed (string, object, etc), it
 * will be added to the scope.
 */
export const sentryError = <T extends Record<string, unknown>>(e: any, ctx?: T | string) => {
	Sentry.captureException(e, (s) => {
		if (!ctx) return s

		const normalizedCtx = typeof ctx === 'string' ? { message: ctx } : ctx

		return s.setContext('Error Ctx', normalizedCtx)
	})
}

export type SentryActionTransformer = <A extends Action = AnyAction>(action: A) => any | null
export type SentryStateTransformer = <S = any>(s: S | undefined) => any | null
export type SentryEnhancerOpts = {
	readonly actionTransformer: SentryActionTransformer
	readonly stateTransformer: SentryStateTransformer
}

const defaultSentryEnhancerOpts: SentryEnhancerOpts = {
	actionTransformer: (a) => a,
	stateTransformer: (s) => s || null,
}

/**
 * This is roughly the same implementation that is included with @sentry/react
 * but it uses `Sentry.addBreadcrumb` (hub call) instead of
 * `scope.addBreadcrumb`. By using `scope` instead of the hub, the default
 * implementation introduces a memory leak in some cases. Calling
 * `addBreadcrumb` on the scope does not derive defaults for `maxBreadcrumbs`,
 * so if you don't pass `maxBreadcrumbs` (as @sentry/redux does not), it's
 * possible to infinitely add breadcrumbs that are never trimmed. Anything else
 * that calls `addBreadcrumb` on the hub will trigger the trimming behavior, but
 * if there are many redux actions being fired without anything calling the hub,
 * this list will grow indefinitely.
 *
 * I opened a bug at https://github.com/getsentry/sentry-javascript/issues/2963
 * So we can potentially remove this once that is fixed.
 */
export const createSentryReduxEnhancer = (opts?: Partial<SentryEnhancerOpts>) => {
	const { actionTransformer, stateTransformer } = {
		...defaultSentryEnhancerOpts,
		...opts,
	}

	return (next: StoreEnhancerStoreCreator): StoreEnhancerStoreCreator => <
		S = any,
		A extends Action = AnyAction
	>(
		reducer: Reducer<S, A>,
		initialState?: PreloadedState<S>
	) => {
		const sentryReducer: Reducer<S, A> = (s, a) => {
			const newState = reducer(s, a)
			const transformedAction = actionTransformer(a)
			const transformedState = stateTransformer(s)

			if (transformedAction != null) {
				Sentry.addBreadcrumb({
					category: 'redux.action',
					data: transformedAction,
					type: 'info',
				})
			}

			Sentry.setContext('redux.state', transformedState ?? null)

			return newState
		}

		return next(sentryReducer, initialState)
	}
}
