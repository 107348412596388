import './PillLabel.scss'

import { useFn } from '@motiv-shared/react'
import type { MouseEventHandler, ReactNode, TouchEventHandler } from 'react'
import Image from 'react-bootstrap/Image'
import X_ICON from '../../assets/icons/x.svg'

type PillLabelProps<T extends Maybe<string> = undefined> = {
	readonly children?: ReactNode
	readonly id?: T
	readonly onDismiss: (id: T) => void
}

export const PillLabel = <T extends Maybe<string> = undefined>(p: PillLabelProps<T>) => {
	const onDismiss = useFn(() => p.onDismiss(p.id!))
	return <BasePillLabel onClick={onDismiss}>{p.children}</BasePillLabel>
}

type BasePillLabelProps = {
	readonly children?: ReactNode
	readonly onClick?: MouseEventHandler<HTMLButtonElement>
	readonly onMouseDown?: MouseEventHandler<HTMLButtonElement>
	readonly onTouchEnd?: TouchEventHandler<HTMLButtonElement>
}

// This is a generic version that is compatible w/ react-select.
// We can remove this when we remove react-select.
export const BasePillLabel = ({ children, ...events }: BasePillLabelProps) => (
	<div className="pill-label">
		<span className="pill-label__label">{children}</span>
		<button className="pill-label__dismiss" {...events}>
			<Image alt="Close Icon" src={X_ICON} />
		</button>
	</div>
)
