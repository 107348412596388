import fromPairs from 'lodash/fromPairs'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { AuthRedirect, setAuthRedirect, setInviteActions, useAuth } from '../Auth'
import { isAuthenticated$ } from '../reducers'
import { LoadingFigure } from '../widgets/BusyIndicator'

/**
 * This is simply a page that handles sign-up invitations and then
 * invokes the appropriate actions.
 */
export const InvitePage = () => {
	const isAuthenticated = useSelector(isAuthenticated$)

	const { isReady } = useAuth()

	try {
		const paramsPairs = [...new URLSearchParams(location.search)]

		// Stores invitation details and redirect so when we are re-routed
		// back to our app on logout we can continue the invitation flow
		setInviteActions(fromPairs(paramsPairs))
		setAuthRedirect(AuthRedirect.INVITE)
	} catch {
		//
	}

	// We wait for isReady so that we know for sure if we are logged in
	// or not. If we are we will first logout before doing initiating
	// the invitation flow
	if (!isReady) return <LoadingFigure />

	return isAuthenticated ? <Redirect to="/logout" /> : <Redirect to="/login" />
}
