import { useFn } from '@motiv-shared/react'
import type { Integration } from '@motiv-shared/server'
import { integrationName } from '@motiv-shared/server'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import { useSelector } from 'react-redux'
import WARNING_IMG from '../../assets/images/warning.png'
import { hasUnauthorizedIntegrations$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { TeamsModals } from '../TeamsModal'
import { setTeamsModal } from './teams.slice'

export const Integrations = ({ integrations }: { integrations: Integration[] }) => {
	const dispatch = useAppDispatch()
	const hasUnauthorizedIntegrations = useSelector(hasUnauthorizedIntegrations$)

	const handleManageClick = useFn(() => dispatch(setTeamsModal(TeamsModals.INTEGRATION)))

	return (
		<Card className="mb-5">
			<Card.Header className="d-flex align-items-center" as="h5">
				<span>Integrations</span>

				<Button className="ml-auto" onClick={handleManageClick} size="sm">
					Manage
				</Button>
			</Card.Header>

			<BusyIndicator region={IndicatorRegions.INTEGRATIONS}>
				<ListGroup variant="flush">
					{integrations.map((integration) => (
						<ListGroup.Item className="f-1 font-weight-bold" key={integration.id}>
							<div className="w-100 d-flex align-items-center justify-content-between">
								{integrationName(integration.integrationProvider)}

								{hasUnauthorizedIntegrations && (
									<Image alt="Warning Icon" src={WARNING_IMG} width={25} />
								)}
							</div>
						</ListGroup.Item>
					))}
				</ListGroup>
			</BusyIndicator>
		</Card>
	)
}
