import './BillingInvoices.scss'

import { fetchInvoices, invoices$ } from '@motiv-shared/reducers'
import type { InvoiceData } from '@motiv-shared/server'
import cls from 'classnames'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { useSelector } from 'react-redux'
import DOWNLOAD_ICON from '../../assets/icons/download.svg'
import { useAppDispatch } from '../../store'
import { IndicatorRegions } from '../../widgets/BusyIndicator'
import type { SafeColumnDescription } from '../../widgets/Table'
import { PaginationTable } from '../Teams/PaginationTable'

const RECORDS_PER_PAGE = 15

export const BillingInvoices = () => {
	const dispatch = useAppDispatch()
	const invoices = useSelector(invoices$)

	// We get invoice numbers as 'ID-0001` and we want to display `1`
	const formatInvoiceNumber = (invoiceNumber: string) => {
		const [, numStr] = /(\d+)$/.exec(invoiceNumber) || []

		const formattedNumber = numStr ? parseInt(numStr, 10).toString() : invoiceNumber

		return <div className="max-col-width">#{formattedNumber}</div>
	}

	const formatInvoiceDate = (invoiceTs: number) => {
		const formattedDate = moment(invoiceTs).format('MMM DD, YYYY')
		return <div className="max-col-width">{formattedDate}</div>
	}

	// Invoice status can be paid, uncollectible, open, void. They don't export those types
	const formatInvoiceStatus = (invoiceStatus: string) => {
		const formattedText =
			invoiceStatus === 'paid'
				? 'Paid'
				: invoiceStatus === 'uncollectible'
				? 'Failed'
				: invoiceStatus === 'open'
				? 'Current'
				: invoiceStatus

		return (
			<div
				className={cls('text-capitalize max-col-width', {
					'text-danger': invoiceStatus === 'uncollectible',
					'text-success': invoiceStatus === 'open',
				})}
			>
				{formattedText}
			</div>
		)
	}

	const formatInvoiceDownload = (invoiceUrl: string) => {
		return (
			<a href={invoiceUrl}>
				<Image src={DOWNLOAD_ICON} alt="Download Icon" />
			</a>
		)
	}

	const columns: SafeColumnDescription<InvoiceData>[] = [
		{
			dataField: 'invoiceId',
			hidden: true,
			text: '',
		},
		{
			dataField: 'invoiceNumber',
			formatter: formatInvoiceNumber,
			sort: true,
			text: 'Number',
		},
		{
			dataField: 'createdTs',
			formatter: formatInvoiceDate,
			sort: true,
			text: 'Date',
		},
		{
			dataField: 'status',
			formatter: formatInvoiceStatus,
			sort: true,
			text: 'Status',
		},
		{
			dataField: 'invoicePdfUrl',
			formatter: formatInvoiceDownload,
			text: '',
			classes: 'invoices__download-col',
		},
	]

	useEffect(() => {
		dispatch(fetchInvoices({ indicatorRegion: IndicatorRegions.BILLING }))
	}, [])

	return (
		<div>
			<h2 className="mb-4">Invoices</h2>

			<Card>
				<PaginationTable
					columns={columns}
					data={invoices}
					keyField="invoiceId"
					sizePerPage={RECORDS_PER_PAGE}
					totalSize={invoices.length > RECORDS_PER_PAGE ? invoices.length : 0}
				/>
			</Card>
		</div>
	)
}
