import { useFn } from '@motiv-shared/react'
import { activeSub$, hasUserPermission } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { user$ } from '../../reducers'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const ExpiredSubBanner = () => {
	const currentUser = useSelector(user$)!
	const canAccessBilling =
		currentUser && hasUserPermission(currentUser, UserPermissions.CAN_VIEW_BILLING_TAB)
	const activeSub = useSelector(activeSub$)

	const history = useHistory()

	const handleBannerBtnClick = useFn(() => {
		history.push('/billing')
	})

	const ExpiredSubMsg = useMemo(() => {
		return canAccessBilling
			? 'A Subscription is Required to use Motiv features. Upgrade now to manage your team.'
			: 'A Subscription is Required to use Motiv features. To access team data, an Account Owner or Admin must upgrade.'
	}, [canAccessBilling])

	if (activeSub) return null

	return <BannerWithBtn btnMsg="Upgrade" msg={ExpiredSubMsg} onClick={handleBannerBtnClick} />
}
