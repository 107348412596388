import { useFn } from '@motiv-shared/react'
import { activeSub$ } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import { useSelector } from 'react-redux'
import RightArrowIcon from '../../assets/icons/right-arrow.svg'
import { fetchUsers } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { DisableOverlay } from '../../widgets/DisableOverlay/DisableOverlay'
import { TeamsModals } from '../TeamsModal'
import { fetchTeam, fetchTeams } from './teams.asyncActions'
import { doTeamsExist$, settingsTeams$ } from './teams.selectors'
import { setSelectedTeamId, setTeamsModal } from './teams.slice'

type TeamItemProps = {
	readonly onClick: (team: Team) => void
	readonly team: Team
}

const TeamItem = ({ onClick, team }: TeamItemProps) => {
	const handleOpenTeamClick = useFn(() => onClick(team))

	return (
		<ListGroup.Item
			className="d-flex align-items-center justify-content-between"
			as="button"
			key={team.id}
			onClick={handleOpenTeamClick}
		>
			<span className="d-flex align-items-center flex-truncate pr-1">
				<Badge className="mr-3 badge-teams-counter" variant="dark">
					{team.memberCount}
				</Badge>

				<span className="f-1 text-truncate">{team.name}</span>
			</span>

			<Image alt="Right Arrow" src={RightArrowIcon} />
		</ListGroup.Item>
	)
}

export const Teams = () => {
	const dispatch = useAppDispatch()
	const doTeamsExist = useSelector(doTeamsExist$)
	const activeSub = useSelector(activeSub$)
	const teams = useSelector(settingsTeams$)

	const [teamsToBeDisplayed, setTeamsToBeDisplayed] = useState(teams)

	const handleCreateTeamClick = useFn(() => {
		dispatch(setTeamsModal(TeamsModals.CREATE_TEAM))
	})

	useEffect(() => {
		setTeamsToBeDisplayed(teams)
	}, [teams])

	const handleTeamSearch = useFn((e: ChangeEvent<HTMLInputElement>) => {
		const teamToBeSearched = e.target.value
		setTeamsToBeDisplayed(
			teams.filter((team) => team.name.toLowerCase().includes(teamToBeSearched.toLowerCase()))
		)
	})

	const handleUpdateTeamClick = useFn(async (team: Team) => {
		await dispatch(fetchTeam({ teamId: team.id, force: true }))
		dispatch(setSelectedTeamId(team.id))
		dispatch(setTeamsModal(TeamsModals.UPDATE_TEAM))
	})

	useEffect(() => {
		dispatch(fetchTeams())
		dispatch(fetchUsers())
	}, [])

	return (
		<DisableOverlay isDisabled={!activeSub}>
			<Card className="mb-5">
				<BusyIndicator region={[IndicatorRegions.FETCH_SUBS, IndicatorRegions.FETCH_TEAMS]}>
					<Card.Header className="d-flex align-items-center" as="h5">
						<span>Teams</span>

						{doTeamsExist && (
							<Button className="ml-auto" onClick={handleCreateTeamClick} size="sm">
								Create Team
							</Button>
						)}
					</Card.Header>

					{doTeamsExist ? (
						<>
							<Card.Body>
								<Form.Control
									className="search-control"
									onChange={handleTeamSearch}
									placeholder="Search Teams"
									type="text"
								/>
							</Card.Body>

							<BusyIndicator region={IndicatorRegions.FETCH_TEAMS}>
								<ListGroup as="div" className="mb-0 add-scrollbar" role="tablist" variant="flush">
									{teamsToBeDisplayed.length > 0
										? teamsToBeDisplayed?.map((each) => (
												<TeamItem key={each.id} onClick={handleUpdateTeamClick} team={each} />
										  ))
										: 'No Team Found'}
								</ListGroup>
							</BusyIndicator>
						</>
					) : (
						<Card.Body className="px-6 py-6 text-center">
							<h4 className="font-weight-bold f-1 mb-4">Create Your First Team</h4>

							<p className="mb-4">
								Create your first team and assign it to a team lead on your account
							</p>

							<Button
								className="ml-auto btn-block"
								onClick={handleCreateTeamClick}
								size="sm"
								variant="primary"
							>
								Create Team
							</Button>
						</Card.Body>
					)}
				</BusyIndicator>
			</Card>
		</DisableOverlay>
	)
}
