import { useBoolState } from '@motiv-shared/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { sentryError } from '../../infrastructure'
import { fetchSubscriptions } from '../../reducers'
import { canAccessProtected$ } from '../../state-helpers'
import { useAppDispatch } from '../../store'
import { fetchTeamMembers, fetchTeams } from '../Teams'
import { DowngradeBanner } from './DowngradeBanner'
import { ExpiredSubBanner } from './ExpiredSubBanner'

export const BillingBanners = () => {
	const dispatch = useAppDispatch()
	const canAccessProtected = useSelector(canAccessProtected$)

	const [hasResolved, setHasResolved] = useBoolState(false)

	const fetchDeps = async () => {
		try {
			await Promise.all([
				dispatch(fetchSubscriptions()),
				dispatch(fetchTeamMembers()),
				dispatch(fetchTeams()),
			])
		} catch (e) {
			sentryError(e, 'Failed to fetch DowngradeBanner deps')
		}

		setHasResolved()
	}

	// We first have to resolve certain calls before being able
	// to determine if the user has access to
	useEffect(() => {
		!hasResolved && canAccessProtected && fetchDeps()
	}, [canAccessProtected, hasResolved])

	if (!hasResolved) return null

	return (
		<>
			<DowngradeBanner />
			<ExpiredSubBanner />
		</>
	)
}
