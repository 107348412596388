import cls from 'classnames'
import type { ElementType, ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'
import type { ModalProps as BaseModalProps } from 'react-overlays/Modal'

// NOTE: This is a duplicate of `react-bootstrap/Modal` -> `ModalProps`
//  Because they are using the build-in `Omit` and not the 'better'
//  re-mapped variant, it's hard to remove the index prop. We either
//  have to manually `Pick` all the props we want from `BaseModalProps`
//  or in this case, duplicate the `ModalProps` definition using the
//  better `MOmit` re-mapped type.
//  I chose the latter, but ideally TypeScript will implement using the
//  newer re-mapped types. See: https://github.com/microsoft/TypeScript/issues/41383
type ModalProps = RemoveIndex<
	MOmit<
		BaseModalProps,
		| 'children'
		| 'role'
		| 'renderBackdrop'
		| 'renderDialog'
		| 'show'
		| 'transition'
		| 'backdropTransition'
	>
> & {
	// animation?: boolean
	// backdropClassName?: string
	// bsPrefix?: string
	// centered?: boolean
	// contentClassName?: string
	dialogAs?: ElementType
	// dialogClassName?: string
	scrollable?: boolean
	size?: 'sm' | 'md' | 'lg' | 'res'
}

export type MotivModalProps = ModalProps & {
	readonly children: ReactNode
	readonly onHide: () => void
	readonly showCloseBtn?: boolean
	readonly title?: string
}

export const MotivModal = ({
	className,
	children,
	showCloseBtn = true,
	size = 'res',
	title,
	...p
}: MotivModalProps) => (
	<Modal
		{...p}
		animation={false}
		centered={false}
		className={cls('motiv-modal rounded content-light', className)}
		dialogClassName={cls(`modal-${size}`)}
		show
	>
		{title && (
			<Modal.Header closeButton={showCloseBtn}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
		)}

		{children}
	</Modal>
)
