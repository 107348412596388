import './NavBar.scss'

import { hasUserPermission } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import cls from 'classnames'
import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'
import LOGO from '../../assets/images/logo.png'
import { SETTINGS_REFACTOR } from '../../features/Settings'
import { authUser$, isAuthenticated$, isLoggedInViaSSO$, user$ } from '../../reducers'
import type { NavScreen } from './navBar.constants'
import { NavScreens } from './navBar.constants'

export const NavBar = () => {
	const { pathname } = useLocation()
	const authUser = useSelector(authUser$)
	const currentUser = useSelector(user$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const isLoggedInViaSSO = useSelector(isLoggedInViaSSO$)
	const isHomePageUrl = pathname === '/'

	const activeCls = (matchPath: NavScreen, override?: boolean) =>
		cls((override || pathname.includes(matchPath)) && 'active')

	const userName = currentUser?.fullName || authUser?.name

	return (
		<Navbar collapseOnSelect expand="md" variant="dark">
			<LinkContainer to="/">
				<Navbar.Brand>
					<Image src={LOGO} />
				</Navbar.Brand>
			</LinkContainer>

			<Navbar.Toggle aria-controls="basic-navbar-nav" />

			<Navbar.Collapse className="justify-content-end">
				<Nav as="ul" className="mr-auto">
					<Nav.Item as="li" className={activeCls(NavScreens.TEAMS, isHomePageUrl)}>
						<LinkContainer to="/teams">
							<Nav.Link>Teams</Nav.Link>
						</LinkContainer>
					</Nav.Item>

					{currentUser && hasUserPermission(currentUser, UserPermissions.CAN_VIEW_BILLING_TAB) && (
						<Nav.Item as="li" className={activeCls(NavScreens.BILLING)}>
							<LinkContainer to="/billing">
								<Nav.Link>Billing</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}

					{!SETTINGS_REFACTOR && !isLoggedInViaSSO && (
						<Nav.Item as="li" className={activeCls(NavScreens.PROFILE)}>
							<LinkContainer to="/profile">
								<Nav.Link>Profile</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}

					{!SETTINGS_REFACTOR &&
						currentUser &&
						hasUserPermission(currentUser, UserPermissions.CAN_VIEW_ACCOUNT_TAB) && (
							<Nav.Item as="li" className={activeCls(NavScreens.ACCOUNT)}>
								<LinkContainer to="/account">
									<Nav.Link>Account</Nav.Link>
								</LinkContainer>
							</Nav.Item>
						)}

					{SETTINGS_REFACTOR && (
						<Nav.Item as="li" className={activeCls(NavScreens.SETTINGS)}>
							<LinkContainer to="/settings">
								<Nav.Link>Settings</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}
				</Nav>

				<Nav as="ul" className="navbar-right">
					{(authUser || currentUser) && (
						<Navbar.Text className="navbar-welcome text-truncate" as="li" title={userName}>
							Welcome, {userName}
						</Navbar.Text>
					)}

					{isAuthenticated && (
						<Nav.Item as="li" className="mr-0 d-flex align-items-center pointer">
							<LinkContainer to="/logout">
								<Nav.Link>Logout</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}
