import { notEmpty } from '@motiv-shared/util'
import cls from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import CompleteIcon from '../../assets/icons/complete.svg'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'

export const TodoCard = ({ doTeamsExist, integrations }) => {
	const isIntegrated = notEmpty(integrations)
	const integratedWith = integrations?.map((e) => e.integrationProvider.toLowerCase())

	const formatIntegratedWith = (integratedWith) =>
		integratedWith
			.slice(',')
			.map((each) => each.charAt(0).toUpperCase() + each.slice(1))
			.join(', ')

	return (
		<Card className="mb-5">
			<Card.Header className="d-flex align-items-center" as="h5">
				Your To-Do List
			</Card.Header>

			<BusyIndicator region={IndicatorRegions.INTEGRATIONS}>
				<ListGroup variant="flush">
					<ListGroup.Item className="d-flex">
						<Image
							alt="complete-icon"
							className={cls('ml-n2 mr-3', isEmpty(integrations) && 'opacity-25')}
							src={CompleteIcon}
							width={25}
							height={25}
						/>

						<div className="flex-fill mr-n2">
							<h6>{isIntegrated ? 'Account Integrated' : 'Integrate Your Account'}</h6>

							<p className="mb-0">
								{integratedWith.length > 0
									? `Your account has been integrated with ${formatIntegratedWith(integratedWith)}.`
									: 'We are waiting for an integration.'}
							</p>
						</div>
					</ListGroup.Item>

					<ListGroup.Item className="d-flex">
						<Image
							alt="complete-icon"
							className="ml-n2 mr-3 opacity-25"
							src={CompleteIcon}
							width={25}
							height={25}
						/>

						<div className="flex-fill mr-n2">
							<h6>{!doTeamsExist && 'Assign Team'}</h6>

							<p className="mb-0">
								{!doTeamsExist && 'Create a team and assign it to a team lead.'}
							</p>
						</div>
					</ListGroup.Item>
				</ListGroup>
			</BusyIndicator>
		</Card>
	)
}
