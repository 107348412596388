import { useFn } from '@motiv-shared/react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import {
	deleteIntegration,
	fetchIntegrations,
	fetchUsers,
	integrationToBeDeleted$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import { fetchTeams, setTeamsModal } from '../Teams'

const { INTEGRATIONS, DISCONNECT_INTEGRATION } = IndicatorRegions

export const DeleteIntegrationModal = () => {
	const dispatch = useAppDispatch()
	const integrationToBeDeleted = useSelector(integrationToBeDeleted$)

	const resetModal = useFn(() => {
		dispatch(setTeamsModal(null))
	})

	const handleDisconnectClick = useFn(async () => {
		if (!integrationToBeDeleted) return

		sentryBreadcrumb('Deleting Integration')

		try {
			await dispatch(deleteIntegration({ id: integrationToBeDeleted }))

			Promise.all([
				dispatch(fetchIntegrations({ indicatorRegion: INTEGRATIONS, force: true })),
				dispatch(fetchTeams({ force: true })),
				dispatch(fetchUsers({ force: true })),
			])

			resetModal()
		} catch (e) {
			sentryError(e, 'Error Deleting Integration')
		}
	})

	return (
		<MotivModal
			onHide={resetModal}
			size="sm"
			title="Are You Sure You Want to Disconnect Integration?"
		>
			<BusyIndicator region={DISCONNECT_INTEGRATION}>
				<Modal.Body>
					<p>This action will delete teams associated with this integration.</p>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={handleDisconnectClick} size="lg" variant="danger">
						Disconnect
					</Button>

					<Button variant="light-link" size="lg" onClick={resetModal}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
