/**
 * This exists simply to call logout action after auth0 redirects. Without
 * this, our local logout could trigger redirect to login before Auth0
 * finishes its own logout procedure, which will keep us logged in.
 */
import { resetAction } from '@motiv-shared/reducers'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../Auth'
import { isAuthenticated$ } from '../reducers'
import { useAppDispatch } from '../store'

export const LogoutPage = () => {
	const d = useAppDispatch()
	const { logout } = useAuth()
	const isAuthenticated = useSelector(isAuthenticated$)

	if (isAuthenticated) {
		logout()
		return null
	}

	d(resetAction)
	return <Redirect to="/login" />
}
