import './TeamMemberCard.scss'

import { useFn } from '@motiv-shared/react'
import type { IntegrationProvider } from '@motiv-shared/server'
import { integrationShortName } from '@motiv-shared/server'
import cls from 'classnames'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { useSelector } from 'react-redux'
import { useIntegrationFlow } from '../../IntegrationFlow'
import {
	getIntegrationDisplayData,
	integrationsCatalog$,
	unauthorizedIntegrations$,
} from '../../reducers'
import { AvailableIntegrations } from '../../types'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'

const { INTEGRATIONS } = IndicatorRegions

type ConnectBtnProps = {
	readonly index: number
	readonly provider: IntegrationProvider
}

const ConnectBtn = ({ index, provider }: ConnectBtnProps) => {
	const { connect } = useIntegrationFlow()

	const displayData = useMemo(() => getIntegrationDisplayData(provider), [provider])

	const connectIntegration = useFn(() => {
		connect(provider)
	})

	// NOTE: For now we have 2 DefaultIntegrations so added check on index.
	return (
		<>
			{index !== 0 && <span className="d-block mb-3 mb-md-0 mx-md-4 d-md-inline-block"> OR </span>}

			<Button
				className={cls(displayData.btnClass, 'connect-btn btn-lg')}
				onClick={connectIntegration}
			>
				<Image src={displayData.iconLogo} alt={`Integration Logo for ${displayData.displayName}`} />
				<span>{`Connect ${displayData.displayName}`}</span>
			</Button>
		</>
	)
}

export const TeamMemberCard = () => {
	const { disconnect, connect } = useIntegrationFlow()
	const integrationsCatalog = useSelector(integrationsCatalog$)
	const unauthorizedIntegrations = useSelector(unauthorizedIntegrations$)

	const unauthorizedIntegration = unauthorizedIntegrations[0]
	const unauthorizedName =
		unauthorizedIntegration && integrationShortName(unauthorizedIntegration.integrationProvider)

	return (
		<Card className="mb-5">
			<Card.Header as="h5">
				{unauthorizedIntegration ? 'Integrate Your Account' : 'Team Members'}
			</Card.Header>

			<Card.Body className="py-6 text-center">
				<BusyIndicator region={INTEGRATIONS}>
					<h4>
						{unauthorizedIntegration
							? `The ${unauthorizedName} integration is no longer valid.`
							: 'Integrate Your Account'}
					</h4>

					<p>
						{unauthorizedIntegration
							? `Restore your data or disconnect from ${unauthorizedName}.`
							: 'Sign in to your Google or Microsoft account to view your team’s data.'}
					</p>

					<div className="social-connect">
						{unauthorizedIntegration ? (
							<>
								<Button
									className="m-3"
									onClick={() => connect(unauthorizedIntegration.integrationProvider)}
									style={{ minWidth: '250px' }}
									variant="success"
								>
									{`Restore ${unauthorizedName} Data`}
								</Button>

								<Button
									className="m-3"
									onClick={() => disconnect(unauthorizedIntegration.id)}
									style={{ minWidth: '250px' }}
									variant="light"
								>
									{`Disconnect from ${unauthorizedName}`}
								</Button>
							</>
						) : (
							integrationsCatalog.map(
								({ integrationProvider: provider }, index) =>
									AvailableIntegrations.has(provider) && (
										<ConnectBtn index={index} key={provider} provider={provider} />
									)
							)
						)}
					</div>
				</BusyIndicator>
			</Card.Body>
		</Card>
	)
}
