import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { http } from './http'
import { createSentryReduxEnhancer, sanitizeAction, sanitizeState } from './infrastructure'
import { rootReducer } from './rootReducer'

export const store = configureStore({
	reducer: rootReducer,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ thunk: { extraArgument: { http } } }),

	enhancers: [
		createSentryReduxEnhancer({
			actionTransformer(action) {
				return sanitizeAction(action)
			},

			stateTransformer(state: any): any {
				return sanitizeState(state)
			},
		}),
	],
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
