import {
	isDowngradedSub$,
	isNextSubMonthly$,
	nextSeatLimit$,
	nextSKU$,
	nextSub$,
	nextSubCycle$,
} from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$ } from '../../reducers'
import { getNextSubPrice } from '../../types'
import { PlanLineItem } from './PlanLineItem'

export const UpcomingPlanDetails = () => {
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const isDowngradeSub = useSelector(isDowngradedSub$)
	const isNextSubMonthly = useSelector(isNextSubMonthly$)
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const nextSKU = useSelector(nextSKU$)
	const nextSub = useSelector(nextSub$)
	const subCycle = useSelector(nextSubCycle$)

	const Cycle = useMemo(
		() =>
			subCycle &&
			subCycle !== SubscriptionPeriod.NON_RECURRING && (
				<PlanLineItem>{`Cycle: ${isNextSubMonthly ? 'Monthly' : 'Annually'}`}</PlanLineItem>
			),
		[subCycle, isNextSubMonthly]
	)

	const Cost = useMemo(() => {
		if (!(nextSKU && nextSub)) return null

		const cost = getNextSubPrice(nextSKU, nextSub)
		const cycle = isNextSubMonthly ? 'Month' : 'Year'

		return <PlanLineItem>{`Cost: $${cost}/${cycle}`}</PlanLineItem>
	}, [nextSub, nextSKU])

	if (!(isDowngradeSub && nextSub)) return null

	return (
		<>
			<h4 className="mb-4">Upcoming: {nextSeatLimit} Seats</h4>

			<ul className="plan-line-items">
				<PlanLineItem>Starts: {activeSubExpiryStr}</PlanLineItem>

				{Cycle}

				{Cost}
			</ul>
		</>
	)
}
