import React from 'react'
import { PlanDetails } from './PlanDetails'
import { SubOptions } from './SubOptions'

export const BillingPlan = () => (
	<div className="billing-plan">
		<PlanDetails className="mb-5" />
		<SubOptions />
	</div>
)
