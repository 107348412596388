import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchRoles, fetchSubscriptions } from '../reducers'
import { canAccessProtected$ } from '../state-helpers'
import { useAppDispatch } from '../store'
import { TeamsModal } from './TeamsModal'
import { UserModal } from './UserModal'

/**
 * Simply performs authentication side effects
 */
export const AuthEffects = () => {
	const d = useAppDispatch()
	const canAccessProtected = useSelector(canAccessProtected$)

	useEffect(() => {
		// Authenticated side effects calls go here
		if (!canAccessProtected) return

		d(fetchRoles())
		d(fetchSubscriptions())
	}, [canAccessProtected])

	if (!canAccessProtected) return null

	return (
		<>
			<TeamsModal />
			<UserModal />
		</>
	)
}
