import type {
	ActionCreatorWithPreparedPayload,
	ActionReducerMapBuilder,
	CaseReducer,
} from '@reduxjs/toolkit'
import type { Action } from 'redux'

import type { HttpExtra } from '../http'

type Cacheable = ActionCreatorWithPreparedPayload<
	any,
	any,
	any,
	any,
	{ arg: HttpExtra | undefined } // TODO: remove undefined when using HttpExtra from /shared/reducers
>

type TypedActionCreator<Type extends string> = {
	type: Type
	(...args: any[]): Action<Type>
}

type AddCaseArgs<B> = B extends { addCase: (...args: infer A) => any } ? A : never
type AddMatcherArgs<B> = B extends { addMatcher: (...args: infer A) => any } ? A : never

type BuilderWithCachedCase<S> = SafeOmit<ActionReducerMapBuilder<S>, 'addCase' | 'addMatcher'> & {
	addCachedCase<ActionCreator extends Cacheable>(
		actionCreator: ActionCreator,
		reducer: CaseReducer<S, ReturnType<ActionCreator>>
	): BuilderWithCachedCase<S>

	addResetCase(type: string, state: S): BuilderWithCachedCase<S>

	addCase<ActionCreator extends TypedActionCreator<string>>(
		actionCreator: ActionCreator,
		reducer: CaseReducer<S, ReturnType<ActionCreator>>
	): BuilderWithCachedCase<S>

	addCase(...args: AddCaseArgs<ActionReducerMapBuilder<S>>): BuilderWithCachedCase<S>

	addMatcher(
		...args: AddMatcherArgs<ActionReducerMapBuilder<S>>
	): SafeOmit<BuilderWithCachedCase<S>, 'addCachedCase' | 'addResetCase' | 'addCase'>

	resetCache()
}

/**
 * Adds a `addCachedCase` method to builder.  `addCacheCase` will check to see if this type has
 * already been run and, if so, will not call the reducer (unless `force=true`).
 * @param origBuilder
 */
export const withCachedCase = <S>(
	origBuilder: ActionReducerMapBuilder<S>
): BuilderWithCachedCase<S> => {
	const initializedTypes = new Set<string>()

	const newBuilder: BuilderWithCachedCase<S> = {
		addCachedCase: (type, reducer) => {
			origBuilder.addCase(type, (s, a) => {
				const isInitialized = initializedTypes.has(type.name)
				const isForce = a.meta.arg?.force
				if (isForce || !isInitialized) {
					reducer(s, a)
					initializedTypes.add(type.name)
				}
			})
			return newBuilder
		},

		addResetCase: (action, state: S) => {
			origBuilder.addCase(action, () => {
				initializedTypes.clear()
				return state
			})
			return newBuilder
		},

		addCase: (type, reducer) => {
			origBuilder.addCase(type, reducer)
			return newBuilder
		},

		addMatcher: (matcher, reducer) => {
			origBuilder.addMatcher(matcher, reducer)
			return {
				addMatcher: newBuilder.addMatcher,
				addDefaultCase: newBuilder.addDefaultCase,
				resetCache: newBuilder.resetCache(),
			}
		},

		addDefaultCase: origBuilder.addDefaultCase,

		resetCache: () => {
			initializedTypes.clear()
		},
	}

	return newBuilder
}
