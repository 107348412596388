/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import './DowngradeModal.scss'

import { useBoolState, useFn, useMounted } from '@motiv-shared/react'
import { nextSeatLimit$ } from '@motiv-shared/reducers'
import cls from 'classnames'
import { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { TeamMemberWithTeamAssignments } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { PillLabel } from '../../widgets/PillLabel'
import { UserThumbnail } from '../../widgets/UserThumbnail'
import type { UpdateTeamArgs } from '../Teams'
import {
	assignedTeamMembers$,
	fetchTeamMembers,
	fetchTeams,
	settingsTeams$,
	updateTeam,
} from '../Teams'

export const DowngradeModal = () => {
	const dispatch = useAppDispatch()
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const assignedTeamMembers = useSelector(assignedTeamMembers$)
	const settingsTeams = useSelector(settingsTeams$)
	const isMounted = useMounted()

	const [selectedTeamMembers, setSelectedTeamMembers] = useState<string[]>([])
	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const hasSelectedLimit = selectedTeamMembers.length === nextSeatLimit
	const disabled = isSaving || selectedTeamMembers.length === 0

	const handleClose = useFn(() => {
		dispatch(setActivePurchaseModal(null))
	})

	const handleUnmanage = useFn(async () => {
		sentryBreadcrumb('Un-assigning Users Via Downgrade')

		startSaving()
		try {
			// Get Ids Of Team Members That We Don't Want To Keep
			const membersToBeRemoved = assignedTeamMembers
				.filter((tm) => !selectedTeamMembers.includes(tm.id))
				.map((m) => m.id)

			const teamPatches: UpdateTeamArgs[] = []

			for (const team of settingsTeams) {
				// If the team has a member we want to remove, then we create a patch for that team
				if (team.assignedTeamMembers.some((id) => membersToBeRemoved.includes(id))) {
					// patch team
					teamPatches.push({
						teamId: team.id,
						patch: {
							setAssignedTeamMembers: team.assignedTeamMembers.filter(
								(id) => !membersToBeRemoved.includes(id)
							),
						},
					})
				}
			}

			await Promise.all(teamPatches.map((tp) => dispatch(updateTeam(tp))))

			Promise.all([
				dispatch(fetchTeamMembers({ force: true })),
				dispatch(fetchTeams({ force: true })),
			])

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to un-assign users')
			isMounted() && stopSaving()
		}
	})

	const handleTeamMemberChange = useFn((id: string) => {
		if (selectedTeamMembers.includes(id)) {
			setSelectedTeamMembers(selectedTeamMembers.filter((sid) => sid !== id))
			return
		}

		setSelectedTeamMembers([...selectedTeamMembers, id])
	})

	const SelectedTeamMembers = useMemo(
		() =>
			selectedTeamMembers.map((id) => {
				const teamMember = assignedTeamMembers.find((tm) => tm.id === id)!

				return (
					<PillLabel key={id} id={id} onDismiss={handleTeamMemberChange}>
						{teamMember.fullName}
					</PillLabel>
				)
			}),
		[selectedTeamMembers, assignedTeamMembers]
	)

	const renderTeamMember = useFn((tm: TeamMemberWithTeamAssignments) => {
		const teamNames = tm.teamAssignments.map((ta) => ta.name).join(', ')
		const isSelected = selectedTeamMembers.includes(tm.id)
		const isDisabled = hasSelectedLimit && !isSelected

		return (
			<div
				key={tm.id}
				className={cls(
					'downgrade-modal__member-list-row mb-3',
					isDisabled && 'downgrade-modal__member-list-row--disabled'
				)}
			>
				<Form.Check
					type="checkbox"
					label=""
					checked={isSelected}
					disabled={isDisabled}
					onChange={() => handleTeamMemberChange(tm.id)}
				/>

				<div className="d-flex">
					<UserThumbnail className={cls('mr-3', isDisabled && 'disabled')} user={tm} />

					<p className="font-weight-bold text-truncate" title={tm.fullName}>
						{tm.fullName}
					</p>
				</div>

				<p className="text-truncate mb-0" title={teamNames}>
					{!teamNames ? 'No Assigned Teams' : teamNames}
				</p>
			</div>
		)
	})

	return (
		<MotivModal
			onHide={handleClose}
			title="Confirm Team Members"
			className="downgrade-modal"
			size="lg"
		>
			<Modal.Body>
				<p className="font-weight-bold mb-4">
					Your subscription has downgraded to the {nextSeatLimit} Team Member plan. Please select
					the {nextSeatLimit} team members you would like to keep:
				</p>

				<div className="my-3 mx-4">{assignedTeamMembers.map((tm) => renderTeamMember(tm))}</div>

				<p className="font-weight-bold">
					{`${selectedTeamMembers.length} Team Member${
						selectedTeamMembers.length === 1 ? '' : 's'
					} selected:`}
				</p>

				<div className="d-flex my-3 flex-wrap"> {SelectedTeamMembers} </div>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="success" size="lg" onClick={handleUnmanage} disabled={disabled}>
					Keep
				</Button>

				<Button variant="light" size="lg" onClick={handleClose}>
					I&apos;ll take care of this later
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
