import type { IntegrationIdentityId, TeamMember } from '@motiv-shared/server'

export const filterAvailableTeamMembers = (
	tm: TeamMember[],
	assignedTeamMembers: IntegrationIdentityId[],
	maxSeatLimit: number
): TeamMember[] => {
	const assigned = tm.filter((t) => assignedTeamMembers.includes(t.id))

	// If we already have max seats assigned we only select the assigned seats
	// and we limit it to the max amount per team
	if (assigned.length >= maxSeatLimit) return assigned.slice(0, maxSeatLimit)

	// If we select all assigned seats and still have seats remaining, then we select up
	// to the amount we're allowed
	const nonAssigned = tm.filter((t) => !assignedTeamMembers.includes(t.id))
	return [...assigned, ...nonAssigned.slice(0, maxSeatLimit - assigned.length)]
}
