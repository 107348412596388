import { useFn, useToggleState } from '@motiv-shared/react'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { fetchUsers, updateUser, user$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import { setSelectedAccountModal } from '../Account'

export const EmailSubscriptionAccountModal = () => {
	const dispatch = useAppDispatch()
	const user = useSelector(user$)

	const [isMarketingOptIn, toggleMarketingOptIn] = useToggleState(
		user?.settings.marketingOptIn || false
	)

	const handleClose = useFn(() => {
		dispatch(setSelectedAccountModal(null))
	})

	const handleEmailSubscriptionSubmit = useFn(async () => {
		sentryBreadcrumb('Email Subscription Account')

		try {
			await dispatch(
				updateUser({
					userId: user!.id,
					patch: { addSettings: { marketingOptIn: isMarketingOptIn } },
				})
			)

			// NOTE: We make this call here to have fetchAllUsers update it's cached
			//  results because updateUser updates the store but if we make a
			//  fetchAllUsers call without {force: true} then the old cached results
			//  will overwrite our changes
			await dispatch(fetchUsers({ force: true }))

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed update to email subscription')
		}
	})

	return (
		<MotivModal onHide={handleClose} size="sm" title="Email Subscription">
			<BusyIndicator region={IndicatorRegions.UPDATE_USER}>
				<Modal.Body>
					<Form.Group className="px-5" controlId="marketingOptIn">
						<Form.Check
							checked={isMarketingOptIn}
							label="Keep me updated on new features and promotional offers."
							onChange={toggleMarketingOptIn}
						/>
					</Form.Group>
				</Modal.Body>

				<Modal.Footer>
					<Button size="lg" variant="success" onClick={handleEmailSubscriptionSubmit}>
						Save
					</Button>

					<Button variant="light-link" size="lg" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
