import { setUser } from '@sentry/react'
import { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { useSelector } from 'react-redux'
import { AccountModal } from './features/AccountModal'
import { AuthEffects } from './features/AuthEffects'
import { Banners } from './features/Banners/Banners'
import { PurchaseModals } from './features/PurchaseModals'
import { ToastContainer } from './infrastructure'
import { accountId$ } from './reducers'
import { WebAppRoutes } from './WebAppRoutes'
import { Footer } from './widgets/Footer'
import { NavBar } from './widgets/NavBar'

export const App = () => {
	const accountId = useSelector(accountId$)

	// Set Sentry user to account id. We can make this more granular
	//  to the actual user if desired later.
	useEffect(() => {
		setUser(accountId ? { id: accountId } : null)
	}, [accountId])

	return (
		<Container className="wrapper">
			<NavBar />
			<Banners />

			<main className="py-5">
				<WebAppRoutes />
			</main>

			<Footer />

			<AccountModal />
			<AuthEffects />
			<PurchaseModals />
			<ToastContainer />
		</Container>
	)
}
