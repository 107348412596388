/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import { useFn } from '@motiv-shared/react'
import { purchaseInfo$, updatePayment } from '@motiv-shared/reducers'
import type { Token } from '@stripe/stripe-js'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import { addErrorToast, addSuccessToast, setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'
import { StripeCardForm, StripeFormProvider } from '../../widgets/Stripe'

export const UpdatePaymentModal = () => {
	const dispatch = useAppDispatch()
	const purchaseInfo = useSelector(purchaseInfo$)

	const handleClose = useFn(() => dispatch(setActivePurchaseModal(null)))

	const handleSubmit = useFn(async (token?: Token) => {
		if (!token) return

		sentryBreadcrumb('Updating Payment')

		try {
			await dispatch(updatePayment({ token: token.id }))

			dispatch(addSuccessToast('Your payment has been updated.'))

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to update payment')
			dispatch(addErrorToast('Unknown error occurred. Please try again'))
		}
	})

	return (
		<StripeFormProvider onSubmit={handleSubmit}>
			{(sp) => (
				<MotivModal onHide={handleClose} size="lg" title="Update Payment">
					<Modal.Body>
						<h5>Current Card</h5>

						<p>**** **** **** {purchaseInfo?.last4}</p>

						<hr />

						<h5>New Card</h5>

						<StripeCardForm />
					</Modal.Body>

					<Modal.Footer>
						<Button
							disabled={!sp.isValid || sp.isSaving}
							onClick={sp.submitForm}
							size="lg"
							variant="success"
						>
							Save
						</Button>

						<Button disabled={sp.isSaving} onClick={handleClose} size="lg" variant="light">
							Cancel
						</Button>
					</Modal.Footer>
				</MotivModal>
			)}
		</StripeFormProvider>
	)
}
