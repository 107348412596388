import { useConstant, useToggleState } from '@motiv-shared/react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { v4 } from 'uuid'
import type { SafeColumnDescription } from '../widgets/Table'
import { Table } from '../widgets/Table'

type DemoTableData = {
	readonly age: number
	readonly email: string
	readonly id: string
	readonly name: string
}

type DemoTableProps = {
	readonly bordered?: boolean
	readonly className?: string
	readonly isFixedHeader?: boolean
}

export const DemoTable = (p: DemoTableProps) => {
	const demoData = useConstant((): DemoTableData[] => [
		{
			name: 'Ron Swanson',
			email: 'ron@parksnrec.com',
			id: v4(),
			age: 43,
		},
		{
			age: 120,
			email: 'a@a.com',
			id: v4(),
			name: 'James Bond',
		},
		{
			age: 50,
			email: 'fred@gmail.com',
			id: v4(),
			name: 'Fred Smith',
		},
		{
			age: 13,
			email: 'jim@gmail.com',
			id: v4(),
			name: 'Jim Jimmerson',
		},
		{
			age: 63,
			email: 'janet@livingonmars.space',
			id: v4(),
			name: 'Janet Kiff',
		},
		{
			age: 48,
			email: 'isofat@blackhole.com',
			id: v4(),
			name: 'Your Mom',
		},
		{
			age: 97,
			email: 'f@fskljl.com',
			id: v4(),
			name: 'Willsky McFarthander',
		},
		{
			age: 1,
			email: 'bart@thetoddler.com',
			id: v4(),
			name: 'Bartholomew Chinstein',
		},
	])

	const NameFormatter = (name: string, data: DemoTableData) => {
		return <div>{name.startsWith('J') ? name.split('').reverse().join('') : name}</div>
	}

	const AgeFormatter = (age: number, data: DemoTableData) => {
		return <div>{age} years old</div>
	}

	const columns: SafeColumnDescription<DemoTableData>[] = [
		{
			dataField: 'name',
			formatter: NameFormatter,
			sort: true,
			text: 'Name',
		},

		{
			dataField: 'email',
			text: 'Email',
		},

		{
			dataField: 'age',
			formatter: AgeFormatter,
			sort: true,
			text: 'Age',
		},
	]

	return <Table classes={p.className} columns={columns} data={demoData} keyField="id" {...p} />
}

export const DemoTablePage = () => {
	const [isModalVisible, toggleModalVisibility] = useToggleState(false)
	const [isBordered, toggleBordered] = useToggleState(false)
	const [isFixedHeader, toggleFixedHeader] = useToggleState(false)

	const demoProps = { bordered: isBordered, isFixedHeader }

	return (
		<>
			<Card className="mb-5">
				<Card.Header>Controls</Card.Header>

				<Card.Body as={Row}>
					<Col sm="6">
						<Form.Check
							checked={isBordered}
							id="borderedChk"
							label="Bordered"
							onChange={toggleBordered}
						/>
					</Col>

					<Col sm="6">
						<Form.Check
							checked={isFixedHeader}
							id="fixedHeaderChk"
							label="Fixed Header"
							onChange={toggleFixedHeader}
						/>
					</Col>
				</Card.Body>

				<Card.Footer>
					<Button onClick={toggleModalVisibility}>Show Modal</Button>
				</Card.Footer>
			</Card>

			<Card className="mb-5">
				<Card.Header>Table Card with Header + Footer</Card.Header>

				<DemoTable className="no-radius" {...demoProps} />

				<Card.Footer className="font-weight-bold">Here&apos;s a footer</Card.Footer>
			</Card>

			<h5>Table Card without Header</h5>
			<Card className="mb-5">
				<DemoTable {...demoProps} />
			</Card>

			<Modal
				className="content-light"
				onHide={toggleModalVisibility}
				show={isModalVisible}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Table Modal</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<DemoTable {...demoProps} />
				</Modal.Body>
			</Modal>
		</>
	)
}
