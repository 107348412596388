import type { Account } from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { HttpExtra } from '../../http'
import { user$ } from '../../reducers'
import { IndicatorRegions } from '../../widgets/BusyIndicator'
import { account$ } from './account.selectors'

type DeleteAccountArgs = HttpExtra & {
	readonly accountId: string
}

export const deleteAccount = createAsyncThunk(
	'account/Id',
	({ accountId, ...extra }: DeleteAccountArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`accounts/${accountId}`, {
				errorMessage: 'Unable to delete account. Please try again later.',
				indicatorRegion: IndicatorRegions.DELETE_ACCOUNT,
				...extra,
			})
		)
)

export const fetchAccount = createAsyncThunk(
	'account/fetchAccount',
	async (extra: HttpExtra = {}, { dispatch, getState, extra: { http } }) => {
		const state = getState()
		const account = account$(state)

		if (account && !extra.force) return account

		const user = user$(state)

		if (!user) {
			throw new Error(`Can't retrieve account without user accountId`)
		}

		return dispatch(
			http.get<Account>(`accounts/${user.accountId}`, {
				errorMessage: 'Unable to load account. Please try again later.',
				indicatorRegion: IndicatorRegions.ACCOUNT,
				...extra,
			})
		)
	}
)

type UpdateAccountArgs = HttpExtra & {
	readonly accountId: string
	readonly updatedAccount: Partial<any>
}

export const updateAccount = createAsyncThunk(
	'account/updateAccount',
	({ accountId, updatedAccount, ...extra }: UpdateAccountArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.patch(`accounts/${accountId}`, {
				data: updatedAccount,
				errorMessage: 'Unable to update account. Please try again later.',
				indicatorRegion: IndicatorRegions.ACCOUNT,
				...extra,
			})
		)
)
