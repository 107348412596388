import type { Integration, IntegrationProvider } from '@motiv-shared/server'
import { IntegrationProviders } from '@motiv-shared/server'

export type IntegrationDisplayData = {
	readonly btnClass: string
	readonly displayName: string
	readonly iconLogo: string
	readonly iconLogoBwSm: string
	readonly iconLogoSm: string
	readonly logo: string
	readonly logoBw: string
	readonly shortName: string
}

export type IntegrationDecorated = IntegrationDisplayData &
	MPick<Integration, 'integrationProvider'> & {
		readonly id?: string
		readonly isAvailable: boolean
		readonly isIntegrated: boolean
		readonly isUnauthorized: boolean
	}

export const AvailableIntegrations = new Set<IntegrationProvider>([
	IntegrationProviders.GOOGLE,
	IntegrationProviders.MICROSOFT,
])
