import { resetAction } from '@motiv-shared/reducers'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import { v4 } from 'uuid'
import type { MotivToast } from '../../types'
import { DEFAULT_ERROR_TOAST, DEFAULT_SUCCESS_TOAST } from '../../types'

export type ToastState = {
	readonly toasts: MotivToast[]
}

export type WithToastState = {
	readonly toasts: ToastState
}

const initialState: ToastState = {
	toasts: [],
}

type CloseToastPayload = {
	readonly id: string
}

export type ToastData = {
	readonly msg: string
	readonly title?: string
}

export type ToastPayload = string | ToastData

const normalizePayload = (p: ToastPayload): ToastData => (typeof p == 'string' ? { msg: p } : p)

export const toastSlice = createSlice({
	name: 'toasts',
	initialState,
	reducers: {
		addErrorToast(s, { payload }: PayloadAction<ToastPayload>) {
			addToast(s, payload, true)
		},

		addSuccessToast(s, { payload }: PayloadAction<ToastPayload>) {
			addToast(s, payload, false)
		},

		closeToast(s, { payload: { id } }: PayloadAction<CloseToastPayload>) {
			return { ...s, toasts: reject(s.toasts, { id }) }
		},
	},

	extraReducers: (builder) => {
		builder.addCase(resetAction.type, () => initialState)
	},
})

const addToast = (s: Draft<ToastState>, toast: ToastPayload, isError: boolean) => {
	s.toasts.push({
		id: v4(),
		...(isError ? DEFAULT_ERROR_TOAST : DEFAULT_SUCCESS_TOAST),
		...normalizePayload(toast),
	})
}

export const { addErrorToast, addSuccessToast, closeToast } = toastSlice.actions
