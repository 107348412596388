import cls from 'classnames'
import truncate from 'lodash/truncate'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

type BadgeWithTooltipProps = {
	readonly className?: string
	readonly label?: string
	readonly maxLength: number
}

const TRIGGER_OPTS = { show: 250, hide: 400 }

export const BadgeWithTooltip = ({ className, label = '', maxLength }: BadgeWithTooltipProps) => {
	const BadgeContent = (
		<Badge className={cls('m-1', className)} variant="dark">
			{truncate(label, { length: maxLength, omission: '...' })}
		</Badge>
	)

	if (label.length <= maxLength) return BadgeContent

	const Overlay = <Tooltip id="badge-with-tooltip">{label}</Tooltip>

	return (
		<OverlayTrigger delay={TRIGGER_OPTS} overlay={Overlay} placement="bottom">
			{BadgeContent}
		</OverlayTrigger>
	)
}

type BadgesWithTooltipsProps = {
	readonly className?: string
	readonly labels?: string[]
	readonly maxLength: number
	readonly maxVisible: number
}

export const BadgesWithTooltips = ({
	className,
	labels = [],
	maxLength,
	maxVisible,
}: BadgesWithTooltipsProps) => {
	const visibleLabels = labels.slice(0, maxVisible)
	const overflowLabels = labels.slice(maxVisible)

	const LabelsContent = visibleLabels.map((label, idx) => (
		<BadgeWithTooltip className={className} key={idx} label={label} maxLength={maxLength} />
	))

	return (
		<>
			{LabelsContent}
			{labels.length > maxVisible && (
				<OverlayTrigger
					delay={TRIGGER_OPTS}
					overlay={<Tooltip id="badge-with-tooltip">{overflowLabels.join(', ')}</Tooltip>}
					placement="bottom"
				>
					<Badge className={cls('m-1', className)} variant="dark">
						+ {labels.length - maxVisible}
					</Badge>
				</OverlayTrigger>
			)}
		</>
	)
}
