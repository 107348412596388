import { toQueryStr } from '@motiv-shared/react'
import type {
	MotivUser,
	MotivUserPatch,
	MotivUserSeed,
	PasswordChangeResponse,
	PasswordPatch,
	UserPatchQueryArgs,
	UserRoleData,
} from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { HttpExtra } from '../../http'
import type { IndicatorRegion } from '../../widgets/BusyIndicator'
import { IndicatorRegions } from '../../widgets/BusyIndicator'

type CreateUserArgs = HttpExtra & {
	readonly user: MotivUserSeed
}

export const createUser = createAsyncThunk(
	'users/createUser',
	({ user, ...extra }: CreateUserArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.post<MotivUser>('users', {
				data: user,
				errorMessage: 'Unable to add user. Please try again later.',
				indicatorRegion: IndicatorRegions.ADD_NEW_USER,
				...extra,
			})
		)
)

type DeleteUserArgs = HttpExtra & {
	readonly userId: string
}

export const deleteUser = createAsyncThunk(
	'Delete users/userId',
	({ userId, ...extra }: DeleteUserArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`users/${userId}`, {
				errorMessage: 'Unable to delete user. Please try again later.',
				indicatorRegion: IndicatorRegions.DELETE_USER,
				...extra,
			})
		)
)

export const fetchUsers = createAsyncThunk(
	'users/getAll',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivUser[]>('users', {
				errorMessage: 'Unable to load users. Please try again later.',
				indicatorRegion: IndicatorRegions.USERS,
				...extra,
			})
		)
)

export const fetchRoles = createAsyncThunk(
	'roles/getAll',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<UserRoleData[]>('roles', {
				errorMessage: 'Unable to load roles. Please try again later.',
				...extra,
			})
		)
)

type UpdatePasswordArgs = HttpExtra & {
	readonly userId: string
	readonly passwordPatch: PasswordPatch
	readonly region: IndicatorRegion
}

export const updateUserPassword = createAsyncThunk(
	'users/updateUserPassword',
	(
		{ userId, passwordPatch, region, ...extras }: UpdatePasswordArgs,
		{ dispatch, extra: { http } }
	) =>
		dispatch(
			http.patch<PasswordChangeResponse>(`users/${userId}`, {
				data: passwordPatch,
				errorMessage: 'Unable to change password. Please try again later.',
				indicatorRegion: region,
				...extras,
			})
		)
)

type UpdateUserArgs = HttpExtra & {
	readonly userId: string
	readonly patch: MotivUserPatch
	readonly region?: IndicatorRegion
	readonly query?: UserPatchQueryArgs
}

export const updateUser = createAsyncThunk(
	'users/updateUser',
	(
		{ query, userId, patch, region = IndicatorRegions.UPDATE_USER, ...extra }: UpdateUserArgs,
		{ dispatch, extra: { http } }
	) =>
		dispatch(
			http.patch<MotivUser>(`users/${userId}${toQueryStr(query)}`, {
				data: patch,
				errorMessage: 'Unable to update user. Please try again later.',
				indicatorRegion: region,
				...extra,
			})
		)
)
