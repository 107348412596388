/**
 * @author Brian Frichette (brian@eturi.com)
 */

export enum PurchaseModalType {
	CANCEL = 1,
	DOWNGRADE,
	PURCHASE,
	UPDATE_PAYMENT,
	UPGRADE,
}
