import { resetAction } from '@motiv-shared/reducers'
import type { Account } from '@motiv-shared/server'
import { isIdentifySuccessRes, sanitizeAccount } from '@motiv-shared/server'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { postAuthIdentify } from '../../reducers/auth/auth.asyncActions'
import type { AccountModalType } from '../AccountModal'
import { deleteAccount, fetchAccount } from './account.asyncActions'

export type AccountState = {
	readonly selectedAccountModal: Maybe<AccountModalType>
	readonly account: Maybe<Account>
	readonly isAccountDeleted: boolean
}

export type WithAccountState = {
	readonly account: AccountState
}

const initialState: AccountState = {
	account: null,
	isAccountDeleted: false,
	selectedAccountModal: null,
}

export const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setSelectedAccountModal: (state, action: PayloadAction<Maybe<AccountModalType>>) => {
			state.selectedAccountModal = action.payload
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)

			.addCase(deleteAccount.fulfilled, (s, a) => {
				s.isAccountDeleted = Boolean(a.payload)
			})

			.addCase(fetchAccount.fulfilled, (s, a) => {
				s.account = a.payload
			})

			.addCase(postAuthIdentify.fulfilled, (s, { payload }) => {
				if (isIdentifySuccessRes(payload)) {
					s.account = payload.account
				}
			})
	},
})

export const { setSelectedAccountModal } = accountSlice.actions

// Removes confidential information from state when sent to Sentry
export const sanitizeAccountState = (s: AccountState) => ({
	...s,
	account: sanitizeAccount(s.account),
})
