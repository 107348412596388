import './Toast.scss'

import { usePrevious } from '@motiv-shared/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { animated, config, useTransition } from 'react-spring'
import { toasts$ } from '../../reducers'
import { Toast } from './Toast'

export const ToastContainer = () => {
	const toasts = useSelector(toasts$)
	const previousSize = usePrevious(toasts.length)

	const actionTransitions = useTransition(toasts, (t) => t.id, {
		from: {
			opacity: 0,
			transform: 'translate3d(0%, -150%, 0)',
		},
		enter: {
			opacity: 1,
			transform: 'translate3d(0%, 0%, 0)',
		},
		leave: {
			opacity: 0,
			transform: 'translate3d(-150%, 0%, 0)',
		},
		config: config.gentle,
		trail: 100,
	})

	useEffect(() => {
		// When we have new toast, scroll to top
		if (!previousSize || previousSize < toasts.length) {
			window.scrollTo(0, 0)
		}
	}, [previousSize, toasts.length])

	return (
		<section className="toasts">
			{actionTransitions.map(({ item, key, props }) => (
				<animated.div className="toast-container" key={key} style={props}>
					<Toast {...item} />
				</animated.div>
			))}
		</section>
	)
}
