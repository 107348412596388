import { useConstant } from '@motiv-shared/react'
import cls from 'classnames'
import type {
	BootstrapTableProps,
	ColumnDescription,
	ColumnFormatter,
	SortOrder,
} from 'react-bootstrap-table-next'
import BootstrapTable from 'react-bootstrap-table-next'
import Figure from 'react-bootstrap/Figure'

export type MotivColumnDescription<
	T extends object,
	E = any,
	K extends keyof T = keyof T
> = SafeOmit<ColumnDescription<T, E>, 'dataField' | 'formatter'> & {
	readonly dataField: K
	readonly formatter?: ColumnFormatter<T, E, T[K]>
	readonly events?: { onClick: (e: MouseEvent) => void }
}

export type SafeColumnDescription<
	T extends object,
	E = any,
	F extends keyof T = keyof T
> = F extends keyof T ? MotivColumnDescription<T, E, F> : never

export type TableProps<T extends object, K = number> = SafeOmit<
	BootstrapTableProps<T, K>,
	'data' | 'columns' | 'keyField'
> & {
	readonly columns: SafeColumnDescription<T>[]
	readonly data: T[]
	readonly isFixedHeader?: boolean
	readonly isTableLight?: boolean
	readonly keyField: keyof T & string
}

const NO_SORT = 'no-sort'
type CustomSortOrder = SortOrder | typeof NO_SORT

const SortCaret = (order: CustomSortOrder = NO_SORT) => (
	<Figure
		className="sort-caret"
		title={
			order === NO_SORT ? 'No sorting' : order === 'asc' ? 'Ascending order' : 'Descending order'
		}
	>
		<span className={`sort-caret__icon sort-caret__icon--${order === 'desc' ? 'down' : 'up'}`} />
		{order === NO_SORT && <span className="sort-caret__icon sort-caret__icon--down" />}
	</Figure>
)

export const Table = <T extends object, K = number>({
	bordered = false,
	classes,
	isFixedHeader = false,
	wrapperClasses,
	...tableProps
}: TableProps<T, K>) => {
	// NOTE: Types here expect `order` field defined but this is not required
	//  and docs mention this. The point here is just to define the sorting
	//  caret defaults so we don't have to specify it at the column level
	//  every single time.
	// TODO: Type definition override
	const Sort = useConstant((): any => ({ sortCaret: SortCaret }))

	return (
		<BootstrapTable
			bordered={bordered}
			bootstrap4={true}
			classes={cls(isFixedHeader && 'fix-header', classes)}
			noDataIndication="No Data Found"
			sort={Sort}
			wrapperClasses={cls(
				'table-responsive text-nowrap',
				isFixedHeader && 'mh-350',
				wrapperClasses
			)}
			{...tableProps}
		/>
	)
}
