import { UserPermissions } from '@motiv-shared/server'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AccountPage } from './features/Account'
import { BillingPage } from './features/Billing'
import { InvitePage } from './features/InvitePage'
import { ProfilePage } from './features/Profile'
import { SETTINGS_REFACTOR, SettingsPage } from './features/Settings'
import { TeamsPage } from './features/Teams'
import { ProtectedRoute } from './infrastructure/ProtectedRoute'
import { LoginPage } from './pages/LoginPage'
import { LogoutPage } from './pages/LogoutPage'
import { isLoggedInViaSSO$ } from './reducers'
import { UIDemoPage } from './UIDemo'

// NOTE: React router doesn't like fragments in Switch, so please avoid
export const WebAppRoutes = () => {
	const isLoggedInViaSSO = useSelector(isLoggedInViaSSO$)

	return (
		<Switch>
			{!SETTINGS_REFACTOR && (
				<ProtectedRoute
					path="/account"
					component={AccountPage}
					permissions={[UserPermissions.CAN_VIEW_ACCOUNT_TAB]}
				/>
			)}

			<Route path="/invite" component={InvitePage} />

			<Route path="/login" component={LoginPage} />

			<Route path="/logout" component={LogoutPage} />

			{!SETTINGS_REFACTOR && !isLoggedInViaSSO && (
				<ProtectedRoute path="/profile" component={ProfilePage} />
			)}

			{SETTINGS_REFACTOR && <ProtectedRoute path="/settings" component={SettingsPage} />}

			<ProtectedRoute path="/teams" component={TeamsPage} />

			<ProtectedRoute
				path="/billing"
				component={BillingPage}
				permissions={[UserPermissions.CAN_VIEW_BILLING_TAB]}
			/>

			{process.env.APP_ENV === 'dev' && <Route path="/ui-demo/:page" component={UIDemoPage} />}
			{process.env.APP_ENV === 'dev' && (
				<Route path="/ui-demo" exact={true} component={UIDemoPage} />
			)}

			<Redirect to="/teams" />
		</Switch>
	)
}
