import { useFn } from '@motiv-shared/react'
import { hasUserPermission } from '@motiv-shared/reducers'
import { UserPermissions } from '@motiv-shared/server'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { addErrorToast, allUsersWithTeamAssignments$, user$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import { AccountModals } from '../AccountModal'
import { setSelectedAccountModal } from './account.slice'

export const AccountButtons = () => {
	const dispatch = useAppDispatch()
	const allUsers = useSelector(allUsersWithTeamAssignments$)
	const user = useSelector(user$)!

	const handleDeleteAccountClick = useFn(() => {
		dispatch(setSelectedAccountModal(AccountModals.DELETE_ACCOUNT))
	})

	const handleTransferAccountClick = useFn(() => {
		// Added check on users length as if there is no user with Role
		// Admin/TeamLead/Admin+TeamLead then Account Owner will be there.
		if (allUsers?.length === 1) {
			dispatch(
				addErrorToast({
					title: 'Unable to transfer',
					msg: 'Please add a user to your account and try again.',
				})
			)
		} else {
			dispatch(setSelectedAccountModal(AccountModals.TRANSFER_ACCOUNT))
		}
	})

	const canTransferOrDelete = hasUserPermission(
		user,
		UserPermissions.CAN_TRANSFER_OR_DELETE_ACCOUNT
	)

	return (
		<>
			{canTransferOrDelete && (
				<Button
					className="btn-block mb-3"
					onClick={handleTransferAccountClick}
					size="lg"
					variant="light"
				>
					Transfer Account
				</Button>
			)}

			{canTransferOrDelete && (
				<Button
					className="btn-block mb-3"
					onClick={handleDeleteAccountClick}
					size="lg"
					variant="light"
				>
					Delete Account
				</Button>
			)}
		</>
	)
}
