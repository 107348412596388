/**
 * @author Brian Frichette (brian@eturi.com)
 */

/** A more efficient omit function than the deprecated lodash omit */
export const omit = <T, K extends keyof T>(
	obj: T,
	k: K | readonly K[]
): Omit<T, K extends [] ? K[number] : K> => {
	if (!obj) return {} as any

	const newObj: any = {}
	const paths: Set<K> = new Set(Array.isArray(k) ? k : [k])

	for (const key of Object.keys(obj) as K[]) {
		if (paths.has(key)) continue

		newObj[key] = obj[key]
	}

	return newObj
}
