import type { IntegrationProvider } from '@motiv-shared/server'
import { integrationName, IntegrationProviders, integrationShortName } from '@motiv-shared/server'
import { assertUnreachable } from '@motiv-shared/util'
import GoogleIconLogoBwSm from '../../assets/logos/google-icon-logo-bw-sm.png'
import GoogleIconLogoSm from '../../assets/logos/google-icon-logo-sm.png'
import GoogleIconLogo from '../../assets/logos/google-icon-logo.svg'
import GoogleLogoBw from '../../assets/logos/google-logo-bw.png'
import GoogleLogo from '../../assets/logos/google-logo.png'
import OfficeIconLogoBwSm from '../../assets/logos/office-icon-logo-bw-sm.png'
import OfficeIconLogoSm from '../../assets/logos/office-icon-logo-sm.png'
import OfficeIconLogo from '../../assets/logos/office-icon-logo.svg'
import OfficeLogoBw from '../../assets/logos/office-logo-bw.png'
import OfficeLogo from '../../assets/logos/office-logo.png'
import SlackIconLogoBwSm from '../../assets/logos/slack-icon-logo-bw-sm.png'
import SlackIconLogoSm from '../../assets/logos/slack-icon-logo-sm.png'
import SlackIconLogo from '../../assets/logos/slack-icon-logo.svg'
import SlackLogoBw from '../../assets/logos/slack-logo-bw.png'
import SlackLogo from '../../assets/logos/slack-logo.png'
import ZoomIconLogoBwSm from '../../assets/logos/zoom-icon-logo-bw-sm.png'
import ZoomIconLogoSm from '../../assets/logos/zoom-icon-logo-sm.png'
import ZoomIconLogo from '../../assets/logos/zoom-icon-logo.svg'
import ZoomLogoBw from '../../assets/logos/zoom-logo-bw.png'
import ZoomLogo from '../../assets/logos/zoom-logo.png'
import type { IntegrationDisplayData } from '../../types'

// FIXME: Remove bw logos and just use css filter: saturate(0) + opacity
export const getIntegrationDisplayData = (
	provider: IntegrationProvider
): IntegrationDisplayData => {
	const displayName = integrationName(provider)
	const shortName = integrationShortName(provider)

	switch (provider) {
		case IntegrationProviders.GOOGLE:
			return {
				btnClass: 'btn-google',
				displayName,
				iconLogo: GoogleIconLogo,
				iconLogoBwSm: GoogleIconLogoBwSm,
				iconLogoSm: GoogleIconLogoSm,
				logo: GoogleLogo,
				logoBw: GoogleLogoBw,
				shortName,
			}

		case IntegrationProviders.MICROSOFT:
			return {
				btnClass: 'btn-microsoft',
				displayName,
				iconLogo: OfficeIconLogo,
				iconLogoBwSm: OfficeIconLogoBwSm,
				iconLogoSm: OfficeIconLogoSm,
				logo: OfficeLogo,
				logoBw: OfficeLogoBw,
				shortName,
			}

		case IntegrationProviders.SLACK:
			return {
				btnClass: 'btn-slack',
				displayName,
				iconLogo: SlackIconLogo,
				iconLogoBwSm: SlackIconLogoBwSm,
				iconLogoSm: SlackIconLogoSm,
				logo: SlackLogo,
				logoBw: SlackLogoBw,
				shortName,
			}

		case IntegrationProviders.ZOOM:
			return {
				btnClass: 'btn-zoom',
				displayName,
				iconLogo: ZoomIconLogo,
				iconLogoBwSm: ZoomIconLogoBwSm,
				iconLogoSm: ZoomIconLogoSm,
				logo: ZoomLogo,
				logoBw: ZoomLogoBw,
				shortName,
			}
	}

	assertUnreachable(provider, `Unknown provider ${provider}`)
}
