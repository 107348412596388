import './Account.scss'

import { useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { fetchUsers } from '../../reducers'
import { useAppDispatch } from '../../store'
import { fetchTeams } from '../Teams'
import { fetchAccount } from './account.asyncActions'
import { AccountButtons } from './AccountButtons'
import { AccountUsersTable } from './AccountUsersTable'
import { CompanyNameForm } from './CompanyNameForm'

export const AccountPage = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(fetchAccount())
		dispatch(fetchUsers())
		dispatch(fetchTeams())
	}, [])

	return (
		<Row>
			<Col lg={3}>
				<Card className="mb-5">
					<Card.Header as="h3">Account</Card.Header>

					<Card.Body>
						<CompanyNameForm />
					</Card.Body>
				</Card>

				<AccountButtons />
			</Col>

			<Col lg={9}>
				<AccountUsersTable />
			</Col>
		</Row>
	)
}
