import { pick } from '@motiv-shared/util'
import type { IntegrationIdentityId } from './integrations'

// MVP hard limit
export const MAX_TEAMS_PER_ACCOUNT = 10

export type Team = {
	readonly accountId: string
	readonly assignedManagedUsers: string[]
	readonly assignedTeamLeads: string[]
	readonly assignedTeamMembers: IntegrationIdentityId[]
	readonly id: string
	readonly memberCount: number
	readonly name: string
}

export const sanitizeTeam = (ts: Maybe<Team>) =>
	ts
		? pick(ts, [
				'assignedManagedUsers',
				'assignedTeamLeads',
				'assignedTeamMembers',
				'id',
				'memberCount',
		  ])
		: null

export type TeamsCreateData = {
	readonly assignedManagedUsers?: Maybe<string[]>
	readonly assignedTeamLeads?: Maybe<string[]>
	readonly assignedTeamMembers?: Maybe<(string | IntegrationIdentityId)[]>
	readonly name: string
}

export type TeamPatch = SafeOmit<
	Partial<TeamsCreateData>,
	'assignedTeamMembers' | 'assignedTeamLeads' | 'assignedManagedUsers'
> & {
	readonly addAssignedTeamMembers?: Maybe<(string | IntegrationIdentityId)[]>
	readonly addManagedUsers?: Maybe<string[]>
	readonly addTeamLeads?: Maybe<string[]>
	readonly removeAssignedTeamMembers?: Maybe<(string | IntegrationIdentityId)[]>
	readonly removeManagedUsers?: Maybe<string[]>
	readonly removeTeamLeads?: Maybe<string[]>
	readonly setAssignedTeamMembers?: Maybe<(string | IntegrationIdentityId)[]>
	readonly setManagedUsers?: Maybe<string[]>
	readonly setTeamLeads?: Maybe<string[]>
}
