import type { UserPermission } from '@motiv-shared/server'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useAuth } from '../Auth'
import { createHasPermissionsSelector } from '../reducers'
import { canAccessProtected$ } from '../state-helpers'
import { LoadingScreen } from '../widgets/BusyIndicator'

// We want to keep this simple, so remove `children` as an option
type ProtectedRouteProps = SafeOmit<RouteProps, 'children'> & {
	readonly displayName?: string
	readonly permissions?: UserPermission[]
}

export const ProtectedRoute = ({
	component,
	displayName = component?.displayName || component?.name,
	permissions = [],
	render,
	...routeProps
}: ProtectedRouteProps) => {
	const { isReady } = useAuth()
	const canAccessProtected = useSelector(canAccessProtected$)
	const hasPermissions$ = useMemo(createHasPermissionsSelector, [permissions])
	const hasPermissions = useSelector((s) => hasPermissions$(s, permissions))
	const location = useLocation()

	return (
		<Route
			{...routeProps}
			render={(props) => {
				if (!isReady) return <LoadingScreen />

				if (!canAccessProtected) {
					return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
				}

				if (hasPermissions) {
					// If there's a render function use that, otherwise, we have a component
					if (render) return render(props)

					const Component: any = component
					Component.displayName = displayName

					return <Component {...props} />
				}

				return <div>You don&apos;t have the required permissions for this page.</div>
			}}
		/>
	)
}
