import { resetAction } from '@motiv-shared/reducers'
import type { MotivUser, UserRoleData } from '@motiv-shared/server'
import { isIdentifySuccessRes, sanitizeMotivUser } from '@motiv-shared/server'
import { arrayAddOrUpdate, omit } from '@motiv-shared/util'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserModalType } from '../../features/UserModal'
import { withCachedCase } from '../../util/withCachedCase'
import { postAuthIdentify } from '../auth'
import { createUser, fetchRoles, fetchUsers, updateUser } from './users.asyncActions'

export type UsersState = {
	readonly allUsers: MotivUser[]
	readonly loggedInUserId: Maybe<string>
	// FIXME: Refactor to separate slice when John db stuff is done
	readonly roles: UserRoleData[]
	readonly userModal: Maybe<UserModalType>
	readonly userToBeDeleted: Maybe<string>
	readonly userToBeEdited: Maybe<string>
}

export type WithUsersState = {
	readonly users: UsersState
}

const initialState: UsersState = {
	allUsers: [],
	loggedInUserId: null,
	roles: [],
	userModal: null,
	userToBeDeleted: null,
	userToBeEdited: null,
}

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUserModal(s, a: PayloadAction<Maybe<UserModalType>>) {
			s.userModal = a.payload
		},

		setUserToBeEdited(s, a: PayloadAction<Maybe<string>>) {
			s.userToBeEdited = a.payload
		},

		setUserToBeDeleted(s, a: PayloadAction<Maybe<string>>) {
			s.userToBeDeleted = a.payload
		},
	},
	extraReducers: (builder) => {
		withCachedCase(builder)
			.addResetCase(resetAction.type, initialState)

			.addCase(createUser.fulfilled, (s, a) => {
				storeUser(s, a.payload)
			})

			.addCachedCase(fetchUsers.fulfilled, (s, a) => {
				s.allUsers = a.payload
			})

			.addCase(fetchRoles.fulfilled, (s, a) => {
				s.roles = a.payload
			})

			.addCase(postAuthIdentify.fulfilled, (s, { payload }) => {
				if (isIdentifySuccessRes(payload)) {
					s.loggedInUserId = payload.user.id
					storeUser(s, payload.user)
				}
			})

			.addCase(updateUser.fulfilled, (s, a) => {
				storeUser(s, a.payload)
			})
	},
})

const storeUser = (s: Draft<UsersState>, user: MotivUser) => {
	s.allUsers = arrayAddOrUpdate(s.allUsers, user, 'id')
}

export const sanitizeUsersState = (s: UsersState) => ({
	...s,
	allUsers: s.allUsers.map(sanitizeMotivUser),
	roles: s.roles.map((r) => omit(r, 'id')),
	userToBeEdited: s.userToBeEdited ? s.userToBeEdited : null,
})

export const { setUserModal, setUserToBeDeleted, setUserToBeEdited } = usersSlice.actions

export const typeOnlyUsersActions = new Set([setUserToBeDeleted.type, setUserToBeEdited.type])
