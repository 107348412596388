/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import { useConstant, useFn } from '@motiv-shared/react'
import {
	isPaidSub$,
	isTrialSub$,
	nextSeatLimit$,
	purchaseInfo$,
	purchaseSku,
} from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { Token } from '@stripe/stripe-js'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { sentryBreadcrumb, sentryError } from '../../infrastructure'
import {
	activeSubExpiryStr$,
	addErrorToast,
	addSuccessToast,
	clearSubscribingInfo,
	setActivePurchaseModal,
	subscribingCycle$,
	subscribingSKU$,
	subscribingSKUQty$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { getSKUPrice } from '../../types'
import { LoadingFigure } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import { StripeChoosePayment, StripeFormProvider } from '../../widgets/Stripe'
import { assignedSeats$ } from '../Teams'

const createSubStringsSelector = () =>
	createSelector(
		nextSeatLimit$,
		activeSubExpiryStr$,
		assignedSeats$,
		isPaidSub$,
		isTrialSub$,
		subscribingCycle$,
		subscribingSKU$,
		subscribingSKUQty$,
		(seatLimit, expiryStr, assignedSeats, isPaid, isTrial, subCycle, subSKU, subQty) => {
			const seats = subSKU?.seats

			if (!(subSKU && subQty && seats)) return null

			const nextSeatLimit = seats * subQty
			const isMonthly = subCycle === SubscriptionPeriod.MONTHLY
			const isDowngrade = nextSeatLimit < seatLimit
			const price = getSKUPrice(subSKU, subCycle, subQty)

			// E.g. 'You are changing your plan from 14 seats to 10 seats. You will
			// need to remove 4 team members or we will remove the ones most recently
			// added when your new plan becomes active on Jan, 12 2022.'
			const getDowngrade = () => {
				if (!isDowngrade) return null

				let downgrade = `You are changing your plan from ${seatLimit} seats to ${nextSeatLimit} seats. `

				if (assignedSeats > nextSeatLimit) {
					downgrade += `You will need to remove ${assignedSeats - nextSeatLimit} 
					team members or we will remove the ones most recently added when your 
					new plan becomes active on ${expiryStr}.`
				}

				return downgrade
			}

			// E.g. 'At the end of your free trial period, you will be billed an
			// annual subscription fee of $500. You may cancel your membership at
			// any time.'
			const description = `${isTrial ? 'At the end of your free trial period, you' : 'You'} 
			will be billed ${isMonthly ? 'a monthly' : 'an annual'} subscription fee of $${price}. 
			You may cancel your membership at any time.`

			// E.g. '10 Team Members Annually. Plan: $500 / Year'
			const tierInfoTitle = `${nextSeatLimit} Team Members ${isMonthly ? 'Monthly' : 'Annually'} 
			Plan: $${price} / ${isMonthly ? 'Month' : 'Year'}`

			return {
				action: isDowngrade ? 'Downgrade' : 'Save',
				description,
				downgrade: getDowngrade(),
				tierInfoTitle,
				title: isPaid ? 'Update Plan' : 'Subscribe Now',
			}
		}
	)

export const SubscribeModal = () => {
	const subStrings$ = useConstant(createSubStringsSelector)
	const dispatch = useAppDispatch()

	const subStrings = useSelector(subStrings$)
	const purchaseInfo = useSelector(purchaseInfo$)
	const subscribingCycle = useSelector(subscribingCycle$)
	const subscribingSku = useSelector(subscribingSKU$)
	const subscribingSKUQty = useSelector(subscribingSKUQty$)!

	const handleCancel = useFn(() => {
		dispatch(clearSubscribingInfo())
		dispatch(setActivePurchaseModal(null))
	})

	const handleSubmit = useFn(async (token?: Token) => {
		sentryBreadcrumb(`${token ? 'Subscribing to' : 'Upgrading'} Plan`)

		try {
			await dispatch(
				purchaseSku({
					period: subscribingCycle,
					quantity: subscribingSKUQty,
					sku: subscribingSku!.id,
					token: token?.id,
				})
			)

			dispatch(addSuccessToast('Your plan has been updated'))

			// This will close the modal if purchase was successful
			handleCancel()
		} catch (e) {
			sentryError(e, `Failed to ${token ? 'subscribe' : 'update'}`)
			dispatch(addErrorToast('Unknown error occurred. Please try again'))
		}
	})

	if (!subStrings) return null

	const { action, description, downgrade, tierInfoTitle, title } = subStrings

	return (
		<StripeFormProvider onSubmit={handleSubmit}>
			{(sp) => (
				<MotivModal onHide={handleCancel} size="lg" title={title}>
					<Modal.Body>
						{downgrade && <p>{downgrade}</p>}

						<p className="h6 mb-4">{tierInfoTitle}</p>

						{sp.isReady ? <StripeChoosePayment purchaseInfo={purchaseInfo} /> : <LoadingFigure />}

						<p className="mb-0">{description}</p>
					</Modal.Body>

					{sp.isReady && (
						<Modal.Footer>
							<Button
								disabled={!sp.isValid || sp.isSaving}
								onClick={sp.submitForm}
								size="lg"
								variant={downgrade ? 'danger' : 'success'}
							>
								{action}
							</Button>

							<Button disabled={sp.isSaving} onClick={handleCancel} size="lg" variant="light">
								Cancel
							</Button>
						</Modal.Footer>
					)}
				</MotivModal>
			)}
		</StripeFormProvider>
	)
}
