import { activeSeatLimit$ } from '@motiv-shared/reducers'
import { MAX_MEMBERS_PER_TEAM } from '@motiv-shared/server'
import { notEmpty } from '@motiv-shared/util'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import type { WithTeamsState } from './teams.slice'

export const state$ = <T extends WithTeamsState>(s: T) => s.teams

export const createTeamMemberIds$ = createSelector(state$, (s) => s.createTeamMemberIds)
export const selectedTeamMemberIds$ = createSelector(state$, (s) => s.selectedTeamMemberIds)
export const settingsModal$ = createSelector(state$, (s) => s.teamsModal)
export const settingsTeam$ = createSelector(state$, (s) =>
	find(s.teams, { id: s.selectedTeamId } as any)
)
export const settingsTeamMembers$ = createSelector(state$, (s) => s.teamMembers)
export const settingsTeams$ = createSelector(state$, (s) => s.teams)
export const teamToBeDeleted$ = createSelector(state$, (s) => s.teamToBeDeleted)
export const doTeamsExist$ = createSelector(settingsTeams$, notEmpty)
export const teamMembersWithTeamAssignments$ = createSelector(
	settingsTeams$,
	settingsTeamMembers$,
	(teams, teamMembers) => {
		return teamMembers.map((teamMember) => ({
			...teamMember,
			teamAssignments: teams.filter((team) => team.assignedTeamMembers.includes(teamMember.id)),
		}))
	}
)

export const selectedTeamMembersWithTeamAssignments$ = createSelector(
	selectedTeamMemberIds$,
	settingsTeamMembers$,
	settingsTeams$,
	(ids, tms, teams) =>
		tms
			.filter((tm) => ids.includes(tm.id))
			.map((tm) => ({
				...tm,
				teamAssignments: teams.filter((t) => t.assignedTeamMembers.includes(tm.id)),
			}))
)

export const assignedTeamMembers$ = createSelector(teamMembersWithTeamAssignments$, (tm) =>
	tm.filter((m) => notEmpty(m.teamAssignments))
)

// Team member ids that are only assigned to the currently selected team
export const uniqueTeamMembersOfSelectedTeam$ = createSelector(
	settingsTeam$,
	assignedTeamMembers$,
	(tm, atm) => {
		return atm
			.filter((a) => a.teamAssignments.length === 1 && a.teamAssignments[0].id === tm?.id)
			.map((b) => b.id)
	}
)

export const assignedTeamMemberIds$ = createSelector(assignedTeamMembers$, (tms) =>
	tms.map((t) => t.id)
)

export const assignedSeats$ = createSelector(assignedTeamMembers$, (tm) => tm.length)
export const hasSeatLimit$ = createSelector(assignedSeats$, activeSeatLimit$, (as, sl) => as >= sl)
