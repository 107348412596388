/**
 * @fileOverview Normalizes ev.key for common keyboard events
 * @author Brian Frichette (brian@eturi.com)
 */

import type { KeyboardEvent, SyntheticEvent } from 'react'

const isKey = (...keys: string[]) => <T extends { readonly key: string }>(ev: T) =>
	keys.some((k) => k === ev.key)

export const isKeyboardEvent = <T extends SyntheticEvent>(ev: T): ev is T & KeyboardEvent<T> =>
	Boolean((ev as any).key)

export const isArrowDown = /* @__PURE__ */ isKey('ArrowDown', 'Down')
export const isArrowUp = /* @__PURE__ */ isKey('ArrowUp', 'Up')
export const isArrowRight = /* @__PURE__ */ isKey('ArrowRight', 'Right')
export const isArrowLeft = /* @__PURE__ */ isKey('ArrowLeft', 'Left')
export const isEscape = /* @__PURE__ */ isKey('Escape', 'Esc')
export const isEnter = /* @__PURE__ */ isKey('Enter')
export const isSpace = /* @__PURE__ */ isKey(' ', 'Spacebar')
