/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useState } from 'react'
import { useFn } from './useFn'
import type { UseGetState, UseSetState } from './useStateGetter'

/**
 * Same thing as `useState`, but only updates when state changes
 */
export const useChgState = <T>(initValue: T | UseGetState<T>): [T, UseSetState<T>] => {
	const [state, _setState] = useState(initValue)

	const setState = useFn((v: T) => {
		if (state === v) return
		_setState(v)
	})

	return [state, setState]
}
