export type TeamsModalType = ValueUnion<typeof TeamsModals>
export const TeamsModals = {
	ASSIGN_TEAM_MEMBER_TO_TEAMS: 'assignedTeamMemberToTeams',
	ASSIGN_TEAM_MEMBERS_TO_TEAMS: 'assignedTeamMembersToTeams',
	CREATE_TEAM: 'createTeam',
	DELETE_INTEGRATION: 'deleteIntegration',
	DELETE_TEAM: 'deleteTeam',
	INTEGRATION: 'integration',
	UPDATE_TEAM: 'updateTeam',
} as const
