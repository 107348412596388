import { useSelector } from 'react-redux'
import { accountModal$ } from '../Account'
import { AccountModals } from './accountModals.constants'
import { DeleteAccountModal } from './DeleteAccountModal'
import { EmailSubscriptionAccountModal } from './EmailSubscriptionAccountModal'
import { TransferAccountModal } from './TransferAccountModal'

export const AccountModal = () => {
	const accountModal = useSelector(accountModal$)

	switch (accountModal) {
		case AccountModals.DELETE_ACCOUNT:
			return <DeleteAccountModal />

		case AccountModals.EMAIL_SUBSCRIPTION:
			return <EmailSubscriptionAccountModal />

		case AccountModals.TRANSFER_ACCOUNT:
			return <TransferAccountModal />
	}

	return null
}
