/**
 * @author Brian Frichette (brian@eturi.com)
 */

import { useRef } from 'react'

/**
 * Defines a constant using a factory function. Note that
 * this is the similar to `useFn` but using a factory function instead of
 * a raw value. Here are two examples of where to use this over `useFn`:
 *
 * 1. Instantiating Stripe. If you decide to use
 * `const s = useFn(Stripe(apiKey))`, then `s` will be the original Stripe
 * instance every time, but Stripe itself still gets instantiated every render.
 * Doing `const s = useConstant(() => Stripe(apiKey))`, solves this, since the
 * factory is only called once.
 *
 * 2. Components that never change. E.g. `const Foo = useFn(<div/>)`. This will
 * cause the `<div>` to be newly created each time, even if the element returned
 * is always the original `<div>`. `const Foo = useConstant(() => <div/>)` fixes
 * this.
 *
 * @see useFn
 */
export const useConstant = <T>(factory: () => T): T => {
	const initRef = useRef(false)
	const valueRef = useRef<T>()

	if (!initRef.current) {
		initRef.current = true
		valueRef.current = factory()
	}

	return valueRef.current!
}
