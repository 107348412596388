import './BusyIndicator.scss'

import { useConstant, useMemoEq } from '@motiv-shared/react'
import type { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import type { IndicatorRegion } from './busyIndicator.constants'
import { createNamedBusyIndicatorSelector } from './busyIndicator.selectors'

type BusyIndicatorProps = {
	readonly children?: ReactNode
	readonly region?: IndicatorRegion | IndicatorRegion[]
}

export const BusyIndicator = ({ children, region }: BusyIndicatorProps) => {
	// Normalize region to list of regions or undefined. Memoize on region equality.
	const normalizedRegion = useMemoEq(
		() => (Array.isArray(region) ? region : region ? [region] : region),
		[region]
	)
	const isBusyIndicatorVisible$ = useConstant(createNamedBusyIndicatorSelector)
	const isBusyIndicatorVisible = useSelector((s) => isBusyIndicatorVisible$(s, normalizedRegion))
	const hasContentToDisplay = !isBusyIndicatorVisible && children

	if (isBusyIndicatorVisible) return <LoadingFigure />

	return hasContentToDisplay ? <>{children}</> : <p>No content found</p>
}

export const LoadingScreen = () => (
	<div className="d-flex flex-fill">
		<LoadingFigure />
	</div>
)

export const LoadingFigure = () => (
	<div className="motiv-loader">
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
		<div className="motiv-loader__bar" />
	</div>
)
