import {
	activeCycle$,
	activeSeatLimit$,
	activeSKU$,
	activeSub$,
	isActiveMonthlySub$,
	isDowngradedSub$,
	isPaidSub$,
	isTrialPeriod$,
	isTrialSub$,
	nextSKU$,
	nextSub$,
} from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import cls from 'classnames'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$ } from '../../reducers'
import { getSubPrice } from '../../types'
import { assignedSeats$ } from '../Teams'
import { PlanLineItem } from './PlanLineItem'

export const CurrentPlanDetails = () => {
	const activeCycle = useSelector(activeCycle$)
	const activeSeatLimit = useSelector(activeSeatLimit$)
	const activeSku = useSelector(activeSKU$)
	const activeSub = useSelector(activeSub$)
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const assignedSeats = useSelector(assignedSeats$)
	const isDowngradeSub = useSelector(isDowngradedSub$)
	const isMonthlySub = useSelector(isActiveMonthlySub$)
	const isPaidSub = useSelector(isPaidSub$)
	const isTrialPeriod = useSelector(isTrialPeriod$)
	const isTrialSub = useSelector(isTrialSub$)
	const nextSku = useSelector(nextSKU$)
	const nextSub = useSelector(nextSub$)

	const expiredSub = activeSub && !nextSub

	const CurrentPlan = useMemo(() => {
		if (!activeSub) return 'N/A'

		return `${isDowngradeSub ? 'Current: ' : ''}${activeSeatLimit} Seats`
	}, [activeSub, activeSeatLimit, isDowngradeSub])

	const SubDate = useMemo(() => {
		const prefix = isTrialPeriod
			? 'Starts'
			: expiredSub || isDowngradeSub
			? 'Expires'
			: nextSub
			? 'Renews'
			: null

		if (!(prefix && activeSub)) return null

		return (
			<PlanLineItem
				className={cls((expiredSub || isDowngradeSub) && !isTrialPeriod && 'text-danger')}
			>
				{`${prefix}: ${activeSubExpiryStr}`}
			</PlanLineItem>
		)
	}, [activeSub, expiredSub, activeSubExpiryStr, isDowngradeSub, activeSku])

	const Cycle = useMemo(
		() =>
			activeCycle &&
			activeCycle !== SubscriptionPeriod.NON_RECURRING && (
				<PlanLineItem>{`Cycle: ${isMonthlySub ? 'Monthly' : 'Annually'}`}</PlanLineItem>
			),
		[activeCycle, isMonthlySub]
	)

	const Cost = useMemo(() => {
		if (!(activeSku && activeSub) || isTrialSub || !isPaidSub) return null

		const cost = getSubPrice(activeSku, activeSub)
		const cycle = isMonthlySub ? 'Month' : 'Year'

		return <PlanLineItem>{`Cost: $${cost}/${cycle}`}</PlanLineItem>
	}, [nextSub, nextSku, isMonthlySub])

	const SeatInfo = useMemo(() => {
		if (!activeSeatLimit) return null

		const emptySeats = Math.max(0, activeSeatLimit - assignedSeats)

		return (
			<>
				<PlanLineItem>{`Occupied Seats: ${assignedSeats}`}</PlanLineItem>
				<PlanLineItem>{`Empty Seats: ${emptySeats}`}</PlanLineItem>
			</>
		)
	}, [activeSub, assignedSeats])

	const hasLineItems = Boolean(SubDate || Cycle || Cost || SeatInfo)

	return (
		<>
			<h4 className={`mb-${hasLineItems ? 4 : 0}`}>{CurrentPlan}</h4>

			{hasLineItems && (
				<ul className="plan-line-items">
					{SubDate}
					{Cycle}
					{Cost}
					{SeatInfo}
				</ul>
			)}
		</>
	)
}
