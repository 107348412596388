import { pick } from '@motiv-shared/util'
import type { IntegrationIdentityId, IntegrationProvider } from './integrations'
import { sanitizeIntegrationId } from './ManagedUser'

// Hard limit
export const MAX_MEMBERS_PER_ACCOUNT = 100

export const MAX_MEMBERS_PER_TEAM = 10

export type TeamMember = {
	readonly avatarUrl?: string
	readonly email: string
	readonly firstName: string
	readonly fullName: string
	readonly id: IntegrationIdentityId
	readonly lastName: string
	readonly source: IntegrationProvider // TODO: Remove when multiple integrations are supported
}

export const sanitizeTeamMember = (tm: TeamMember) => ({
	...pick(tm, ['source']),
	id: sanitizeIntegrationId(tm.id),
})
