import type { IdentifyCreateData, IdentifyRes } from '@motiv-shared/server'
import { ClientType } from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'

export const postAuthIdentify = createAsyncThunk(
	'postLoginIdentify',
	(data: IdentifyCreateData = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.post<IdentifyRes>('identify', {
				data: { clientType: ClientType.WEB, ...data },
				errorMessage: 'Unable to get login identity.',
				force: true,
			})
		)
)
