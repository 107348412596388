/**
 * @author Freddy Solis (freddy@eturi.com)
 */

import './PlanDetails.scss'

import { useFn } from '@motiv-shared/react'
import { activeSub$, isTrialSub$, nextSKU$ } from '@motiv-shared/reducers'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { PurchaseModalType } from '../../types'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { CurrentPlanDetails } from './CurrentPlanDetails'
import { UpcomingPlanDetails } from './UpcomingPlanDetails'

export const PlanDetails = ({ className }: { readonly className?: string }) => {
	const dispatch = useAppDispatch()
	const activeSub = useSelector(activeSub$)
	const isTrial = useSelector(isTrialSub$)
	const nextSku = useSelector(nextSKU$)

	const handleCancellation = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.CANCEL))
	})

	const handlePaymentUpdate = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.UPDATE_PAYMENT))
	})

	return (
		<div className={className}>
			<h3 className="mb-4">{isTrial ? 'Free Trial' : 'Plan Details'}</h3>

			<Card>
				<Card.Body as={Row}>
					<Col xs={12} md={8}>
						<Row className="mb-5 mb-md-0">
							<Col className="mb-4 mb-sm-0" xs={12} sm={6}>
								<BusyIndicator region={IndicatorRegions.TEAM_MEMBERS}>
									<CurrentPlanDetails />
								</BusyIndicator>
							</Col>

							{nextSku && (
								<Col className="plan-details__center" xs={12} sm={6}>
									<UpcomingPlanDetails />
								</Col>
							)}
						</Row>
					</Col>

					{nextSku && (
						<Col md={4}>
							<Button block size="lg" onClick={handlePaymentUpdate}>
								Update Payment
							</Button>

							<Button block className="mt-3" size="lg" onClick={handleCancellation}>
								Cancel Subscription
							</Button>
						</Col>
					)}

					{!activeSub && (
						<p className="ml-4 mt-5 mb-0">Subscribe now to start using Motiv for your team.</p>
					)}
				</Card.Body>
			</Card>
		</div>
	)
}
