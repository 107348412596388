import { useFn } from '@motiv-shared/react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import CancelIcon from '../../assets/icons/cancel.svg'
import { closeToast } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { MotivToastType } from '../../types'

export type ToastProps = {
	readonly id: string
	readonly msg: string
	readonly title: string
	readonly type: MotivToastType
}

export const Toast = ({ id, msg, title, type }: ToastProps) => {
	const dispatch = useAppDispatch()

	const handleClose = useFn(() => {
		dispatch(closeToast({ id }))
	})

	// className is set as motiv-toast to avoid bootstrap style conflicts
	return (
		<div className={`motiv-toast motiv-toast--${type}`} title={msg}>
			<header className="motiv-toast__header">
				<h6 className="motiv-toast__title text-break">{title}</h6>

				<Button variant="link" onClick={handleClose} className="motiv-toast__close">
					<Image src={CancelIcon} alt="cancel-icon" />
				</Button>
			</header>
			<p className="motiv-toast__message text-break">{msg}</p>
		</div>
	)
}
