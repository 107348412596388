import './SubOptions.scss'

import { useFn, useToggleState } from '@motiv-shared/react'
import { activeSub$, isPaidSub$, isTrialSub$, seatSKU$ } from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { isSubscribingMonthly$, setActivePurchaseModal, setSubscribingInfo } from '../../reducers'
import { useAppDispatch } from '../../store'
import { getSKUPrice, getSKUPriceMonthlyPerSeat, PurchaseModalType } from '../../types'
import { SlideToggle } from '../../widgets/SlideToggle'

export const SubOptions = () => {
	const dispatch = useAppDispatch()
	const activeSub = useSelector(activeSub$)
	const isPaidSub = useSelector(isPaidSub$)
	const isSubscribingMonthly = useSelector(isSubscribingMonthly$)
	const isTrialSub = useSelector(isTrialSub$)
	const seatSKU = useSelector(seatSKU$)

	const MAX_SEATS = seatSKU?.maxQuantity || 100
	const MIN_SEATS = seatSKU?.minQuantity || 10

	const [isMonthlySelected, toggleMonthlySelected] = useToggleState(isSubscribingMonthly)
	const [seatCount, setSeatCount] = useState(
		!isTrialSub && isPaidSub ? activeSub?.quantity || MIN_SEATS : MIN_SEATS
	)

	const period = isMonthlySelected ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUAL

	const handleSeatChange = useFn((ev: ChangeEvent<HTMLInputElement>) => {
		const updatedCount = Number(ev.target.value)
		if (updatedCount > MAX_SEATS || updatedCount < MIN_SEATS) return

		setSeatCount(updatedCount)
	})

	const updateSeatCount = useFn((updatedCount: number) => {
		if (updatedCount > MAX_SEATS || updatedCount < MIN_SEATS) return
		setSeatCount(updatedCount)
	})

	const handleUpdate = useFn(() => {
		dispatch(
			setSubscribingInfo(
				seatCount,
				isMonthlySelected ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUAL
			)
		)

		dispatch(setActivePurchaseModal(PurchaseModalType.PURCHASE))
	})

	if (!seatSKU) return null

	// SKU Price is cost per cycle per seat, so to get the cost of each seat it would be
	const costPerSeat = getSKUPriceMonthlyPerSeat(seatSKU, period)
	const costPerMonth = costPerSeat * seatCount
	const totalCost = getSKUPrice(seatSKU, period, seatCount)

	return (
		<div className="sub-options">
			<h3 className="mb-4">{isTrialSub || !isPaidSub ? 'Subscription Options' : 'Update Plan'}</h3>

			<Card>
				<Card.Body className="py-5">
					<Row>
						<Col className="flex-column flex-center mb-5" sm={12}>
							<h4 className="mb-2">Billing Cycle</h4>

							<p className="mb-5">(save up to 50% by switching to annual)</p>

							<div className="d-flex align-items-center">
								<h6 className="mb-0 text-center">Pay Monthly</h6>

								<SlideToggle
									checked={!isMonthlySelected}
									className="mx-4"
									onChange={toggleMonthlySelected}
								/>

								<h6 className="mb-0 text-center">Pay Annually</h6>
							</div>
						</Col>

						<Col sm={12}>
							<Row>
								<Col
									className="d-flex flex-column align-items-center justify-content-end mb-5 mb-sm-0"
									xs={12}
									sm={6}
								>
									<h4 className="mb-0">Seats</h4>
									<p className="mb-3">{`$${costPerSeat} / month per seat`}</p>

									<div className="form-control sub-seats__selector content-light" tabIndex={0}>
										<Button
											className="flex-center sub-seats__icon"
											disabled={seatCount <= MIN_SEATS}
											onClick={() => updateSeatCount(seatCount - 1)}
											variant="light"
										>
											{`-`}
										</Button>

										<input
											className="sub-seats__input text-center border-0"
											max={MAX_SEATS}
											min={MIN_SEATS}
											onChange={handleSeatChange}
											type="number"
											value={seatCount}
										/>

										<Button
											className="flex-center sub-seats__icon"
											disabled={seatCount >= MAX_SEATS}
											onClick={() => updateSeatCount(seatCount + 1)}
											variant="light"
										>
											{`+`}
										</Button>
									</div>
								</Col>

								<Col className="d-flex flex-column align-items-center" xs={12} sm={6}>
									<h4 className="mb-0">{`$${costPerMonth} / month`}</h4>

									<p className="mb-3">
										billed {isMonthlySelected ? 'monthly' : `$${totalCost} annually`}
									</p>

									<Button
										block
										onClick={handleUpdate}
										size="lg"
										style={{ maxWidth: '275px' }}
										variant="success"
									>{`${isPaidSub ? 'Update Plan' : 'Subscribe Now'}`}</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}
