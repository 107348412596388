import type { QueryStrInit } from '@motiv-shared/react'
import { toQueryStr } from '@motiv-shared/react'
import type {
	CreateIntegrationInfo,
	Integration,
	IntegrationCatalogData,
	IntegrationProvider,
} from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { HttpExtra } from '../../http'
import { IndicatorRegions } from '../../widgets/BusyIndicator'

type CreateIntegrationArgs = HttpExtra & {
	readonly provider: IntegrationProvider
}

export const createIntegration = createAsyncThunk(
	'integrations/createIntegration',
	({ provider, ...extra }: CreateIntegrationArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.post<CreateIntegrationInfo>(`integrations?provider=${provider}`, {
				errorMessage: 'Unable to start integration. Please try again later.',
				ignoreIndicator: true,
				...extra,
			})
		)
)

type DeleteIntegrationArgs = HttpExtra & {
	readonly id: string
}

export const deleteIntegration = createAsyncThunk(
	'integration/Id',
	({ id, ...extra }: DeleteIntegrationArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`integrations/${id}`, {
				errorMessage: 'Unable to disconnect integration. Please try again later.',
				indicatorRegion: IndicatorRegions.DISCONNECT_INTEGRATION,
				...extra,
			})
		)
)

type FetchIntegrationsArgs = HttpExtra & {
	readonly query?: QueryStrInit
}

export const fetchIntegrations = createAsyncThunk(
	'integrations',
	({ query, ...extra }: FetchIntegrationsArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Integration[]>(`integrations${toQueryStr(query)}`, {
				errorMessage: 'Unable to load integrations. Please try again later.',
				...extra,
			})
		)
)

export const fetchIntegrationCatalog = createAsyncThunk(
	'integrations-catalog',
	({ ...extra }: HttpExtra = {}, { dispatch, extra: { http } }) => {
		return dispatch(
			http.get<IntegrationCatalogData[]>(`integrations-catalog`, {
				errorMessage: 'Unable to load integrations. Please try again later.',
				...extra,
			})
		)
	}
)
