import './DemoFormsPage.scss'

import { useConstant, useFn, useToggleState } from '@motiv-shared/react'
import { Form as FormikForm, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import * as Yup from 'yup'
import type { FormikSubmit } from '../types'
import { formikChkProps, formikCtrlProps } from '../util'
import { FormValidationText } from '../widgets/FormValidationText'

export const DemoFormsPage = () => {
	return (
		<div className="demo-forms-page">
			<CondensedForm />
			<InlineLabels />
			<PlainForm />
		</div>
	)
}

type CondensedFormData = {
	readonly email: string
	readonly password: string
	readonly city: string
	readonly state: string
	readonly zip: string
}

const CondensedForm = () => {
	const [hasVisibleLabels, toggleLabelsVisibility] = useToggleState(false)

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().required('Email is required').email('Invalid email').default(''),
			password: Yup.string()
				.required('Password is required')
				.matches(/^motiv$/, `Password is 'motiv'`)
				.default(''),
			city: Yup.string()
				.required('Please enter your city')
				.min(3, 'City should be at least 3 characters')
				.default(''),
			state: Yup.string()
				.required('Please enter your state.')
				.min(2, 'State should be at least 2 characters')
				.default(''),
			zip: Yup.string()
				.required('Zip code is required')
				.matches(/^\d{5}(-\d{4})?$/, 'Invalid zip')
				.default(''),
		})
	)

	const INITIAL_VALUES = useConstant((): CondensedFormData => validationSchema.cast({}))

	const handleSubmit: FormikSubmit<CondensedFormData> = useFn((v, helpers) => {
		alert(JSON.stringify(v, null, 2))
		console.log(v)
		helpers.resetForm({ values: v })
	})

	return (
		<Card>
			<Card.Header>
				<Row>
					<Col>Condensed Form</Col>
					<Form.Group className="mb-0" as={Col} sm="auto" controlId="condensedToggle">
						<Form.Check
							checked={hasVisibleLabels}
							inline
							label={<Form.Label className="mb-0">Toggle Labels</Form.Label>}
							onChange={toggleLabelsVisibility}
						/>
					</Form.Group>
				</Row>
			</Card.Header>

			<Card.Body>
				<Formik<CondensedFormData>
					initialValues={INITIAL_VALUES}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{(p) => {
						const getCtrlProps = formikCtrlProps(p)

						return (
							<Form as={FormikForm}>
								<Row>
									<Form.Group as={Col} sm="6" controlId="condensedEmail">
										{hasVisibleLabels && <Form.Label>Email</Form.Label>}

										<Form.Control
											placeholder="Enter email"
											type="email"
											{...getCtrlProps('email')}
										/>

										<FormValidationText field="email" formikProps={p} valid="Email is valid" />
									</Form.Group>

									<Form.Group as={Col} sm="6" controlId="condensedPassword">
										{hasVisibleLabels && <Form.Label>Password</Form.Label>}

										<Form.Control
											placeholder="Enter password"
											type="password"
											{...getCtrlProps('password')}
										/>

										<FormValidationText
											field="password"
											formikProps={p}
											valid="Password is valid"
										/>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} sm="5" controlId="condensedCity">
										{hasVisibleLabels && <Form.Label>City</Form.Label>}

										<Form.Control placeholder="City" {...getCtrlProps('city')} />

										<FormValidationText field="city" formikProps={p} valid="City is valid" />
									</Form.Group>

									<Form.Group as={Col} sm="3" controlId="condensedState">
										{hasVisibleLabels && <Form.Label>State</Form.Label>}

										<Form.Control placeholder="State" {...getCtrlProps('state')} />

										<FormValidationText field="state" formikProps={p} valid="Valid state" />
									</Form.Group>

									<Form.Group as={Col} sm="4" controlId="condensedZip">
										{hasVisibleLabels && <Form.Label>Zip</Form.Label>}

										<Form.Control placeholder="Enter zip code" {...getCtrlProps('zip')} />

										<FormValidationText field="zip" formikProps={p} valid="Valid zip" />
									</Form.Group>
								</Row>

								<Button className="mr-3" type="submit" variant="success">
									Submit
								</Button>

								<Button className="mr-3" type="reset" variant="light-link">
									Reset
								</Button>
							</Form>
						)
					}}
				</Formik>
			</Card.Body>
		</Card>
	)
}

type InlineLabelsFormData = {
	readonly email: string
	readonly password: string
	readonly checks: string[]
	readonly radio: Maybe<string>
}

const InlineLabels = () => {
	const INITIAL_VALUES = useConstant(
		(): InlineLabelsFormData => ({
			email: '',
			password: '',
			checks: [],
			radio: null,
		})
	)

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().required('Email is required.').email('Email is not valid'),
			password: Yup.string()
				.required('Password is required!')
				.matches(/^motiv$/, `Password is 'motiv'`),
		})
	)

	const handleSubmit: FormikSubmit<InlineLabelsFormData> = useFn((v, helpers) => {
		alert(JSON.stringify(v, null, 2))
		console.log(v)
		// Reset form so submitCount goes to 0. We could also clear values.
		helpers.resetForm({ values: v })
	})

	return (
		<Card>
			<Card.Header>Inline labels</Card.Header>

			<Card.Body>
				<Formik<InlineLabelsFormData>
					initialValues={INITIAL_VALUES}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{(p) => {
						const getCtrlProps = formikCtrlProps(p)
						const getChkProps = formikChkProps(p, 'checks')
						const getRadioProps = formikChkProps(p, 'radio', 'radio')

						return (
							<Form as={FormikForm}>
								<Form.Group as={Row} controlId="inlineLabelsEmail">
									<Form.Label column sm="2">
										Email
									</Form.Label>

									<Col sm="10">
										<Form.Control
											placeholder="Enter email"
											type="email"
											{...getCtrlProps('email')}
										/>

										<FormValidationText field="email" formikProps={p} valid="Email is valid!" />
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="inlineLabelsPassword">
									<Form.Label column sm="2">
										Password
									</Form.Label>

									<Col sm="10">
										<Form.Control
											placeholder="Enter password"
											type="password"
											{...getCtrlProps('password')}
										/>

										<FormValidationText
											field="password"
											formikProps={p}
											valid="Password is valid!"
										/>
									</Col>
								</Form.Group>

								<Form.Group>
									<Form.Check
										id="inlineLabelsCheck1"
										inline
										label="Check 1"
										{...getChkProps('1')}
									/>

									<Form.Check
										id="inlineLabelsCheck2"
										inline
										label="Check 2"
										{...getChkProps('2')}
									/>

									<Form.Check
										disabled
										id="inlineLabelsCheck3"
										inline
										label="Check 3 (disabled)"
										{...getChkProps('3')}
									/>
								</Form.Group>

								<Form.Group>
									<Form.Check
										id="inlineLabelsRadio1"
										inline
										label="Radio 1"
										{...getRadioProps('1')}
									/>

									<Form.Check
										id="inlineLabelsRadio2"
										inline
										label="Radio 2"
										{...getRadioProps('2')}
									/>

									<Form.Check
										disabled
										id="inlineLabelsRadio3"
										inline
										label="Radio 3 (disabled)"
										{...getRadioProps('3')}
									/>
								</Form.Group>

								<Button className="mr-3" variant="success" type="submit">
									Submit
								</Button>

								<Button type="reset" variant="light-link">
									Reset
								</Button>
							</Form>
						)
					}}
				</Formik>
			</Card.Body>
		</Card>
	)
}

type PlainFormData = {
	readonly email: string
	readonly password: string
	readonly textarea: string
	readonly checks: string[]
	readonly radio: Maybe<string>
}

const PlainForm = () => {
	const INITIAL_VALUES = useConstant(
		(): PlainFormData => ({
			email: '',
			password: '',
			textarea: '',
			checks: [],
			radio: null,
		})
	)

	const validationSchema = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().required('Email is required!').email('Invalid email'),
			password: Yup.string()
				.required('Password is required!')
				.matches(/^motiv$/, `Password is 'motiv'`),
			textarea: Yup.string().max(200, '200 character max!'),
			checks: Yup.array().min(2, 'Select at least two.'),
			radio: Yup.string().nullable().required('Value is required').matches(/2/, 'Choose 2'),
		})
	)

	const handleSubmit: FormikSubmit<PlainFormData> = useFn((v, helpers) => {
		alert(JSON.stringify(v, null, 2))
		console.log(v)
		helpers.resetForm({ values: v })
	})

	return (
		<Card>
			<Card.Header>Regular form</Card.Header>

			<Card.Body>
				<Formik<PlainFormData>
					initialValues={INITIAL_VALUES}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{(p) => {
						const { errors } = p
						const getCtrlProps = formikCtrlProps(p)
						const getChkProps = formikChkProps(p, 'checks')
						const getRadioProps = formikChkProps(p, 'radio', 'radio')

						return (
							<Form as={FormikForm}>
								<Form.Group controlId="plainFormEmail">
									<Form.Label>Email address</Form.Label>

									<Form.Control type="email" placeholder="Enter email" {...getCtrlProps('email')} />

									<Form.Text>Here{`'`}s some form text</Form.Text>
									<Form.Control.Feedback>Email is valid!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId="plainFormPassword">
									<Form.Label>Password</Form.Label>

									<Form.Control
										type="password"
										placeholder="Enter password"
										{...getCtrlProps('password')}
									/>

									<Form.Control.Feedback>Password is valid!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId="plainFormTextarea">
									<Form.Label>Textarea</Form.Label>
									<Form.Control
										as="textarea"
										rows={3}
										placeholder="You can type some stuff in here if you want"
										{...getCtrlProps('textarea')}
									/>

									<Form.Control.Feedback>Text is fine.</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">{errors.textarea}</Form.Control.Feedback>
								</Form.Group>

								<Form.Group>
									<Form.Check label="Check 1" id="plainFormCheck1" {...getChkProps('1')} />
									<Form.Check label="Check 2" id="plainFormCheck2" {...getChkProps('2')} />
									<Form.Check label="Check 3" id="plainFormCheck3" {...getChkProps('3')} />
									<Form.Check
										disabled
										id="plainFormCheck4"
										label="Check 4 (disabled)"
										{...getChkProps('4')}
									/>

									<FormValidationText field="checks" formikProps={p} valid="Good job!" />
								</Form.Group>

								<Form.Group>
									<Form.Check label="Radio 1" id="plainFormRadio1" {...getRadioProps('1')} />
									<Form.Check label="Radio 2" id="plainFormRadio2" {...getRadioProps('2')} />
									<Form.Check label="Radio 3" id="plainFormRadio3" {...getRadioProps('3')} />

									<Form.Check
										disabled
										id="plainFormRadio4"
										label="Radio 4 (disabled)"
										{...getRadioProps('4')}
									/>

									<FormValidationText field="radio" formikProps={p} valid="Radio is correct!" />
								</Form.Group>

								<Button className="mr-3" variant="success" type="submit">
									Submit
								</Button>

								<Button type="reset" variant="light-link">
									Reset
								</Button>
							</Form>
						)
					}}
				</Formik>
			</Card.Body>
		</Card>
	)
}
