import { useConstant, useFn, useToggleState } from '@motiv-shared/react'
import { Form as FormikForm, Formik } from 'formik'
import { useMemo, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import * as Yup from 'yup'
import type { FormikSubmit } from '../types'
import { formikChkProps, formikCtrlProps } from '../util'
import { FormValidationText } from '../widgets/FormValidationText'
import type { MotivModalProps } from '../widgets/Modal'
import { MotivModal } from '../widgets/Modal'
import { DemoTable } from './DemoTablePage'

export const DemoModalPage = () => {
	const [isLgVisible, toggleLgVisibility] = useToggleState(false)
	const [isMdVisible, toggleMdVisibility] = useToggleState(false)
	const [isResVisible, toggleResVisibility] = useToggleState(false)
	const [isSmVisible, toggleSmVisibility] = useToggleState(false)
	const [isFormVisible, toggleFormVisibility] = useToggleState(true)
	const [isParagraphVisible, toggleParagraphVisibility] = useToggleState(true)
	const [isTableVisible, toggleTableVisibility] = useToggleState(true)

	const baseProps = {
		showForm: isFormVisible,
		showParagraph: isParagraphVisible,
		showTable: isTableVisible,
	}

	return (
		<Card>
			<Card.Header>Modal Demos</Card.Header>
			<ListGroup variant="flush">
				<ListGroup.Item action onClick={toggleSmVisibility}>
					Small Modal
				</ListGroup.Item>

				<ListGroup.Item action onClick={toggleMdVisibility}>
					Medium Modal
				</ListGroup.Item>

				<ListGroup.Item action onClick={toggleLgVisibility}>
					Large Modal
				</ListGroup.Item>

				<ListGroup.Item action onClick={toggleResVisibility}>
					Responsive Modal
				</ListGroup.Item>
			</ListGroup>

			{isSmVisible && <DemoModal onHide={toggleSmVisibility} size="sm" {...baseProps} />}
			{isMdVisible && <DemoModal onHide={toggleMdVisibility} size="md" {...baseProps} />}
			{isLgVisible && <DemoModal onHide={toggleLgVisibility} size="lg" {...baseProps} />}
			{isResVisible && <DemoModal onHide={toggleResVisibility} size="res" {...baseProps} />}

			<Card.Footer>
				<Row>
					<Col sm="4">
						<Form.Check
							checked={isTableVisible}
							id="showTable"
							label="Show Table"
							onChange={toggleTableVisibility}
						/>
					</Col>

					<Col sm="4">
						<Form.Check
							checked={isParagraphVisible}
							id="showParagraph"
							label="Show Paragraph"
							onChange={toggleParagraphVisibility}
						/>
					</Col>

					<Col sm="4">
						<Form.Check
							checked={isFormVisible}
							id="showForm"
							label="Show Form"
							onChange={toggleFormVisibility}
						/>
					</Col>
				</Row>
			</Card.Footer>
		</Card>
	)
}

type DemoModalProps = MOmit<MotivModalProps, 'children'> & {
	readonly showForm?: boolean
	readonly showParagraph?: boolean
	readonly showTable?: boolean
}

type DemoFormData = {
	readonly checks: string[]
	readonly email: string
	readonly name: string
	readonly password: string
	readonly radio: string | null
}

const DemoModal = ({ showForm, showParagraph, showTable, ...p }: DemoModalProps) => {
	const validationSchema = useConstant(() =>
		Yup.object().shape({
			checks: Yup.array().min(2, 'Choose at least two').default([]),
			email: Yup.string()
				.required('Email is required')
				.email('Please enter a valid email')
				.default(''),
			name: Yup.string().required('Please enter your name').default(''),
			password: Yup.string()
				.required('Please enter your password')
				.matches(/^motiv$/, `Password is 'motiv'`)
				.default(''),
			radio: Yup.string().nullable().required('Please choose an option').default(null),
		})
	)

	const initialValues = useConstant(() => validationSchema.cast({}))

	const formikSubmit = useRef<() => void>()

	const handleSubmit: FormikSubmit<DemoFormData> = useFn((v) => {
		console.log(v)
		alert(JSON.stringify(v, null, 2))
		p.onHide()
	})

	const handleSuccessClick = useFn(() => {
		if (formikSubmit.current) return formikSubmit.current()

		p.onHide()
	})

	const ModalSize = useMemo(() => {
		switch (p.size) {
			case 'sm':
				return 'Small'

			case 'md':
				return 'Medium'

			case 'lg':
				return 'Large'
		}

		return 'Responsive'
	}, [p.size])
	// TODO: Table

	return (
		<MotivModal {...p}>
			<Modal.Header closeButton>
				<Modal.Title>{ModalSize} Modal</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{showParagraph && (
					<>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur volutpat, massa ut
							tincidunt faucibus, eros lorem pellentesque nisl, in vulputate risus lorem a diam.
							Integer eu consequat nisl, quis malesuada nisl.
						</p>

						<div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
							<Button className="m-3" variant="primary" size="lg">
								Primary Lg
							</Button>

							<Button className="m-3" variant="danger" size="lg">
								Danger Lg
							</Button>

							<Button className="m-3" variant="primary">
								Primary
							</Button>

							<Button className="m-3" variant="danger">
								Danger
							</Button>

							<Button className="m-3" variant="primary" size="sm">
								Primary Sm
							</Button>

							<Button className="m-3" variant="danger" size="sm">
								Danger Sm
							</Button>
						</div>
					</>
				)}

				{showForm && (
					<Formik<DemoFormData>
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
					>
						{(p) => {
							formikSubmit.current = p.submitForm
							const getCtrlProps = formikCtrlProps(p)
							const getChkProps = formikChkProps(p, 'checks')
							const getRadioProps = formikChkProps(p, 'radio', 'radio')

							return (
								<Form as={FormikForm}>
									<Row>
										<Form.Group as={Col} md="4" controlId="formName">
											<Form.Label>Name</Form.Label>

											<Form.Control placeholder="Enter your name" {...getCtrlProps('name')} />

											<FormValidationText field="name" formikProps={p} valid="Nice name!" />
										</Form.Group>

										<Form.Group as={Col} md="4" controlId="formEmail">
											<Form.Label>Email</Form.Label>

											<Form.Control
												placeholder="Enter your email"
												type="email"
												{...getCtrlProps('email')}
											/>

											<FormValidationText field="email" formikProps={p} valid="Email is valid!" />
										</Form.Group>

										<Form.Group as={Col} md="4" controlId="formPassword">
											<Form.Label>Password</Form.Label>

											<Form.Control
												placeholder="Enter your password"
												type="password"
												{...getCtrlProps('password')}
											/>

											<FormValidationText
												field="password"
												formikProps={p}
												valid="Password looks good!"
											/>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} md="6">
											<Form.Check label="Check 1" id="check1" {...getChkProps('1')} />
											<Form.Check label="Check 2" id="check2" {...getChkProps('2')} />
											<Form.Check label="Check 3" id="check3" {...getChkProps('3')} />

											<FormValidationText field="checks" formikProps={p} valid="Good job!" />
										</Form.Group>

										<Form.Group as={Col} md="6">
											<Form.Check label="Radio 1" id="radio1" {...getRadioProps('1')} />
											<Form.Check label="Radio 2" id="radio2" {...getRadioProps('2')} />
											<Form.Check label="Radio 3" id="radio3" {...getRadioProps('3')} />

											<FormValidationText field="radio" formikProps={p} valid="Good job!" />
										</Form.Group>
									</Row>
								</Form>
							)
						}}
					</Formik>
				)}

				{showTable && <DemoTable isFixedHeader />}
			</Modal.Body>

			<Modal.Footer>
				<Button size="lg" variant="success" onClick={handleSuccessClick}>
					Sounds Fine
				</Button>

				<Button size="lg" variant="light-link" onClick={p.onHide}>
					Cancel
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
