import type { HttpExtra as BaseHttpExtra, MotivHttp } from '@motiv-shared/reducers'
import { createHttp } from '@motiv-shared/reducers'
import type { MotivApiErrorBody } from '@motiv-shared/server'
import { ApiErrors } from '@motiv-shared/server'
import type {
	Action as BaseAction,
	AnyAction,
	ThunkAction as BaseThunkAction,
} from '@reduxjs/toolkit'
import type { ToastPayload } from './reducers'
import { addErrorToast, fetchIntegrations, isAuthenticated$, jwtToken$ } from './reducers'
import type { RootState } from './rootReducer'
import { getRequiresAuth, normalizeUrl } from './util'
import type { IndicatorRegion } from './widgets/BusyIndicator'
import { decrementBusyIndicator, incrementBusyIndicator } from './widgets/BusyIndicator'

type ExtraArg = { readonly http: MotivHttp<RootState, HttpExtra> }

export type ThunkAction<
	Return extends unknown = void,
	State = RootState,
	Action extends BaseAction = AnyAction
> = BaseThunkAction<Return, State, ExtraArg, Action>

export type HttpErrorMessage =
	| ToastPayload
	| ((extra: HttpExtra, body: Maybe<MotivApiErrorBody>) => ThunkAction<ToastPayload>)

type Extra = {
	readonly errorMessage?: HttpErrorMessage
	readonly ignoreIndicator?: boolean
	readonly indicatorRegion?: IndicatorRegion
}

export type HttpExtra = BaseHttpExtra<RootState, Extra>

export const http = createHttp<RootState, Extra>(
	{
		auth: (url) => (dispatch, getState) => {
			const state = getState()

			return {
				header: `Bearer ${jwtToken$(state)}`,
				requiresAuth: getRequiresAuth(url),
				isAuthorized: isAuthenticated$(state),
			}
		},

		onAfterFetch: (extra) => (dispatch) => {
			if (!extra.ignoreIndicator) {
				dispatch(decrementBusyIndicator(extra.indicatorRegion))
			}
		},

		onBeforeFetch: (extra) => (dispatch) => {
			if (!extra.ignoreIndicator) {
				dispatch(incrementBusyIndicator(extra.indicatorRegion))
			}
		},

		onError: (extra, body) => (dispatch) => {
			if (body?.data?.errorCode === ApiErrors.INTEGRATION_INVALID_AUTH[0]) {
				dispatch(fetchIntegrations({ force: true }))
			}

			const errorMessage = extra.errorMessage

			if (errorMessage) {
				dispatch(
					addErrorToast(
						typeof errorMessage == 'function' ? dispatch(errorMessage(extra, body)) : errorMessage
					)
				)
			}
		},

		normalizeUrl,
	},
	{
		errorMessage: 'Unable to process request. Please try again later.',
		ignoreIndicator: false,
	}
)
