import type {
	AnyAction,
	AsyncThunk,
	AsyncThunkPayloadCreator,
	SerializedError,
	ThunkDispatch,
} from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { HttpExtra as DefaultHttpExtra, MotivHttp } from './http'

type GetExtra<S, HttpExtra> = {
	readonly http: MotivHttp<S, HttpExtra>
}

type GetThunkConf<S, HttpExtra> = {
	state: S
	dispatch: ThunkDispatch<S, GetExtra<S, HttpExtra>, AnyAction>
	extra: GetExtra<S, HttpExtra>
	rejectValue?: unknown
	serializedErrorType?: unknown
}

/* NOTE: Copied from redux-toolkit */
type GetSerializedErrorType<ThunkApiConfig> = ThunkApiConfig extends {
	serializedErrorType: infer GetSerializedErrorType
}
	? GetSerializedErrorType
	: SerializedError

/* NOTE: This was copied from redux-toolkit and modified to fit */
type AsyncThunkOptions<ThunkArg, ThunkApiConfig extends GetThunkConf<any, any>> = {
	/**
	 * A method to control whether the asyncThunk should be executed. Has access to the
	 * `arg`, `api.getState()` and `api.extra` arguments.
	 *
	 * @returns `false` if it should be skipped
	 */
	condition?: (
		arg: ThunkArg,
		api: {
			readonly getState: () => ThunkApiConfig['state']
			readonly extra: ThunkApiConfig['extra']
		}
	) => boolean | undefined

	/**
	 * If `condition` returns `false`, the asyncThunk will be skipped.
	 * This option allows you to control whether a `rejected` action with `meta.condition == false`
	 * will be dispatched or not.
	 *
	 * @default `false`
	 */
	dispatchConditionRejection?: boolean
	serializeError?: (x: unknown) => GetSerializedErrorType<ThunkApiConfig>
}

/**
 * Redux toolkit throws a `ConditionError` if you try to unwrap the result of
 * a thunk that was aborted due to condition check, however, they don't export
 * `ConditionError` or anything like it, so this is here to keep that check
 * consistent.
 */
export const isConditionError = (e: any) => e?.name === 'ConditionError'

/**
 * This is a function that simply binds the state to `createAsyncThunk`, along
 * with the `extra` prop w/ http, and all of the correct types. It's for type
 * purposes only and, allows us to use `createAsyncThunk` w/ the correct types
 * and extra argument, without having to pass those types in as generic args
 * for every invocation.
 *
 * For example, without this, to get types to flow properly you'd have to do:
 * ```
 * createAsyncThunk<Return, ThunkArg, ThunkConfigWithDispatchExtraStateEtc>(
 *   'foo',
 *   (thunkArg, {dispatch, extra}) => {
 *     return dispatch(extra.http.get<Return>('/foo', thunkArg))
 *   })
 * ```
 *
 * With this, types are bound and return type is inferred from return and args:
 * ```
 * createAsyncThunk(
 *   'foo',
 *   (thunkArg: ThunkArg, {dispatch, extra}) => {
 *     return dispatch(extra.http.get<Return>('/foo', thunkArgs))
 *   })
 * ```
 */
export const bindCreateAsyncThunkToState = <S, HttpExtra = DefaultHttpExtra>() => <
	R,
	ThunkArg = void,
	ThunkApiConfig extends GetThunkConf<S, HttpExtra> = GetThunkConf<S, HttpExtra>
>(
	typePrefix: string,
	payloadCreator: AsyncThunkPayloadCreator<R, ThunkArg, ThunkApiConfig>,
	options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
): AsyncThunk<R, ThunkArg, ThunkApiConfig> =>
	createAsyncThunk<R, ThunkArg, ThunkApiConfig>(typePrefix, payloadCreator, options)
