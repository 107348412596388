import { usePrevious } from '@motiv-shared/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../Auth'
import { isAuthenticated$ } from '../reducers'
import { canAccessProtected$ } from '../state-helpers'
import { LoadingFigure } from '../widgets/BusyIndicator'

export const LoginPage = () => {
	const { isReady, login } = useAuth()
	const isAuthenticated = useSelector(isAuthenticated$)
	const wasAuthenticated = usePrevious(isAuthenticated)
	const canAccessProtected = useSelector(canAccessProtected$)

	useEffect(() => {
		// Allow remaining logged out for UI Demo in dev
		if (process.env.APP_ENV === 'dev' && location.pathname.startsWith('/ui-demo')) return

		// If the user was authenticated and now is not, we have gone through
		// the logout process. Auth0 will redirect to origin once logout is complete
		// at which point wasAuthenticated will be false and login will be called
		if (wasAuthenticated && !isAuthenticated) return

		// NOTE: As it's currently implemented, we could just handle the `isReady`
		//  and login() transparently within Auth.tsx. I elected to do it this way
		//  in case we every want to put a splash screen or logged out state in.
		if (isReady && !isAuthenticated) login()
	}, [isAuthenticated, isReady, wasAuthenticated])

	// TODO: Based on current design of this app, this should probably return
	//  a busy spinner
	return isReady && canAccessProtected ? <Redirect to="/" /> : <LoadingFigure />
}
