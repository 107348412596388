import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import LANDING_PAGE_IMG from '../../assets/images/landing-page.png'
import { GooglePlayBtn, IOSAppBtn } from '../../widgets/AppStoreBtns'

export const TeamLead = () => (
	<Card className="team-lead-container mb-5">
		<Card.Header className="d-flex align-items-center" as="h5">
			Team Lead
		</Card.Header>

		<Card.Body className="d-md-flex align-items-center p-5 p-md-0 ">
			<div className="d-none d-md-block mr-6 pr-6">
				<Image
					alt="Landing page image"
					className="w-100"
					src={LANDING_PAGE_IMG}
					style={{ maxWidth: '170px' }}
				/>
			</div>

			<div className="py-6 text-center">
				<h3 className="font-weight-bold mb-4">Motiv is Productivity Reporting from Anywhere</h3>

				<p>Download the mobile app to see team performance.</p>

				<div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
					<IOSAppBtn className="d-flex mb-6" />
					<GooglePlayBtn className="d-flex mb-6" />
				</div>
			</div>
		</Card.Body>
	</Card>
)
